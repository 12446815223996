// CaseList.js
import React, { useId } from "react";
import { CaseRow } from "./CaseRow";
function CaseList({
  filteredCases,
  specialCases,
  amountInRowsState,
  st,
  search,
  sortBy,
  setSelectedCases,
  selectedCases,
  rainLimitedCases
}) {
  const id = useId();
  return <div data-sentry-component="CaseList" data-sentry-source-file="CaseList.jsx">
      {filteredCases && Object.keys(filteredCases).map((item, i) => {
      return <CaseRow key={id + i + item} itemName={item} rows={filteredCases[item]} st={st} style={{
        gridTemplateColumns: `repeat(${amountInRowsState}, 1fr)`,
        display: "grid"
      }} searchTerm={search} sortBy={sortBy} specialCases={specialCases} setSelectedCases={setSelectedCases} selectedCases={selectedCases} rainLimitedCases={rainLimitedCases} />;
    })}
    </div>;
}
export default React.memo(CaseList);