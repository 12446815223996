import useWallet from "@hooks/useWallet";
import { useMemo } from "react";

export const ranksArray = [
  {
    id: 1,
    index: 0,
    name: "Bronze",
    level: 1,
    threshold: "10000.00",
    reward: "20.00",
    color: "#F87A52",
  },
  {
    id: 2,
    index: 1,
    name: "Silver",
    level: 1,
    threshold: "50000.00",
    reward: "50.00",
    color: "#D4D4D4",
  },
  {
    id: 3,
    index: 2,
    name: "Gold",
    level: 1,
    threshold: "100000.00",
    reward: "100.00",
    color: " #F8C766",
  },
  {
    id: 15,
    index: 3,
    name: "Gold",
    level: 2,
    threshold: "250000.00",
    reward: "200.00",
    color: "#F8C766",
  },
  {
    id: 16,
    index: 4,
    name: "Gold",
    level: 3,
    threshold: "500000.00",
    reward: "400.00",
    color: "#F8C766",
  },
  {
    id: 4,
    index: 5,
    name: "Platinum",
    level: 1,
    threshold: "700000.00",
    reward: "600.00",
  },
  {
    id: 5,
    index: 6,
    name: "Platinum",
    level: 2,
    threshold: "1300000.00",
    reward: "1000.00",
  },
  {
    id: 6,
    index: 7,
    name: "Platinum",
    level: 3,
    threshold: "2500000.00",
    reward: "1700.00",
  },
  {
    id: 7,
    index: 8,
    name: "Platinum",
    level: 4,
    threshold: "4000000.00",
    reward: "2500.00",
  },
  {
    id: 8,
    index: 9,
    name: "Platinum",
    level: 5,
    threshold: "7000000.00",
    reward: "4000.00",
  },
  {
    id: 9,
    index: 10,
    name: "Platinum",
    level: 6,
    threshold: "10000000.00",
    reward: "6000.00",
  },
  {
    id: 10,
    index: 11,
    name: "Diamond",
    level: 1,
    threshold: "15000000.00",
    reward: "10000.00",
  },
  {
    id: 11,
    index: 12,
    name: "Diamond",
    level: 2,
    threshold: "50000000.00",
    reward: "35000.00",
  },
  {
    id: 12,
    index: 13,
    name: "Diamond",
    level: 3,
    threshold: "100000000.00",
    reward: "60000.00",
  },
  {
    id: 13,
    index: 14,
    name: "Diamond",
    level: 4,
    threshold: "250000000.00",
    reward: "150000.00",
  },
  {
    id: 14,
    index: 15,
    name: "Diamond",
    level: 5,
    threshold: "500000000.00",
    reward: "250000.00",
  },
];

export const convertRanks = (rate) =>
  [...ranksArray].reduce(
    (acc, r) => [
      ...acc,
      {
        ...r,
        threshold: Number(r.threshold) * rate,
        reward: Number(r.reward) * rate,
      },
    ],
    []
  );

export const useRanks = () => {
  const { activeRate } = useWallet();

  const data = useMemo(
    () => convertRanks(activeRate?.rate),
    [activeRate?.rate]
  );

  return data;
};
