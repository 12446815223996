import { LoadingExternalLogin } from "@components/Loading/LoadingExternalLogin";
import { Button } from "@components/new-ui/button";
import usePopup from "@hooks/usePopup";
import { useUserSession } from "@hooks/useUserSession";
import { NODE_API } from "@lib/api/api";
import { removeUrlQueries } from "@lib/tools/helpers";
import { log, logError } from "@lib/tools/logger";
import { useSearchParams } from "next/navigation";
import { useEffect } from "react";
import useSWR from "swr";
export default function VerifyEmailModal() {
  const {
    mutate
  } = useUserSession();
  const query = useSearchParams();
  const token = query.get("token");
  const setMessage = usePopup();
  const {
    data: res,
    error
  } = useSWR("/v1/email/validate/", url => NODE_API.post(url, {}, {
    headers: {
      "x-token": token
    }
  }), {
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });
  useEffect(() => {
    if (error) {
      logError(error);
      setMessage({
        type: 0,
        code: "responses.er_email_sending_error"
      });
      return;
    }
    if (res) {
      log(res);
      mutate().then(() => {
        setMessage({
          type: 1,
          code: "responses.success_email_verification"
        });
        removeUrlQueries();
      });
    }
  }, [error, mutate, res, setMessage]);
  if (!error) return <LoadingExternalLogin type="default" />;
  return <div className="flex flex-col gap-8 m-8" data-sentry-component="VerifyEmailModal" data-sentry-source-file="index.tsx">
      <p>Verification failed</p>
      <Button variant="green" onClick={() => {
      window.location.reload();
    }} data-sentry-element="Button" data-sentry-source-file="index.tsx">
        Try again
      </Button>
    </div>;
}