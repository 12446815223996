import st from "../../styles/components/PromoTerms.module.scss";
import useLanguage from "../../hooks/useLanguage";
import API from "../../lib/api/api";
import Button from "../../items/Button";
import usePopup from "../../hooks/usePopup";
import { useRouter } from "next/router";
import { removeUrlQueries } from "../../lib/tools/helpers";
import { useEffect, useState } from "react";
import ValueIcons from "../../items/ValueIcons";
import useAuth from "../../hooks/useAuth";
import { useUserSession } from "../../hooks/useUserSession";
export default function PromoTerms({}) {
  const [warning, setWarning] = useState(false);
  const router = useRouter();
  useAuth();
  const L = useLanguage("PromoTerms");
  const setMessage = usePopup();
  const {
    userData: data,
    mutate
  } = useUserSession();
  const promoBalance = data?.balance_promo && Number(data?.balance_promo);
  const promoBalanceValue = data?.balance_promo && data?.balance_promo;
  useEffect(() => {
    if (router.query?.type) {
      switch (router.query?.type) {
        case "wager-locked-1":
          if (promoBalance > 0) {
            setWarning(true);
          }
          break;
        case "wager-locked-2":
          if (promoBalance > 0) {
            setWarning(true);
          }
          break;
        case "wager-locked-3":
          if (promoBalance > 0) {
            setWarning(true);
          }
          break;
        case "no-wager-lock-1":
          break;
        case "no-wager-lock-2":
          break;
        case "no-wager-lock-3":
          break;
        default:
          removeUrlQueries(router);
          break;
      }
    }
  }, [router]);
  const acceptTerm = async () => {
    try {
      const res = await API.post("promotions/enable", {
        promo_code: router.query.type
      });
      if (res.data.success) {
        await mutate();
        setMessage({
          code: "responses.su_promotion_claimed",
          type: 1
        });
        removeUrlQueries(router);
      } else {
        setMessage({
          code: "responses." + res.data.error,
          type: 0
        });
      }
    } catch (err) {
      console.log("Promo error:", err);
    }
  };
  return <div className={st["promo-terms-container"]} data-sentry-component="PromoTerms" data-sentry-source-file="index.jsx">
      <div className={st["promo-terms"]}>
        <h2>
          {router.query?.type?.includes("wager-locked") && "40x"}{" "}
          {L(router.query.type)}
        </h2>
        <p>{L("promo_terms")}</p>
        {warning && <p className={st["promo-warning"]}>
            {L("warning_message_1")}{" "}
            <span>
              <ValueIcons size="small" /> {promoBalanceValue}
            </span>
            {L("warning_message_2")}
          </p>}
      </div>
      <div className={st["promo-terms-buttons"]}>
        <Button param={false} buttonType={"link"} url={{
        pathname: "/promotions"
      }} text={L("decline")} classType={["grey-button", "cancel-button"]} data-sentry-element="Button" data-sentry-source-file="index.jsx" />

        <Button text={L("accept")} method={acceptTerm} classType={["blue-button", "has-border", "full-width"]} data-sentry-element="Button" data-sentry-source-file="index.jsx" />
      </div>
    </div>;
}