import st from "../../styles/components/Steam.module.scss";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Button from "../../items/Button";
import API from "../../lib/api/api";
import usePopup from "../../hooks/usePopup";
import AddTradeDetails from "./AddTradeDetails";
import useLanguage from "../../hooks/useLanguage";
import { useUserSession } from "../../hooks/useUserSession";

//Auth container,
export default function Steam(props) {
  let router = useRouter();
  const [foundSteam, setFoundSteam] = useState(false);
  const {
    userData,
    mutate
  } = useUserSession();
  const setMessage = usePopup();
  const L = useLanguage(["Steam"]);
  const steam = () => {
    API.post("steam/link").then(res => {
      router.push(res.data.url);
      //console.log("steam res: ",res)
    }).catch(err => {
      //console.log("steam Error", err)
    });
  };
  useEffect(() => {
    if (router.query["openid.ns"] && foundSteam === false) {
      setFoundSteam(true);
      const {
        modal,
        tab,
        ...data
      } = router.query;

      // Convert the 'data' object to a query string
      const queryString = Object.entries(data).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join("&").trim();

      // Assemble the full URL
      const url = `steam/link?${queryString}`;
      API.get(url).then(res => {
        if (res.data.success) {
          // login(res.data.tokens);
          mutate();
          setMessage({
            code: "responses.successful",
            type: 1
          });
          // router.replace(router.asPath.split('?')[0],  undefined, {shallow: true});
        } else {
          //console.log("steam error message: ",res.data.error)
          setMessage({
            code: "responses." + res.data.error,
            type: 0
          });
        }
      }).catch(err => {
        //console.log("steam with data Error", err)
      });
    }
  }, [router, foundSteam, setMessage]);
  const [twoFactorInfo, setTwoFactorInfo] = useState(false);
  const [factorCode, setFactorCode] = useState();

  //Switch between sections and create the menu
  return <div className={`${st["auth-container"]} ${twoFactorInfo ? st["active-two-factor"] : null}`} data-sentry-component="Steam" data-sentry-source-file="index.jsx">
      <h2>{L("steam_details")}</h2>

      {/* {userData?.steam_link !== true && (<h1>steam account</h1>)
            } */}

      {!userData?.steam_link && foundSteam === false ? <div className={st["steam-button"]}>
          <Button text={L("link_steam_account")} method={steam} classType={["green", "full-width"]} />
        </div> : <AddTradeDetails />}
    </div>;
}