import st from "../styles/components/WarningNotice.module.scss";
export default function WarningNotice({
  children,
  type = "warning"
}) {
  return <div className={`
  ${st["notice"]}
  ${st["notice--" + type]}
  `} data-sentry-component="WarningNotice" data-sentry-source-file="WarningNotice.jsx">
      {children}
    </div>;
}