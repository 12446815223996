import useWallet from "@hooks/useWallet";
import type { Currency } from "@/types";
import { useMemo } from "react";
export default function useCurrencySymbol(currencyCode?: string) {
  const {
    currencies,
    walletSetting
  } = useWallet();
  const symbol = useMemo(() => {
    const code = currencyCode?.toUpperCase?.() || walletSetting;
    const currency = currencies?.[code] as Currency;
    const str = currency?.display?.prepend || currency?.display?.append || "$";
    return str;
  }, [currencies, currencyCode, walletSetting]);
  return symbol;
}