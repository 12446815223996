function GooglePayIcon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" fill="none" viewBox="0 0 46 46" {...props} data-sentry-element="svg" data-sentry-component="GooglePayIcon" data-sentry-source-file="GooglePay.jsx">
      <g clipPath="url(#clip0_3881_10662)" data-sentry-element="g" data-sentry-source-file="GooglePay.jsx">
        <path fill="#E8E5FF" d="M21.756 22.792v5.353h-1.7V14.923h4.506a4.07 4.07 0 012.917 1.144 3.675 3.675 0 011.218 2.79 3.662 3.662 0 01-1.218 2.808c-.788.751-1.761 1.127-2.917 1.125l-2.806.002zm0-6.241v4.617h2.848a2.24 2.24 0 001.68-.684 2.253 2.253 0 00.045-3.187l-.045-.045a2.216 2.216 0 00-1.68-.701h-2.848zm10.856 2.253c1.256 0 2.247.335 2.973 1.005.727.67 1.089 1.592 1.089 2.76v5.576H35.05v-1.256h-.074c-.703 1.034-1.64 1.55-2.807 1.55-.996 0-1.83-.294-2.501-.886a2.831 2.831 0 01-1.006-2.216c0-.936.355-1.68 1.062-2.233.707-.553 1.651-.83 2.834-.83 1.008 0 1.84.185 2.492.553v-.39a1.934 1.934 0 00-.702-1.502 2.405 2.405 0 00-1.64-.619c-.948 0-1.7.4-2.252 1.2l-1.495-.942c.82-1.18 2.038-1.77 3.651-1.77zm-2.199 6.572c-.001.44.209.852.563 1.108.376.295.842.453 1.32.443a2.714 2.714 0 001.912-.794c.562-.53.843-1.15.843-1.865-.53-.422-1.267-.634-2.215-.634-.69 0-1.266.166-1.727.5-.464.338-.696.749-.696 1.242zM46 19.098l-5.67 13.035h-1.754l2.104-4.56-3.73-8.475h1.845l2.696 6.5h.037l2.622-6.5H46z" data-sentry-element="path" data-sentry-source-file="GooglePay.jsx"></path>
        <path fill="#4285F4" d="M14.89 21.635c0-.518-.042-1.035-.13-1.545H7.596v2.925h4.102a3.514 3.514 0 01-1.516 2.308v1.9h2.449c1.433-1.32 2.26-3.276 2.26-5.588z" data-sentry-element="path" data-sentry-source-file="GooglePay.jsx"></path>
        <path fill="#34A853" d="M7.595 29.06c2.05 0 3.777-.672 5.035-1.834l-2.45-1.9c-.681.463-1.558.727-2.585.727-1.98 0-3.663-1.335-4.264-3.135H.809v1.958a7.594 7.594 0 006.786 4.185z" data-sentry-element="path" data-sentry-source-file="GooglePay.jsx"></path>
        <path fill="#FBBC04" d="M3.332 22.918a4.551 4.551 0 010-2.908v-1.957H.809a7.595 7.595 0 000 6.823l2.523-1.958z" data-sentry-element="path" data-sentry-source-file="GooglePay.jsx"></path>
        <path fill="#EA4335" d="M7.595 16.873a4.126 4.126 0 012.913 1.14l2.17-2.168a7.308 7.308 0 00-5.083-1.98 7.597 7.597 0 00-6.786 4.186l2.522 1.958c.601-1.8 2.283-3.136 4.264-3.136z" data-sentry-element="path" data-sentry-source-file="GooglePay.jsx"></path>
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="GooglePay.jsx">
        <clipPath id="clip0_3881_10662" data-sentry-element="clipPath" data-sentry-source-file="GooglePay.jsx">
          <path fill="#fff" d="M0 0H46V46H0z" data-sentry-element="path" data-sentry-source-file="GooglePay.jsx"></path>
        </clipPath>
      </defs>
    </svg>;
}
export default GooglePayIcon;