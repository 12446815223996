import Lock from "@assets/icons/general/LockVaultIcon";
import VaultIcon from "@assets/icons/general/VaultIcon";
import ButtonSwitch from "@components/form/button-switch";
import LoadingSpinner from "@components/Loading/LoadingSpinner";
import { Button } from "@components/new-ui/button";
import CurrencyInput from "@components/new-ui/currency-input";
import { Input } from "@components/new-ui/input";
import useLanguage from "@hooks/useLanguage";
import { useUserSession } from "@hooks/useUserSession";
import useWallet from "@hooks/useWallet";
import ValueDisplay from "@items/ValueDisplay";
import API from "@lib/api/api";
import createPopup from "@lib/create-popup";
import { handleCatchPopup, useLocale } from "@lib/tools/helpers";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
type Form = {
  tab: "deposit" | "withdraw";
  "vault-amount": number;
};
const schema = z.object({
  "vault-amount": z.number().min(0.01, {
    message: "Minimum deposit is 0.01"
  })
});
export default function VaultModal() {
  const L = useLanguage(["VaultModal"]);
  const {
    userData,
    mutate
  } = useUserSession({
    required: true
  });
  const {
    register,
    watch,
    setValue,
    formState
  } = useForm<Form>({
    defaultValues: {
      tab: "deposit"
    },
    mode: "onChange",
    resolver: zodResolver(schema)
  });
  const tab = watch("tab");
  const amount = watch("vault-amount");
  const buttonText = L(tab !== "deposit" ? "withdraw" : "deposit");
  const options = useMemo(() => [{
    label: L("deposit"),
    value: "deposit"
  }, {
    label: L("withdraw"),
    value: "withdraw"
  }], [L]);
  const factorCodeRef = useRef("");
  const [loading, setLoading] = useState(false);
  const {
    pathname,
    replace
  } = useRouter();
  const {
    walletSetting,
    vaultBalance,
    balanceMutate,
    vaultBalanceMutate
  } = useWallet();
  const language = useLocale();
  const twoFactor = userData?.["2fa"];
  const submit = useCallback(() => {
    if (!amount) return;
    setLoading(true);
    const code = factorCodeRef.current;
    const currency = walletSetting;
    API.post(`vault/${tab}`, {
      amount,
      language,
      currency,
      ...(code && {
        code
      })
    }).then(() => Promise.all([mutate(), vaultBalanceMutate(), balanceMutate()])).then(async () => {
      createPopup({
        code: tab === "deposit" ? "responses.su_vault_deposit" : "responses.su_vault_withdrawn",
        type: 1,
        replacements: {
          TOKEN_AMOUNT: amount
        },
        raw: true
      });
      replace(pathname.split("?")[0], undefined, {
        shallow: true
      });
    }).catch(err => {
      if (err.response.data.error === "er_2fa_required_vault_deposit") {
        replace({
          pathname,
          query: {
            modal: "twoFactor",
            tab: "enable"
          }
        }, undefined, {
          shallow: true
        });
        return;
      }
      if (err.response.data.error !== "er_unsupported_multiple_request") {
        createPopup({
          code: "responses." + err.response.data.error,
          type: 0
        });
        return;
      }
      if (err.response.data.error === "er_2fa_required_vault_withdraw") {
        replace({
          pathname,
          query: {
            modal: "twoFactor",
            tab: "enable"
          }
        });
        return;
      }
      handleCatchPopup(createPopup)(err);
    }).finally(() => {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  }, [amount, balanceMutate, language, mutate, pathname, replace, tab, vaultBalanceMutate, walletSetting]);
  useEffect(() => {
    factorCodeRef.current = "";
    setValue("vault-amount", null);
  }, [setValue]);
  useEffect(() => {
    setValue("tab", tab);
    setValue("vault-amount", null);
  }, [tab, setValue]);
  useEffect(() => {
    vaultBalanceMutate();
  }, [vaultBalanceMutate]);
  return <div data-sentry-component="VaultModal" data-sentry-source-file="index.tsx">
      <form className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <VaultIcon data-sentry-element="VaultIcon" data-sentry-source-file="index.tsx" />
          <h2 className="text-primary-font-color">{L("vault")}</h2>
        </div>

        <p className="text-sm text-font-color-two">{L("vault_modal_desc")}</p>

        <ButtonSwitch options={options} register={register("tab", {
        required: true
      })} selected={tab} variant={["blue", "disabled"]} data-sentry-element="ButtonSwitch" data-sentry-source-file="index.tsx" />

        <div className="flex flex-col gap-2 relative">
          {tab !== "deposit" && <>
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-2">
                  <Lock />
                  <span className="text-primary-font-color text-xs">
                    {L("vault_balance")}
                  </span>
                </div>

                <div className="text-sm ml-auto">
                  <ValueDisplay amount={vaultBalance?.amount || 0} size="small" customClass="text-primary-font-color text-xs" raw />
                </div>
              </div>
              <hr className="mt-1 mb-4" />
            </>}

          <span className="text-primary-font-color text-xs">
            {L(tab === "deposit" ? "deposit_amount" : "withdraw_amount")}
          </span>

          <div className="relative">
            <CurrencyInput {...register("vault-amount", {
            required: true,
            valueAsNumber: true
          })} currency={walletSetting} empty={!amount} autoComplete="off" data-sentry-element="CurrencyInput" data-sentry-source-file="index.tsx" />
          </div>

          {twoFactor && tab === "withdraw" ? <div className="flex flex-col gap-2 my-3">
              <span className="text-primary-font-color text-xs">
                {L("fa_code")}
              </span>

              <Input type="number" onChange={e => {
            factorCodeRef.current = e.target.value;
          }} placeholder="000000" className="placeholder:text-font-color-seven" />
            </div> : null}

          <Button onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          submit();
        }} variant="green" className="mt-2" disabled={loading || !formState.isValid} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            {loading ? <LoadingSpinner type="button" /> : buttonText}
          </Button>
        </div>
      </form>
    </div>;
}