import st from "../../styles/components/WalletSettings.module.scss";
import { useId } from "react"; // Added useState
import { useRouter } from "next/navigation";
import useLanguage from "../../hooks/useLanguage";
import WalletSettingsIcon from "../../assets/icons/general/WalletSettingsIcon";
import NodeAPI from "@lib/api/nodeApi";
import CurrencyRadioButton from "./CurrencyRadioButton";
import { useUserSession } from "../../hooks/useUserSession";
import useWallet from "../../hooks/useWallet";
import { logError } from "@lib/tools/logger";
import { handleCatchPopup } from "@lib/tools/helpers";
import { usePathname } from "next/navigation";
export default function WalletSettings() {
  const randKey = useId();
  const {
    walletSetting,
    balanceMutate,
    currencies: currenyRates
  } = useWallet();
  const {
    mutate
  } = useUserSession();
  const {
    replace
  } = useRouter();
  const pathname = usePathname();
  const L = useLanguage(["WalletSettings", "Currency"]);
  const storeWalletSettings = currency => {
    NodeAPI.put("v1/user/currency/switch", {
      currency
    }).then(() => Promise.all([mutate(), balanceMutate()])).catch(handleCatchPopup(logError)).finally(() => {
      replace(pathname);
    });
  };
  return <div data-sentry-component="WalletSettings" data-sentry-source-file="index.jsx">
      <div className={st["wallet-settings-container"]}>
        <div className={st["heading"]}>
          <WalletSettingsIcon data-sentry-element="WalletSettingsIcon" data-sentry-source-file="index.jsx" />
          <h2>{L("wallet_settings")} </h2>
        </div>

        <h3>{L("display_currency")} </h3>

        <fieldset className={st["list-of-currencies"]}>
          {Object.keys(currenyRates).map(key => {
          return <CurrencyRadioButton key={randKey + key} id={randKey + key} name="fiatRate" value={key} checked={walletSetting === key} onChange={() => storeWalletSettings(key)} label="Option 2" currenyRates={currenyRates} L={L} />;
        })}
        </fieldset>
        <div className={st["wallet-settings-description"]}>
          <p>{L("wallet_setting_description")}</p>
        </div>
      </div>
    </div>;
}