import { useEffect, useState } from "react";
import st from "../../styles/components/Steam.module.scss";
import { validateSteamSettings, checkSubmitForErrors } from "../../lib/tools/validate.js";
import InputItem from "../../items/InputItem";
import Button from "../../items/Button";
import API from "../../lib/api/api";
import { useRouter } from "next/router";
import usePopup from "../../hooks/usePopup";
import Info from "../../assets/icons/general/Info";
import Link from "next/link";
import useLanguage from "../../hooks/useLanguage";
export default function AddTradeDetails() {
  const Router = useRouter();
  const setMessage = usePopup();
  const [apiHolder, setApiHolder] = useState("");
  const [tradeUrlHolder, setTradeUrlHolder] = useState("");
  const [formState, setFormState] = useState({
    apiKey: "",
    tradeUrl: "",
    errors: {}
  });
  const L = useLanguage(["Steam"]);
  useEffect(() => {
    API.post("steam/fetch-settings").then(res => {
      if (res.data.success) {
        if (res.data.api_key) {
          setApiHolder(`${L("set_api_holder")} ${res.data.api_key}`);
          setTradeUrlHolder(`${L("set_trader_url_holder")} ${res.data.trade_url}`);
        }
      }
    }).catch(() => {
      //console.log("fetch_error",err)
    });
  }, [L]);
  const handleInputChange = (name, value) => {
    setFormState(prev => ({
      ...prev,
      [name]: value
    }));
  };
  const onSubmit = () => {
    let data = {
      api_key: formState.apiKey,
      trade_url: formState.tradeUrl
    };
    setFormState(prev => ({
      ...prev,
      errors: {}
    }));
    let checkErrorResults = checkSubmitForErrors(validateSteamSettings, formState);
    if (checkErrorResults) {
      setFormState(prev => ({
        ...prev,
        errors: checkErrorResults
      }));
      setMessage({
        code: "forms.er_forms",
        type: 0
      });
    } else {
      API.post("steam/update-settings", data).then(res => {
        if (res.data.success) {
          setMessage({
            code: "responses.su_steam_settings",
            type: 1
          });
          Router.replace(Router.asPath.split("?")[0], undefined, {
            shallow: true
          });
        } else {
          setMessage({
            code: "responses." + res.data.error,
            type: 0
          });
        }
      }).catch(() => {
        //console.log("register api error" , error)
      });
    }
  };
  return <form className={st["form-container"]} data-sentry-component="AddTradeDetails" data-sentry-source-file="AddTradeDetails.jsx">
      <InputItem name={"apiKey"} label={L("steam_api_key")} value={formState.apiKey} onChange={handleInputChange} type={"password"} placeholder={apiHolder} data-sentry-element="InputItem" data-sentry-source-file="AddTradeDetails.jsx" />

      <div className={st["instructions-block"]}>
        <p>
          <Info data-sentry-element="Info" data-sentry-source-file="AddTradeDetails.jsx" />
          {L("please")}
          <Link href="https://steamcommunity.com/dev/apikey" legacyBehavior data-sentry-element="Link" data-sentry-source-file="AddTradeDetails.jsx">
            <a target="_blank" rel="noopener noreferrer">
              {L("generate_api_key_steam")}
            </a>
          </Link>
          {L("enter_it_here")}
        </p>
        <ul>
          <li>{L("steam_domain_recommend")} &quot;localhost&quot;</li>
          <li>{L("not_share_steam_api")}</li>
          <li>{L("not_revoke_steam_api")}</li>
          <li>{L("api_steam_share")}</li>
        </ul>
      </div>

      <InputItem name={"tradeUrl"} label={L("steam_trade_url")} value={formState.tradeUrl} onChange={handleInputChange} placeholder={tradeUrlHolder} data-sentry-element="InputItem" data-sentry-source-file="AddTradeDetails.jsx" />

      <div className={st["instructions-block"]}>
        <p>
          <Info data-sentry-element="Info" data-sentry-source-file="AddTradeDetails.jsx" />
          {L("find_steam_trade")}
          <Link href="https://steamcommunity.com/my/tradeoffers/privacy#trade_offer_access_url" legacyBehavior data-sentry-element="Link" data-sentry-source-file="AddTradeDetails.jsx">
            <a target="_blank" rel="noopener noreferrer">
              {`${L("steam_trade_url")} ${L("here")}`}
            </a>
          </Link>
        </p>
      </div>

      <Button method={onSubmit} text={L("submit_details")} classType={["green", "full-width", "mid-height"]} data-sentry-element="Button" data-sentry-source-file="AddTradeDetails.jsx" />
    </form>;
}