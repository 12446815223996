import st from "../../styles/components/WalletSettings.module.scss";
import Image from "next/image";
const fallbackImage = "https://cdn.rainbet.com/currencies/usd.svg";

/**
 * Renders a currency radio button component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.id - The unique identifier for the radio button.
 * @param {string} props.name - The name of the radio button group.
 * @param {string} props.value - The value of the radio button.
 * @param {boolean} props.checked - Indicates whether the radio button is checked.
 * @param {function} props.onChange - The event handler for the radio button change event.
 * @param {function} props.L - The translation function for displaying currency labels.
 * @param {Object} props.currenyRates - The currency rates object.
 * @param {string} props.label - The label for the radio button.
 * @returns {JSX.Element} The currency radio button component.
 */
export default function CurrencyRadioButton({
  id,
  name,
  value,
  checked,
  onChange,
  L,
  currenyRates
}) {
  const iconPath = currenyRates?.[value]?.display?.icon;
  return <div className={`
      ${st["radio-currency-select"]}
      ${checked && st["active-radio"]}
    `} data-sentry-component="CurrencyRadioButton" data-sentry-source-file="CurrencyRadioButton.jsx">
      <input type="radio" id={id} name={name} value={value} checked={checked} onChange={onChange} style={{
      display: "none"
    }} // Hide the default radio button visually
    />

      <label className={st["currency-label"]} htmlFor={id}>
        <div className={st["icon-text"]}>
          <div className={st["curreny-icon-container"]}>
            <Image src={currenyRates?.[value]?.display?.icon || fallbackImage} alt={value} width="18" height="18" sizes="100vw" data-sentry-element="Image" data-sentry-source-file="CurrencyRadioButton.jsx" />
          </div>
          <span>{L(value)}</span>
        </div>

        <span className={st["custom-radio-button"]}>
          <span className={st["custom-radio-button-inner"]}></span>
        </span>
      </label>
    </div>;
}