import { classnames } from "@lib/tools/helpers";
import st from "../../styles/components/LoadingSpinner.module.scss";
import { PropsWithChildren, useMemo, CSSProperties } from "react";
export type SpinnerType = "qrCode" | "fee" | "twoFactor" | "button" | "slots" | "inPlay";
const getClassName = (type?: SpinnerType): string => {
  switch (type) {
    case "qrCode":
      return "lds-ring-qr-code";
    case "fee":
      return "lds-ring-fee";
    case "twoFactor":
      return "lds-ring-two-factor";
    case "button":
      return "lds-ring-button";
    case "slots":
      return "lds-ring-slots";
    case "inPlay":
      return "in-play-ring-slots";
    default:
      return "lds-ring";
  }
};
type LoadingSpinnerProps = PropsWithChildren<{
  type: SpinnerType;
  style?: CSSProperties;
}>;
export default function LoadingSpinner({
  type,
  style
}: LoadingSpinnerProps) {
  const className = useMemo(() => getClassName(type), [type]);
  return <div style={style} {...classnames(st, className, "spinner")} data-sentry-component="LoadingSpinner" data-sentry-source-file="LoadingSpinner.tsx">
      <div />
      <div />
      <div />
      <div />
    </div>;
}