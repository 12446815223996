import { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "@components/new-ui/button";
import API from "../../lib/api/api";
import useLanguage, { Dict, useDictionary } from "../../hooks/useLanguage";
import useLoader from "../../hooks/useLoader";
import usePopup from "../../hooks/usePopup";
import { useRouter } from "next/router";
import useAuth from "../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { Input } from "@components/new-ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { formValidation, PasswordValidationTooltip } from "@components/Auth/password-validation";
import useMobile from "@hooks/useMobile";
export default function ResetPassword() {
  const L = useLanguage(["forms", "ResetPassword"]);
  const setMessage = usePopup();
  const [displayMsg, setLoader] = useLoader(L("update_password", {}));
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const router = useRouter();
  const [emailCode, setEmailCode] = useState("");
  useAuth(true);
  const isMobile = useMobile({
    breakPointOne: 1100,
    breakPointTwo: 525,
    breakPointThree: 100
  });
  const {
    new_password,
    confirm_password,
    password_validation_length,
    password_validation_lowercase,
    password_validation_uppercase,
    password_validation_special,
    form_validation_password_minlength,
    form_validation_password_required,
    form_validation_password_lowercase,
    form_validation_password_uppercase,
    form_validation_password_special,
    form_validation_password_match
  } = useDictionary("settings", "current_password", "new_password", "confirm_password", "password_validation_length", "password_validation_lowercase", "password_validation_uppercase", "password_validation_special", "form_validation_password_minlength", "form_validation_password_required", "form_validation_password_lowercase", "form_validation_password_uppercase", "form_validation_password_special", "form_validation_password_match");
  const formSchema = z.object({
    new_password: z.string().min(1, {
      message: form_validation_password_required
    }).min(formValidation.password.minLength, {
      message: form_validation_password_minlength
    }).regex(formValidation.password.patterns.lowercase, {
      message: form_validation_password_lowercase
    }).regex(formValidation.password.patterns.uppercase, {
      message: form_validation_password_uppercase
    }).regex(formValidation.password.patterns.specialChar, {
      message: form_validation_password_special
    }),
    confirm_password: z.string().min(1, {
      message: form_validation_password_required
    })
  }).refine(data => data.new_password === data.confirm_password, {
    message: form_validation_password_match,
    path: ["confirm_password"]
  });
  type FormValues = z.infer<typeof formSchema>;
  const {
    register,
    handleSubmit,
    formState: {
      errors
    },
    watch
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: "onSubmit",
    defaultValues: {
      new_password: "",
      confirm_password: ""
    }
  });
  const newPassword = watch("new_password");
  const onSubmit = async (formData: FormValues) => {
    try {
      setLoader(true);
      const data = {
        password: formData.new_password,
        confirm: formData.confirm_password,
        hash: emailCode
      };
      const res = await API.post("user/recover", data);
      const newQuery = {
        modal: "auth",
        tab: "login"
      };
      if (res.data.success) {
        setMessage({
          code: "responses.su_password_changed",
          type: 1
        });
        router.push({
          query: newQuery
        });
      } else if (res.data.error === "er_invalid_recover_hash") {
        setMessage({
          code: "responses." + res.data.error,
          type: 0
        });
        router.push({
          query: {
            ...newQuery,
            recover: "true"
          }
        });
      } else {
        setMessage({
          code: "responses." + res.data.error,
          type: 0
        });
      }
    } catch (error) {
      setMessage({
        code: "responses.error",
        type: 0
      });
    } finally {
      setLoader(false);
    }
  };
  const checkHash = useRef(false);
  const passwordValidationState = useMemo(() => ({
    length: newPassword?.length >= formValidation.password.minLength,
    lowercase: formValidation.password.patterns.lowercase.test(newPassword || ""),
    uppercase: formValidation.password.patterns.uppercase.test(newPassword || ""),
    special: formValidation.password.patterns.specialChar.test(newPassword || "")
  }), [newPassword]);
  useEffect(() => {
    if (router.query.code && !checkHash.current) {
      checkHash.current = true;
      API.post("user/recover", {
        hash: router.query.code
      }).then(res => {
        if (res.data.success) {
          setEmailCode(router.query.code as string);
        } else {
          setMessage({
            code: "responses.er_invalid_recover_hash",
            type: 2
          });
          router.push({
            query: {
              modal: "auth",
              tab: "login",
              recover: "true"
            }
          });
        }
      }).catch(() => {
        setMessage({
          code: "responses.error",
          type: 0
        });
      });
    }
  }, [router, setMessage]);
  return <div className="sm:w-[380px] gap-6 flex flex-col justify-between" data-sentry-component="ResetPassword" data-sentry-source-file="index.tsx">
      <Dict name="new_password" as="h2" section="ResetPassword" className="text-[13px] font-medium text-input-label-color-light text-opacity-75" data-sentry-element="Dict" data-sentry-source-file="index.tsx" />

      <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-3">
          <label className="text-[rgba(232,229,255,0.75)] text-xs">
            {new_password}
          </label>
          <PasswordValidationTooltip validationRules={[{
          key: "length",
          text: password_validation_length
        }, {
          key: "lowercase",
          text: password_validation_lowercase
        }, {
          key: "uppercase",
          text: password_validation_uppercase
        }, {
          key: "special",
          text: password_validation_special
        }]} passwordValidationState={passwordValidationState} forceShow={isPasswordFocused} placement={isMobile ? "top-end" : "right"} data-sentry-element="PasswordValidationTooltip" data-sentry-source-file="index.tsx">
            <div>
              <Input type="password" aria-invalid={!!errors.new_password} onFocus={() => setIsPasswordFocused(true)} {...register("new_password", {
              required: true,
              onBlur: () => setIsPasswordFocused(false)
            })} data-sentry-element="Input" data-sentry-source-file="index.tsx" />
            </div>
          </PasswordValidationTooltip>
        </div>

        <div className="grid gap-3">
          <label className="text-[rgba(232,229,255,0.75)] text-xs">
            {confirm_password}
          </label>

          <div className="grid gap-2">
            <div>
              <Input type="password" aria-invalid={!!errors.confirm_password} {...register("confirm_password", {
              required: true
            })} data-sentry-element="Input" data-sentry-source-file="index.tsx" />
            </div>
            {errors.confirm_password && <p className="text-[#EC4848] text-[13px]">
                {errors.confirm_password.message}
              </p>}
          </div>
        </div>

        <Button type="submit" variant="green" data-sentry-element="Button" data-sentry-source-file="index.tsx">
          {displayMsg}
        </Button>
      </form>
    </div>;
}