export default function TrustlyIconBackground() {
  return <svg width="78" height="38" viewBox="0 0 225 110" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="TrustlyIconBackground" data-sentry-source-file="TrustlyIconBackground.jsx">
      <rect width="225" height="110" rx="13" fill="#0EE06E" data-sentry-element="rect" data-sentry-source-file="TrustlyIconBackground.jsx" />
      <g clipPath="url(#clip0_0_1)" data-sentry-element="g" data-sentry-source-file="TrustlyIconBackground.jsx">
        <path d="M85.9762 65.0547V43.5366H78.2695V38.4238H99.2285V43.5366H91.5219V65.0547H85.9762Z" fill="white" data-sentry-element="path" data-sentry-source-file="TrustlyIconBackground.jsx" />
        <path d="M98.3555 65.0545V45.7344H103.456V48.7925C104.664 46.1963 106.221 45.7344 107.89 45.7344H109.86V50.9108H108.191C105.792 50.9108 103.647 52.2965 103.647 56.5333V65.0704H98.3555V65.0545Z" fill="white" data-sentry-element="path" data-sentry-source-file="TrustlyIconBackground.jsx" />
        <path d="M112.258 57.9827V45.7344H117.533V56.7881C117.533 59.0498 118.63 60.7222 120.886 60.7222C123.063 60.7222 124.446 59.0498 124.446 56.82V45.7344H129.721V65.0704H124.7V62.6335C123.429 64.5607 121.538 65.5164 119.138 65.5164C114.911 65.5005 112.258 62.5857 112.258 57.9827Z" fill="white" data-sentry-element="path" data-sentry-source-file="TrustlyIconBackground.jsx" />
        <path d="M131.977 59.6243L136.569 58.5731C136.791 60.0703 137.983 61.2011 140.287 61.2011C142.067 61.2011 143.052 60.4685 143.052 59.5606C143.052 58.8279 142.655 58.2864 140.827 57.8882L137.808 57.2352C133.947 56.391 132.31 54.6071 132.31 51.3579C132.31 48.045 135.043 45.3691 140.176 45.3691C145.626 45.3691 147.819 48.6502 148.041 50.8004L143.417 51.8198C143.195 50.6889 142.369 49.4466 140.065 49.4466C138.603 49.4466 137.522 50.0996 137.522 51.0553C137.522 51.8517 138.062 52.2976 139.016 52.5206L143.417 53.54C146.77 54.3045 148.327 56.3114 148.327 59.1624C148.327 62.2205 145.896 65.4379 140.319 65.4379C134.185 65.4219 132.183 61.7427 131.977 59.6243Z" fill="white" data-sentry-element="path" data-sentry-source-file="TrustlyIconBackground.jsx" />
        <path d="M158.466 65.0542C155.049 65.0542 152.761 63.1588 152.761 59.3999V50.3531H149.52V45.7182H152.761V41.4177L158.037 39.8887V45.7182H161.93V50.3531H158.037V58.4443C158.037 59.8618 158.768 60.4193 160.182 60.4193H162.216V65.0542H158.466Z" fill="white" data-sentry-element="path" data-sentry-source-file="TrustlyIconBackground.jsx" />
        <path d="M165.078 65.0544V38.4395H170.354V65.0703H165.078V65.0544Z" fill="white" data-sentry-element="path" data-sentry-source-file="TrustlyIconBackground.jsx" />
        <path d="M186.257 45.7344H191.898L183.858 71.9989H178.217L180.442 65.0704H177.534L172.227 45.7344H177.868L181.84 60.6903L186.257 45.7344Z" fill="white" data-sentry-element="path" data-sentry-source-file="TrustlyIconBackground.jsx" />
        <path d="M33 50.0984H45.3784V40.1915L55.262 50.0984L45.3784 59.9894V65.0544H57.3754V50.0984H68.3872V38.4395H33V50.0984Z" fill="white" data-sentry-element="path" data-sentry-source-file="TrustlyIconBackground.jsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="TrustlyIconBackground.jsx">
        <clipPath id="clip0_0_1" data-sentry-element="clipPath" data-sentry-source-file="TrustlyIconBackground.jsx">
          <rect width="159" height="34" fill="white" transform="translate(33 38)" data-sentry-element="rect" data-sentry-source-file="TrustlyIconBackground.jsx" />
        </clipPath>
      </defs>
    </svg>;
}