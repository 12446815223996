import DepositButtons from "./DepositButtons";
import Button from "../../items/Button";
import st from "../../styles/components/Wallet.module.scss";
import useCrypto from "../../hooks/useCrypto";
import Image from "next/image";
import useLanguage from "../../hooks/useLanguage";
import { keysTranslate } from "./CryptoHelper";
import { classnames } from "@lib/tools/helpers";
import { useSearchParams } from "next/navigation";
import { useMemo } from "react";
import AllCrytoImage from "@assets/Images/Wallet/all_crypto_icon.png";
import { InteracLogoIcon } from "../../assets/icons/PaymentMethods/InteracIcon";
import InteractWithdrawIcon from "../../assets/icons/PaymentMethods/InteracWithdrawIcon";
import PixIcon from "../../assets/icons/PaymentMethods/PixIcon";
export default function SelectCrypto({
  children
}) {
  const {
    list: crypto
  } = useCrypto();
  const L = useLanguage(["Wallet"]);
  const search = useSearchParams();
  const tab = search.get("tab");
  const method = search.get("method");
  const isWithdraw = tab === "withdraw";
  const selectList = useMemo(() => {
    let filteredCrypto;
    let backupCryptoIndex = 0;
    if (tab === "withdraw") {
      filteredCrypto = Object.entries(crypto || {}).filter(([, {
        fiat,
        withdrawal,
        featured,
        networks
      }]) => {
        const hasValidNetwork = networks?.some(n => n.withdrawal === 1);
        return !fiat && !featured && (withdrawal || hasValidNetwork);
      });
    }
    const btnSetup = (item, {
      icon,
      name,
      id,
      code
    }) => {
      return <Button key={`${item}-${id}`} query={{
        modal: "wallet",
        tab: tab,
        method: item
      }} isShallow={true} active={false} text={<div {...classnames(st, "method-details", "method-details__code-name")}>
              <h3>{code}</h3>
              <span className={st["crypto-name"]}>
                {keysTranslate?.[name] || name}
              </span>
            </div>} icon={<Image src={icon} width={24} height={24} alt={`${name} Icon`} />} classType={["method-select", method === item && "method-select__specific-selected"]} data-sentry-element="Button" data-sentry-component="btnSetup" data-sentry-source-file="SelectCrypto.jsx" />;
    };
    return Object?.entries(crypto || {})?.map?.(([item, {
      icon,
      name,
      rate,
      id,
      code,
      withdrawal,
      deposit,
      networks
    } = {}]) => {
      if (crypto?.[item]?.fiat || !crypto?.[item]?.featured) return null;
      const hasValidNetwork = networks?.some(n => tab === "deposit" ? n.deposit === 1 : n.withdrawal === 1);
      if (tab === "deposit" && !deposit && !hasValidNetwork) return null;
      if (tab === "withdraw" && !withdrawal && !hasValidNetwork) {
        if (filteredCrypto?.[backupCryptoIndex]) {
          let [item, {
            icon,
            name,
            rate,
            id,
            code
          }] = filteredCrypto[backupCryptoIndex];
          let btn = btnSetup(item, {
            icon,
            name,
            rate,
            id,
            code
          });
          backupCryptoIndex++;
          return btn;
        } else {
          return null;
        }
      }
      return btnSetup(item, {
        icon,
        name,
        rate,
        id,
        code
      });
    });
  }, [crypto, method, tab]);
  return <>
      <div className={st["other-method-select-container"]}>
        <h4 className={st["crypto-title"]}>
          <span>{L("banking")}</span>
          <span className={st["title-line"]}></span>
        </h4>

        <>
          <div className={st["pix-pic-container"]}>
            <Button buttonType={"link"} isShallow={true} classType={[isWithdraw ? "method-select-withdraw" : "method-select", method === "interac" && "method-select__specific-selected"]} query={{
            modal: "wallet",
            tab: tab,
            method: "interac"
          }} icon={<InteracLogoIcon />} text={tab === "deposit" ? <div {...classnames(st, "method-details")}>
                    <h3>Interac</h3>
                  </div> : <div {...classnames(st, "method-details")}>
                    <h3>Interac</h3>
                  </div>} data-sentry-element="Button" data-sentry-source-file="SelectCrypto.jsx" />
            <Button buttonType={"link"} isShallow={true} classType={["method-select", method === "pix" && "method-select__specific-selected"]} query={{
            modal: "wallet",
            tab: tab,
            method: "pix"
          }} icon={<PixIcon />} text={<div {...classnames(st, "method-details")}>
                  <h3>Pix</h3>
                </div>} data-sentry-element="Button" data-sentry-source-file="SelectCrypto.jsx" />
          </div>
        </>
      </div>
      <div className={st["other-method-select-container"]}>
        <h3 className={st["crypto-title"]}>
          <span>{L("crypto")}</span> <span className={st["title-line"]}></span>
        </h3>
        <div className={st["deposit-select"]}>
          {selectList}
          <Button key={`${"allcrypto"}-${0}`} query={{
          modal: "wallet",
          tab: tab,
          method: "all-crypto"
        }} isShallow={true} active={false} text={<div {...classnames(st, "method-details")}>
                <h3>{L("all_crypto")}</h3>
              </div>} icon={<Image src={AllCrytoImage.src} width={24} height={24} alt={`${"all Crypto"} Icon`} />} classType={["method-select"]} data-sentry-element="Button" data-sentry-source-file="SelectCrypto.jsx" />
          {children}
        </div>
      </div>
      <div {...classnames(st, "other-method-select-container", "other-method-select-container__cards")}>
        <Button style={{
        boxShadow: "inset 0px 0px 50px -10px rgba(46, 142, 230, 1)"
      }} showActive={true} buttonType={"link"} classType={["icon-left", "full-width-tab", "buy-crypto-tab"]} query={{
        modal: "gift-card"
      }} extraContent={<DepositButtons />} isShallow={true} data-sentry-element="Button" data-sentry-source-file="SelectCrypto.jsx" />
      </div>
    </>;
}