import React, { useState, useRef, useEffect, memo, useMemo } from "react";
import TwoFactorIcon from "@assets/svgs/TwoFactorIcon";
import Image from "next/image";
import usePopup from "@hooks/usePopup";
import st from "@styles/components/TwoFactorInput.module.scss";
import { show } from "@intercom/messenger-js-sdk";
import useLanguage from "@hooks/useLanguage";
import CountDownLink from "./CountDownLink";
import { useUserSession } from "@hooks/useUserSession";
interface TwoFactorInputProps {
  onChange?: (code: string) => void;
  hasError: boolean;
  securityCheck?: boolean;
  username: string;
}
const TwoFactorInput = ({
  onChange,
  hasError,
  securityCheck,
  username
}: TwoFactorInputProps) => {
  const {
    hasToken
  } = useUserSession();
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState(false);
  const [clearedInputs, setClearedInputs] = useState([false, false, false, false, false, false]);
  const L = useLanguage(["auth"]) as any;
  const [isIconActive, setIsIconActive] = useState(false);
  const ref1 = useRef<HTMLInputElement>(null);
  const ref2 = useRef<HTMLInputElement>(null);
  const ref3 = useRef<HTMLInputElement>(null);
  const ref4 = useRef<HTMLInputElement>(null);
  const ref5 = useRef<HTMLInputElement>(null);
  const ref6 = useRef<HTMLInputElement>(null);
  const inputRefs = useMemo(() => [ref1, ref2, ref3, ref4, ref5, ref6], []);
  const setMessage = usePopup();
  const getEnterCodeMessage = () => {
    if (error) {
      return L("twoFA.enter_code_error");
    } else if (hasToken) {
      return L("twoFA.enter_code_authenticated");
    } else {
      return securityCheck ? L("security_check_message") : L("twoFA.enter_code");
    }
  };
  useEffect(() => {
    if (hasToken) {
      setIsIconActive(true);
    }
  }, [hasToken]);
  useEffect(() => {
    if (hasError) setError(true);
  }, [hasError]);
  const handleChange = (index, value) => {
    if (isNaN(value)) return;
    const trimmedValue = value.trim();
    if (trimmedValue.length !== 1) return;
    const newCode = [...code];
    newCode[index] = trimmedValue;
    setCode(newCode);
    if (trimmedValue !== "" && index < 5) {
      setError(false);
      inputRefs[index + 1].current.focus();
    }
    const completeCode = newCode.join("");
    if (newCode.every(digit => digit !== "")) {
      onChange(completeCode);
    }
    const newClearedInputs = [...clearedInputs];
    newClearedInputs[index] = false;
    setClearedInputs(newClearedInputs);
  };
  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace") {
      const newClearedInputs = [...clearedInputs];
      if (code[index] === "" && clearedInputs[index]) {
        if (index > 0) {
          inputRefs[index - 1].current.focus();
          const newCode = [...code];
          newCode[index - 1] = "";
          setCode(newCode);
          newClearedInputs[index - 1] = true;
          setClearedInputs(newClearedInputs);
        }
      } else {
        const newCode = [...code];
        newCode[index] = "";
        setCode(newCode);
        newClearedInputs[index] = true;
        setClearedInputs(newClearedInputs);
      }
    }
  };
  useEffect(() => {
    inputRefs[0].current.focus();
  }, [inputRefs]);
  const handlePaste = e => {
    e.stopPropagation();
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").replace(/\s+/g, "");
    if (pasteData.length !== 6 || !/^\d{6}$/.test(pasteData)) {
      if (!error) {
        setMessage({
          code: "responses.er_2fa_bigger_than_six",
          type: 0
        });
        setError(true);
      }
      setError(false);
      return;
    }
    const newCode = pasteData.split("");
    setCode(newCode);
    newCode.forEach((digit, index) => {
      inputRefs[index].current.value = digit;
    });
    onChange(pasteData);
    inputRefs[5].current.focus();
    setError(false);
  };
  return <div className={st.container} onPaste={handlePaste} data-sentry-component="TwoFactorInput" data-sentry-source-file="two-factor-input.tsx">
      <div className={st.box}>
        <div className={st.iconContainer}>
          <Image quality={100} src={TwoFactorIcon(isIconActive)} alt="TwoFactorIcon" height={150} data-sentry-element="Image" data-sentry-source-file="two-factor-input.tsx" />
        </div>
        <h2 className={st.title}>
          {securityCheck ? L("security_check_title") : L("twoFA.title")}
        </h2>
        <div className={st.inputs}>
          {code.map((digit, index) => <input key={index} ref={inputRefs[index]} type="number" maxLength={1} value={digit} onChange={e => handleChange(index, e.target.value)} onPaste={index === 0 ? handlePaste : null} onKeyDown={e => handleKeyDown(index, e)} className={`${st.input} ${error ? st.errorInput : ""} ${hasToken ? st.authenticatedInput : ""}`} />)}
        </div>
        <div className={st.bottomContainer}>
          <p className={st.codeText}>{getEnterCodeMessage()}</p>
          <div className={st.bottomText}>
            {securityCheck ? <CountDownLink username={username} /> : <>
                {L("twoFA.need_assistance")}
                <p className={st.supportLink} onClick={() => show()}>
                  {L("twoFA.support")}
                </p>
              </>}
          </div>
        </div>
      </div>
    </div>;
};
export default memo(TwoFactorInput);