import Bronze from "@assets/icons/rewards/bronze.png";
import Diamond from "@assets/icons/rewards/diamond.png";
import Gold from "@assets/icons/rewards/gold.png";
import Platinum from "@assets/icons/rewards/platinum.png";
import Silver from "@assets/icons/rewards/silver.png";
import BronzeKey from "@assets/Images/bronze-key.png";
import DiamondKey from "@assets/Images/diamond-key.png";
import GoldKey from "@assets/Images/gold-key.png";
import PlatinumKey from "@assets/Images/platinum-key.png";
import SilverKey from "@assets/Images/silver-key.png";
import { SvgUnranked } from "@components/Svg/SvgStore";
import { cn } from "@lib/utils";
import clsx from "clsx";
import Image from "next/image";
export type RewardCode = "bronze" | "diamond" | "gold" | "platinum" | "silver" | "unranked";
export type RewardIconsProps = Readonly<{
  code?: RewardCode;
  size?: "customHomepage" | "x6large" | "x5large" | "x4large" | "x3large" | "x2large" | "xlarge" | "mlarge" | "large" | "mid" | "small" | undefined;
  isKey?: boolean;
  className?: string;
}>;
const sizeClasses = {
  customHomepage: "w-[132px]",
  x6large: "w-[140px]",
  x5large: "w-[60px]",
  x4large: "w-[50px]",
  x3large: "w-[40px] h-[40px]",
  x2large: "w-[30px]",
  xlarge: "w-[25px]",
  mlarge: "w-[20px] h-[20px]",
  large: "w-[18px]",
  mid: "w-[15px]",
  small: "w-[12px]"
} as const;
export default function RewardIcons({
  code = "unranked",
  size,
  isKey = false,
  className
}: RewardIconsProps) {
  let icon = null;
  switch (code?.toLowerCase() ?? "unranked") {
    case "bronze":
      icon = <IconComponent src={isKey ? BronzeKey : Bronze} alt="bronze" size={size ?? undefined} isKey={isKey} className={className} />;
      break;
    case "diamond":
      icon = <IconComponent src={isKey ? DiamondKey : Diamond} alt="diamond" size={size ?? undefined} isKey={isKey} className={className} />;
      break;
    case "gold":
      icon = <IconComponent src={isKey ? GoldKey : Gold} alt="gold" size={size ?? undefined} isKey={isKey} className={className} />;
      break;
    case "platinum":
      icon = <IconComponent src={isKey ? PlatinumKey : Platinum} alt="platinum" size={size ?? undefined} isKey={isKey} className={className} />;
      break;
    case "silver":
      icon = <IconComponent src={isKey ? SilverKey : Silver} alt="silver" size={size ?? undefined} isKey={isKey} className={className} />;
      break;
    case "unranked":
      icon = !isKey ? <SvgUnranked width={sizeClasses[size]} className={clsx(sizeClasses[size], className)} /> : null;
      break;
    default:
      return null;
  }
  return <div className="" data-sentry-component="RewardIcons" data-sentry-source-file="RewardIcons.tsx">{icon}</div>;
}
const IconComponent = ({
  src,
  alt,
  size,
  isKey,
  className
}: {
  src: any;
  alt: string;
  size?: keyof typeof sizeClasses;
  isKey: boolean;
  className?: string;
}) => {
  const defaultSize = size === undefined ? isKey ? "w-20 h-20" : sizeClasses.large : sizeClasses[size];
  return src ? <Image src={src} alt={alt} className={cn("max-w-full h-auto object-contain", defaultSize, className)} data-sentry-element="Image" data-sentry-component="IconComponent" data-sentry-source-file="RewardIcons.tsx" /> : null;
};