import st from "../../styles/components/Profile.module.scss";
import { useEffect } from "react";
import Overview from "./Overview";
import useLanguage, { useDictionary } from "../../hooks/useLanguage";
import useAuth from "../../hooks/useAuth";
import ModalSpinner from "../../items/ModalSpinner";
import { useUserDetails, useUserSession } from "../../hooks/useUserSession";
import { ProfileProgressBar } from "@components/ProfileProgressBar";
import { ClickCopy } from "@components/click-copy";

//Auth container,
export default function Profile() {
  useAuth();
  const {
    mutate,
    publicId
  } = useUserSession();
  const {
    data: userDetails
  } = useUserDetails();
  const L = useLanguage(["UserProfile"]);
  const {
    public_id
  } = useDictionary("settings", "public_id");
  useEffect(() => {
    mutate?.();
  }, [mutate]);

  //Switch between sections and create the menu
  return <div className={st["container"]} data-sentry-component="Profile" data-sentry-source-file="index.tsx">
      <ModalSpinner hide={userDetails} data-sentry-element="ModalSpinner" data-sentry-source-file="index.tsx" />
      <h2>{L("profile")}</h2>
      <div className={st["user-public-id"]}>
        <span className={st["user-public-container"]}>
          <p className={st["title"]}>{public_id}:</p>
          <p className={st["id"]}>{publicId}</p>
        </span>
        <ClickCopy value={publicId} data-sentry-element="ClickCopy" data-sentry-source-file="index.tsx" />
      </div>

      <div className={st["user-display"]}>
        <ProfileProgressBar data-sentry-element="ProfileProgressBar" data-sentry-source-file="index.tsx" />
      </div>

      <Overview userDetails={userDetails} data-sentry-element="Overview" data-sentry-source-file="index.tsx" />
    </div>;
}