/* eslint-disable no-console */
import API, { NODE_API } from "@lib/api/api";
import { logError } from "./logger";
import { getClientIp } from "./helpers";
import { GetServerSidePropsContext } from "next";
import type { PageData } from "@/types/page-content";

// Common API configuration
export const getSSRHeaders = async <C extends GetServerSidePropsContext>(
  context: C
): Promise<RainbetHeaders> => {
  const { authOptions } = await import("@pages/api/auth/[...nextauth]");
  const { getServerSession } = await import("next-auth");

  const session = await getServerSession(
    context?.req,
    context?.res,
    authOptions
  ).catch(() => null);
  const token = session?.user?.access_token;

  return getServerHeaders({
    userAgent: context?.req?.headers?.["user-agent"],
    cookie: context?.req?.headers?.cookie,
    ip: getClientIp(context),
    token,
  });
};

export const fetchPageContent = async (context: GetServerSidePropsContext) => {
  const { resolvedUrl, locale: language } = context;

  const url_ = new URL(`http://rainbet.com${resolvedUrl}`)?.pathname || "/";
  const url = url_.startsWith("/") ? url_.substring(1) : url_;

  try {
    const response = await NODE_API.post<PageData>(
      "v1/public/content",
      { url, language },
      { headers: await getSSRHeaders(context) }
    );

    // Check both status and data validity
    if (response.status !== 200 || !response.data) {
      logError(`fetchPageContent failed /${language}${resolvedUrl}`, {
        url,
        language,
        status: response.status,
        data: response.data,
      });

      return null;
    }

    return response.data;
  } catch (error) {
    logError(`fetchPageContent failed /${language}${resolvedUrl}`, {
      url,
      language,
      error: error.response?.data || error.message,
    });
    return null;
  }
};

export const fetchGame = async <C extends GetServerSidePropsContext>(
  context: C,
  opts: {
    game_url: string;
    type: string;
  }
) => {
  const { locale } = context;
  const { game_url, type } = opts;

  try {
    const { data, status } = await NODE_API.post(
      "v1/slots/details",
      { game_url, language: locale, type },
      { headers: await getSSRHeaders(context) }
    );

    if (status !== 200) {
      logError(`fetchGame failed /${locale}`, {
        game_url,
        language: locale,
      });
      return null;
    }

    return data;
  } catch (error) {
    logError(`fetchGame failed /${locale}`, {
      game_url,
      language: locale,
      data: error?.response?.data || error?.data || error.message,
    });
    return null;
  }
};

export const fetchTeam = async (
  type: string,
  language: string,
  url: string
) => {
  return API.post(
    "team/" + type,
    {
      language,
      url,
    },
    {
      headers: getServerHeaders(),
    }
  ).then(({ data }) => data);
};

export type RainbetHeaders = {
  "ssr-secret"?: string;
  "user-agent"?: string;
  "ssr-client-ip"?: string;
  cookie?: string;
  token?: string;
  Authorization?: string;
};

type getServerHeadersParams = {
  userAgent?: string;
  cookie?: string;
  ip?: string;
  token?: string;
};

export const getServerHeaders = (
  { userAgent, cookie, ip, token } = {} as getServerHeadersParams
): RainbetHeaders => {
  if (typeof window !== "undefined") {
    throw new Error("getServerHeaders must not be called on the client");
  }

  return {
    "ssr-secret": process.env.SSR_SECRET,
    ...(userAgent && { "user-agent": userAgent }),
    ...(ip && { "ssr-client-ip": ip }),
    ...(cookie && { cookie }),
    ...(token && { Authorization: `Bearer ${token}`, token }),
  } satisfies RainbetHeaders;
};
