import st from "../styles/pages/Races.module.scss";
import useLanguage from "../hooks/useLanguage";
import crown from "../assets/Images/laurel_crown.png";
import Image from "next/image";
import { usePathname } from "next/navigation";
const RacesModal = () => {
  const L = useLanguage(["Rewards", "races"]);
  const tab = usePathname().substring(1)?.split("-")[0];
  const text = {
    weekly_races: L(`${tab}_races`),
    races_desc: L(`${tab}_desc`),
    races_contact: L("races_contact")
  };
  return <div className={st["how-it-works"]} data-sentry-component="RacesModal" data-sentry-source-file="RacesModal.jsx">
      <div>
        <Image className={st["crown"]} src={crown.src} alt="Golden Laurel Crown" width={24} height={24} data-sentry-element="Image" data-sentry-source-file="RacesModal.jsx" />
        <h2>{text.weekly_races}</h2>
      </div>

      <p>{text.races_desc}</p>
    </div>;
};
export default RacesModal;