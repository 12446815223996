import { cn } from "@lib/utils";
import { ComponentProps, forwardRef, ReactNode, useId } from "react";
type InputProps = ComponentProps<"input"> & {
  className?: string;
};
export const Input = forwardRef<HTMLInputElement, InputProps>(({
  className,
  type,
  ...props
}, ref) => {
  return <input type={type} className={cn("w-full h-[50px] rounded-md px-5 font-medium text-sm flex items-center overflow-hidden",
  // Layout and sizing
  "border border-white border-opacity-5 border-solid",
  // Border styles
  "[background:radial-gradient(76.33%_100%_at_50%_100%,rgb(38,44,82,0.8)_0%,rgba(38,44,82,0)_100%),rgba(255,255,255,0.05)]",
  // Background styles
  "text-white outline-none",
  // Text and outline styles
  "duration-75 transition-all",
  // Transition styles
  "aria-[readonly=true]:opacity-50",
  // Readonly styles
  "aria-[invalid=true]:!border-[#EC4848] aria-[invalid=true]:focus:border-[#EC4848]",
  // Invalid styles
  "aria-[readonly=false]:focus:border-primary-stroke-color",
  // Focus styles
  "focus:placeholder:opacity-0", className)} ref={ref} {...props} />;
});
Input.displayName = "Input";
type InputWithIconProps = ComponentProps<"input"> & {
  icon: ReactNode;
};
export const InputWithIcon = forwardRef<HTMLInputElement, InputWithIconProps>(({
  className,
  icon,
  type,
  ...props
}, ref) => {
  const id = useId();
  const labelId = `${id}-${props.name}`;
  return <label htmlFor={labelId} className="relative w-full flex h-[46px] items-center gap-1 rounded-md border border-white border-opacity-5 border-solid px-3 [background:radial-gradient(76.33%_100%_at_50%_100%,rgb(38,44,82,0.8)_0%,rgba(38,44,82,0)_100%),rgba(255,255,255,0.05)]">
        {icon}

        <input id={labelId} placeholder="0" type={type} ref={ref} {...props} className={cn("w-full outline-none duration-75 transition-all aria-[readonly=false]:focus:border-primary-stroke-color overflow-hidden rounded-md font-medium bg-transparent text-white text-sm aria-[readonly=true]:opacity-50 aria-[invalid=true]:!border-[#EC4848] aria-[invalid=true]:focus:border-[#EC4848] inline-flex items-center", "leading-none", "focus:placeholder:opacity-0", className)} />
      </label>;
});
InputWithIcon.displayName = "InputWithIcon";