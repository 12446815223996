export default function AlipayIconBackground() {
  return <svg width="78" height="38" viewBox="0 0 225 110" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="AlipayIconBackground" data-sentry-source-file="AlipayIconBackground.jsx">
      <rect width="225" height="110" rx="13" fill="#1677FF" data-sentry-element="rect" data-sentry-source-file="AlipayIconBackground.jsx" />
      <g clipPath="url(#clip0_0_1)" data-sentry-element="g" data-sentry-source-file="AlipayIconBackground.jsx">
        <path d="M66.3871 35H39.3681C35.8495 35 33 37.868 33 41.4081V68.593C33 72.1309 35.8495 75 39.3681 75H66.3871C69.905 75 72.753 72.1309 72.753 68.593V41.4081C72.753 37.868 69.905 35 66.3871 35Z" fill="white" data-sentry-element="path" data-sentry-source-file="AlipayIconBackground.jsx" />
        <path d="M43.7414 65.76C37.5569 65.76 35.7285 60.8584 38.7849 58.1766C39.8048 57.2703 41.6685 56.8278 42.6622 56.7285C46.3359 56.3632 49.7367 57.7734 53.7499 59.7445C50.929 63.4473 47.3359 65.76 43.7414 65.76ZM65.7299 60.1162C64.1391 59.58 62.0047 58.7603 59.6272 57.8948C61.055 55.3974 62.1954 52.5533 62.945 49.4624H55.1089V46.6225H64.7081V45.0373H55.1089V40.3031H51.1914C50.5042 40.3031 50.5042 40.9858 50.5042 40.9858V45.0373H40.7958V46.6225H50.5042V49.4624H42.4887V51.0472H58.0346C57.4657 53.0194 56.7019 54.871 55.7954 56.5532C50.751 54.8785 45.3682 53.5211 41.9866 54.3566C39.824 54.8927 38.4311 55.8496 37.6129 56.8522C33.8573 61.45 36.5509 68.4336 44.4823 68.4336C49.1719 68.4336 53.6894 65.8027 57.1908 61.4672C62.4132 63.9931 72.7529 68.3302 72.7529 68.3302V62.1491C72.7529 62.1491 71.4543 62.0449 65.7299 60.1162Z" fill="#1677FF" data-sentry-element="path" data-sentry-source-file="AlipayIconBackground.jsx" />
        <path d="M119.32 40.8561C119.32 42.9254 120.836 44.3184 122.95 44.3184C125.064 44.3184 126.58 42.9254 126.58 40.8561C126.58 38.8266 125.064 37.3936 122.95 37.3936C120.836 37.3936 119.32 38.8266 119.32 40.8561Z" fill="white" data-sentry-element="path" data-sentry-source-file="AlipayIconBackground.jsx" />
        <path d="M109.188 67.3213H115.49V38.2695H109.188V67.3213Z" fill="white" data-sentry-element="path" data-sentry-source-file="AlipayIconBackground.jsx" />
        <path d="M88.9663 57.2514L92.7155 44.3174H92.8755L96.4257 57.2514H88.9663ZM98.1411 39.2633H89.6843L80.2305 67.3203H86.0545L87.6501 61.8282H97.6623L99.1775 67.3203H106.637L98.1411 39.2633Z" fill="white" data-sentry-element="path" data-sentry-source-file="AlipayIconBackground.jsx" />
        <path d="M119.797 67.3214H126.099V45.9902H119.797V67.3214Z" fill="white" data-sentry-element="path" data-sentry-source-file="AlipayIconBackground.jsx" />
        <path d="M191.96 46.0289L191.999 45.9892H186.056L182.307 58.9629H182.107L177.799 45.9892H170.738L179.235 67.4002L175.685 73.9267V74.0859H181.23L191.96 46.0289Z" fill="white" data-sentry-element="path" data-sentry-source-file="AlipayIconBackground.jsx" />
        <path d="M137.789 63.6592C137.071 63.6592 136.393 63.5798 135.635 63.3407V51.7998C136.951 50.8845 138.028 50.4465 139.384 50.4465C141.738 50.4465 143.613 52.3172 143.613 56.2968C143.613 61.3906 140.861 63.6592 137.789 63.6592ZM141.778 45.5516C139.464 45.5516 137.669 46.4272 135.635 48.0985V45.9892H129.332V74.0859H135.635V67.1214C136.832 67.4399 137.948 67.5991 139.305 67.5991C144.929 67.5991 149.995 63.4603 149.995 56.0979C149.995 49.4915 146.325 45.5516 141.778 45.5516Z" fill="white" data-sentry-element="path" data-sentry-source-file="AlipayIconBackground.jsx" />
        <path d="M163.2 62.1462C161.525 63.0615 160.567 63.4196 159.451 63.4196C157.935 63.4196 156.978 62.4249 156.978 60.833C156.978 60.2358 157.097 59.639 157.576 59.1617C158.334 58.4052 159.81 57.8481 163.2 57.0523V62.1462ZM169.503 61.987V53.0727C169.503 48.2171 166.631 45.5511 161.565 45.5511C158.334 45.5511 156.1 46.1078 152.032 47.3416L153.148 52.2369C156.858 50.5651 158.494 49.8488 160.209 49.8488C162.283 49.8488 163.2 51.3212 163.2 53.5897V53.749C155.98 55.1022 153.747 55.8583 152.35 57.2513C151.314 58.2861 150.875 59.7585 150.875 61.4696C150.875 65.5687 154.066 67.7578 157.058 67.7578C159.292 67.7578 161.086 66.922 163.52 65.091L163.959 67.3198H170.261L169.503 61.987Z" fill="white" data-sentry-element="path" data-sentry-source-file="AlipayIconBackground.jsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="AlipayIconBackground.jsx">
        <clipPath id="clip0_0_1" data-sentry-element="clipPath" data-sentry-source-file="AlipayIconBackground.jsx">
          <rect width="159" height="40" fill="white" transform="translate(33 35)" data-sentry-element="rect" data-sentry-source-file="AlipayIconBackground.jsx" />
        </clipPath>
      </defs>
    </svg>;
}