export const caseOpenInitialState = {
  cases: {},
  amountInRowsState: 5,
  search: "",
  sortBy: "high-to-low",
  sortByCategory: "all-categories",
  filteredCases: {},
};

export const caseOpenReducer = (state, action) => {
  switch (action.type) {
    case "SET_CASES":
      return { ...state, cases: action.payload };
    case "SET_AMOUNT_IN_ROWS_STATE":
      return { ...state, amountInRowsState: action.payload };
    case "SET_SEARCH":
      return { ...state, search: action.payload };
    case "SET_SORT_BY":
      return { ...state, sortBy: action.payload };
    case "SET_SORT_BY_CATEGORY":
      return { ...state, sortByCategory: action.payload };
    case "SET_FILTERED_CASES":
      return { ...state, filteredCases: action.payload };
    default:
      return state;
  }
};