import { useUserSession } from "@hooks/useUserSession";
import { NODE_API } from "@lib/api/api";
import useSWR from "swr";
import { log, logError } from "@lib/tools/logger";
import { getDeviceInfo } from "@lib/tools/device";
import { getServerHeaders } from "@lib/tools/ssr";
interface IP {
  id: number;
  userId: null;
  ip: string;
  cityName: string;
  region: string;
  regionName: string;
  country: string;
  countryName: string;
  constraint: string;
  firstSeen: number;
  lastSeen: number;
  proxy: string;
  hosting: string;
}
export interface Session {
  id_session: string;
  status: string;
  device: Device;
  ip: IP;
  created_at: string;
  updated_at: string;
}
export interface Device {
  id_device: string;
  browser_name: string;
  browser_version: string;
  os_name: string;
  os_version: string;
  device_type: string;
}
export const getSessions = async ({
  token,
  ...headers
}: {
  token: string;
  headers?: any;
}) => NODE_API.get<Session[]>("v1/auth/sessions", {
  headers: {
    ...getServerHeaders({
      token
    }),
    ...headers
  }
}).then(res => res.data);
export const useSessions = () => {
  const {
    token,
    hasToken
  } = useUserSession();
  const swr = useSWR(hasToken ? ["v1/auth/sessions", token] : null, ([, token]) => getSessions({
    token
  }), {
    revalidateOnFocus: false,
    keepPreviousData: true
  });
  return swr;
};
export type SessionData = {
  id_device: string;
  browser_name: string;
  browser_version: string;
  os_name: string;
  os_version: string;
  device_type: string;
};
export const getSessionData = async () => {
  const {
    id,
    browser,
    os,
    platform
  } = await getDeviceInfo();
  const data = {
    id_device: id,
    browser_name: browser.name,
    browser_version: browser.version,
    os_name: os.name,
    os_version: os.version,
    device_type: platform.type
  } satisfies SessionData;
  return data;
};
type PostSessionResponse = {
  success: boolean;
  tokens: {
    jwt: string;
  };
};
export const saveFederatedSession = async (token: string) => {
  if (typeof token !== "string") throw new Error("Error expected token received " + JSON.stringify(token));
  const data = await getSessionData();
  const response = await NODE_API.post<PostSessionResponse>("v1/auth/sessions", data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "x-federated-token": token
    }
  }).then(res => res.data);
  return response;
};
export const putSession = async (token: string) => {
  if (typeof token !== "string") throw new Error("Error expected token received " + JSON.stringify(token));
  const data = await getSessionData();
  const response = await NODE_API.put<number>("v1/auth/sessions", data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then(res => res.data).catch(err => {
    logError("Error saving session", err);
    return 500;
  });
  log(`Device Saved >> ${data.id_device} <<`, data);
  return response === 200;
};