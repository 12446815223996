import { Button } from "@components/new-ui/button";
import { Input } from "@components/new-ui/input";
import useLanguage from "@hooks/useLanguage";
import useLoader from "@hooks/useLoader";
import usePopup from "@hooks/usePopup";
import useUser from "@hooks/useUser";
import API from "@lib/api/api";
import assert from "@lib/tools/assert";
import { handleCatchPopup, removeUrlQueries } from "@lib/tools/helpers";
import st from "@styles/components/TwoFactor.module.scss";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
const svg1 = <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 4.25C7.7875 4.25 7.60925 4.322 7.46525 4.466C7.32125 4.61 7.2495 4.788 7.25 5L7.25 8.01875C7.25 8.23125 7.322 8.40625 7.466 8.54375C7.61 8.68125 7.788 8.75 8 8.75C8.2125 8.75 8.39075 8.678 8.53475 8.534C8.67875 8.39 8.7505 8.212 8.75 8L8.75 4.98125C8.75 4.76875 8.678 4.59375 8.534 4.45625C8.39 4.31875 8.212 4.25 8 4.25ZM8 10.25C7.7875 10.25 7.60925 10.322 7.46525 10.466C7.32125 10.61 7.2495 10.788 7.25 11C7.25 11.2125 7.322 11.3907 7.466 11.5347C7.61 11.6787 7.788 11.7505 8 11.75C8.2125 11.75 8.39075 11.678 8.53475 11.534C8.67875 11.39 8.7505 11.212 8.75 11C8.75 10.7875 8.678 10.6092 8.534 10.4652C8.39 10.3212 8.212 10.2495 8 10.25ZM8 0.499999C9.0375 0.499999 10.0125 0.697 10.925 1.091C11.8375 1.485 12.6313 2.01925 13.3063 2.69375C13.9813 3.36875 14.5155 4.1625 14.909 5.075C15.3025 5.9875 15.4995 6.9625 15.5 8C15.5 9.0375 15.303 10.0125 14.909 10.925C14.515 11.8375 13.9808 12.6312 13.3063 13.3062C12.6313 13.9812 11.8375 14.5155 10.925 14.909C10.0125 15.3025 9.0375 15.4995 8 15.5C6.9625 15.5 5.9875 15.303 5.075 14.909C4.1625 14.515 3.36875 13.9807 2.69375 13.3062C2.01875 12.6312 1.48425 11.8375 1.09025 10.925C0.696251 10.0125 0.499501 9.0375 0.500001 8C0.500001 6.9625 0.697001 5.9875 1.091 5.075C1.485 4.1625 2.01925 3.36875 2.69375 2.69375C3.36875 2.01875 4.1625 1.48425 5.075 1.09025C5.9875 0.69625 6.9625 0.4995 8 0.499999Z" fill="#FF5858" />
  </svg>;
export default function Disable({
  onDone = () => {}
}) {
  const L = useLanguage(["forms", "TwoFactor"]) as any;
  const setMessage = usePopup();
  const [displayMsg, setLoader] = useLoader(L("disable_2FA"));
  const {
    register,
    handleSubmit
  } = useForm();
  const {
    mutate
  } = useUser();
  const submitForm = useCallback(({
    code
  }: {
    code: string;
  }) => {
    setLoader(true);
    API.post("user/disable-two-factor", {
      code
    }).then(async res => {
      assert(res?.data?.success, res.data);
      setMessage({
        code: "responses.disable_2fa",
        type: 1
      });
      await mutate();
      onDone();
    }).catch(handleCatchPopup(setMessage)).finally(() => {
      setLoader(false);
      removeUrlQueries();
    });
  }, [mutate, onDone, setLoader, setMessage]);
  return <div className={st.container} data-sentry-component="Disable" data-sentry-source-file="disable.tsx">
      <h2>{L("disable_2FA")}</h2>
      <div className={st["warning"]}>
        {svg1}
        <p>{L("disabling_2FA")}</p>
      </div>

      <form id="disable-2fa-form" className="flex flex-col gap-2 mt-4" onSubmit={handleSubmit(submitForm)}>
        <label htmlFor="2fa-input">
          <span className="text-[12px] font-medium text-input-label-color-light text-opacity-75 cursor-pointer">
            {L("enter_six_digit_code")}
          </span>
        </label>

        <Input id="2fa-input" {...register("code", {
        required: true
      })} data-sentry-element="Input" data-sentry-source-file="disable.tsx" />

        <Button variant="green" className="w-full mt-4" type="submit" data-sentry-element="Button" data-sentry-source-file="disable.tsx">
          {displayMsg}
        </Button>
      </form>
    </div>;
}