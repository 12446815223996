import { useRouter } from "next/router";
import { ModalNav } from "../new-modal";
import Seeds from "./Seeds";
import Verify from "./Verify";
import useLanguage from "../../hooks/useLanguage";
import { removeUrlQueries } from "../../lib/tools/helpers";
import { useUserSession } from "../../hooks/useUserSession";
const TAB_COMPONENTS = {
  seeds: Seeds,
  verify: Verify
};
export default function FairPlay() {
  const router = useRouter();
  const L = useLanguage("FairPlay");
  const {
    userData
  } = useUserSession();
  const navItems = [...(userData ? [{
    query: {
      modal: "fairplay",
      tab: "seeds"
    },
    text: "Seeds"
  }] : []), {
    query: {
      modal: "fairplay",
      tab: "verify"
    },
    text: "Verify"
  }];
  const renderContent = () => {
    //if (router.query?.modal !== "fairplay") return null;

    const tab = router.query.tab;
    const Component = TAB_COMPONENTS[tab];
    if (!Component) {
      removeUrlQueries(router);
      return null;
    }
    if (tab === "seeds" && !userData) {
      return <Verify />;
    }
    return <Component data-sentry-element="Component" data-sentry-component="renderContent" data-sentry-source-file="index.jsx" />;
  };
  return <>
      <h2>{L("fair_play")}</h2>
      <ModalNav navItems={navItems} data-sentry-element="ModalNav" data-sentry-source-file="index.jsx" />
      {renderContent()}
    </>;
}