import { NewLogoIcon } from "@assets/icons/general/NewLogo";
import { Button } from "@components/new-ui/button";
import { Input } from "@components/new-ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import useLanguage, { Dict, useDictionary } from "@hooks/useLanguage";
import useLoader from "@hooks/useLoader";
import useMobile from "@hooks/useMobile";
import usePopup from "@hooks/usePopup";
import { getSessionData } from "@hooks/useSessions";
import { useUserSession } from "@hooks/useUserSession";
import { useTurnstile } from "@hooks/useTurnstile";
import SignupDropdown from "@items/SignupDropdown";
import { cn } from "@lib/utils";
import StorageService from "@lib/services/Storage.Service";
import { handleCatchPopup, removeUrlQueries, useLocale } from "@lib/tools/helpers";
import st from "@styles/components/Auth.module.scss";
import { UseSessionOptions } from "next-auth/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { formValidation, PasswordValidationTooltip } from "./password-validation";
import { NODE_API } from "@lib/api/api";
import { XIcon } from "@components/new-ui/dialog";
import { Button as NewButton } from "@components/new-ui/button";
import { useRouter } from "next/router";
export default function Register() {
  const isMobile = useMobile({
    breakPointOne: 1100,
    breakPointTwo: 525,
    breakPointThree: 100
  });
  const {
    password_validation_length,
    password_validation_lowercase,
    password_validation_uppercase,
    password_validation_special,
    form_validation_password_minlength,
    form_validation_password_required,
    form_validation_password_lowercase,
    form_validation_password_uppercase,
    form_validation_password_special,
    form_validation_username_required,
    form_validation_username_minlength,
    form_validation_email_required,
    form_validation_email_valid
  } = useDictionary("settings", "password_validation_length", "password_validation_lowercase", "password_validation_uppercase", "password_validation_special", "password_validation_match", "form_validation_password_minlength", "form_validation_password_required", "form_validation_password_lowercase", "form_validation_password_uppercase", "form_validation_password_special", "form_validation_username_required", "form_validation_username_minlength", "form_validation_email_required", "form_validation_email_valid");
  const formSchema = z.object({
    username: z.string().min(1, {
      message: form_validation_username_required
    }).min(formValidation.username.minLength, {
      message: form_validation_username_minlength
    }),
    password: z.string().min(1, {
      message: form_validation_password_required
    }).min(formValidation.password.minLength, {
      message: form_validation_password_minlength
    }).regex(formValidation.password.patterns.lowercase, {
      message: form_validation_password_lowercase
    }).regex(formValidation.password.patterns.uppercase, {
      message: form_validation_password_uppercase
    }).regex(formValidation.password.patterns.specialChar, {
      message: form_validation_password_special
    }),
    email: z.string().min(1, {
      message: form_validation_email_required
    }).email({
      message: form_validation_email_valid
    }),
    referral: z.string().optional(),
    terms: z.boolean().default(false).refine(val => val === true, {
      message: "Terms must be accepted"
    })
  });
  type FormValues = z.infer<typeof formSchema>;
  const {
    register,
    watch,
    handleSubmit,
    formState: {
      errors
    },
    setValue
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: "onSubmit",
    defaultValues: {
      email: "",
      username: "",
      password: "",
      referral: "",
      terms: false
    }
  });
  const router = useRouter();
  const [dropdownActive, setDropdownActive] = useState(false);
  const L = useLanguage(["forms", "common"]);
  const {
    signInToken: login,
    addTokenHeader
  } = useUserSession({} as UseSessionOptions<any>);
  const setMessage = usePopup();
  const inputRef = useRef(null);
  const language = useLocale();
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [displayMsg, setLoader] = useLoader(L("create_account", {}));
  const password = watch("password");
  const passwordValidationState = useMemo(() => ({
    length: password?.length >= formValidation.password.minLength,
    lowercase: formValidation.password.patterns.lowercase.test(password || ""),
    uppercase: formValidation.password.patterns.uppercase.test(password || ""),
    special: formValidation.password.patterns.specialChar.test(password || "")
  }), [password]);
  const {
    turnstileRef,
    token: turnstileToken,
    turnstileError,
    refresh
  } = useTurnstile();
  const [showTurnstile, setShowTurnstile] = useState(false);
  useEffect(() => {
    if (turnstileError) {
      refresh();
    }
  }, [turnstileError, refresh]);
  const onSubmit = async (formData: FormValues) => {
    if (!turnstileToken) {
      setShowTurnstile(true);
      refresh();
      return;
    }
    if (turnstileError) {
      refresh();
      return;
    }
    setLoader(true);
    NODE_API.post("v1/user/sign-up", {
      device: await getSessionData(),
      username: formData.username,
      email: formData.email,
      password: formData.password,
      affiliate_code: formData.referral ?? StorageService.getReferralCode(),
      agreed: formData.terms,
      language,
      secure_token: turnstileToken
    }, {
      ...addTokenHeader(),
      withCredentials: true
    }).then(res => res.data).then(async data => {
      if (!data?.tokens) throw new Error("No tokens");
      await login(data.tokens);
      const affiliateTag = StorageService.getAffiliateTag();
      if (affiliateTag) {
        const makeAffilkaRequest = async () => {
          try {
            const affiliateRes = await NODE_API.post("v1/user/affilka/set-tag", {
              code: affiliateTag
            }, {
              headers: {
                Authorization: `Bearer ${data.tokens.jwt}`
              },
              withCredentials: true,
              "axios-retry": {
                retries: 3,
                retryDelay: retryCount => {
                  const delay = 1000 * Math.pow(2, retryCount);
                  console.warn(`Retrying Affilka request (${retryCount + 1}/3) in ${delay}ms...`);
                  return delay;
                },
                retryCondition: (error: any) => {
                  return error?.response?.data?.error === "er_database_error";
                }
              }
            });
            if (affiliateRes.status === 201) {
              StorageService.removeAffiliateTag();
            }
          } catch (e) {
            console.error("Affilka request failed after retries:", e);
          }
        };
        await makeAffilkaRequest();
      }
      setMessage({
        code: "responses.successful-register",
        type: 1
      });
      StorageService.removeReferralCode();
      removeUrlQueries();
    }).catch(error => {
      if (error?.response?.data?.error === "er_invalid_secure_token") {
        refresh();
      }
      handleCatchPopup(setMessage)(error);
    }).finally(() => setLoader(false));
  };
  const handleTermsAndServiceClick = () => {
    window.open("/terms", "_blank");
  };

  // blocks opening the collapse
  const handleKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  useEffect(() => {
    const code = StorageService.getReferralCode();
    if (code) {
      setValue("referral", code);
      setDropdownActive(true);
    }
  }, [setValue]);
  return <form className="grid gap-[23px] relative" onKeyDown={handleKeyDown} onSubmit={handleSubmit(onSubmit)} data-sentry-component="Register" data-sentry-source-file="register.tsx">
      <NewButton variant="dark" size="sm" className={cn("absolute right-0 top-0 rounded-full z-10 h-[32px] w-[32px] min-h-[32px]", "[@media(max-width:550px)]:right-[max(16px,env(safe-area-inset-right))]")} onClick={e => {
      e.preventDefault();
      removeUrlQueries();
    }} data-sentry-element="NewButton" data-sentry-source-file="register.tsx">
        <XIcon className="h-3 w-3" data-sentry-element="XIcon" data-sentry-source-file="register.tsx" />
      </NewButton>

      <div className="grid gap-[10px]">
        <div className="flex justify-start gap-4 items-center">
          <NewLogoIcon height={25} width={25} data-sentry-element="NewLogoIcon" data-sentry-source-file="register.tsx" />
          <h2 className="text-lg !font-medium">{L("register-title", {})}</h2>
        </div>
      </div>

      <div className="grid gap-4 ">
        <div className="grid gap-2 relative mb-[4px]">
          <label htmlFor="username-input" className="flex justify-between items-center">
            <Dict name="username" as="span" section="forms" className="text-[12px] font-medium text-input-label-color-light text-opacity-75 cursor-pointer" data-sentry-element="Dict" data-sentry-source-file="register.tsx" />
            {errors.username && <span className="text-[#EC4848] text-[12px]">
                {errors.username.message}
              </span>}
          </label>

          <div>
            <Input id="username-input" aria-invalid={!!errors.username} {...register("username", {
            required: true
          })} data-sentry-element="Input" data-sentry-source-file="register.tsx" />
          </div>
        </div>

        <div className="grid gap-2">
          <label htmlFor="email-input" className="flex justify-between items-center">
            <Dict name="email" as="span" section="forms" className="text-[12px] font-medium text-input-label-color-light text-opacity-75 cursor-pointer" data-sentry-element="Dict" data-sentry-source-file="register.tsx" />
            {errors.email && <span className="text-[#EC4848] text-[12px]">
                {errors.email.message}
              </span>}
          </label>

          <div className="grid gap-2 relative">
            <div>
              <Input id="email-input" aria-invalid={!!errors.email} type="email" {...register("email", {
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
            })} data-sentry-element="Input" data-sentry-source-file="register.tsx" />
            </div>
          </div>
        </div>

        <div className="grid gap-2">
          <label htmlFor="password-input">
            <Dict name="password" as="span" section="forms" className="text-[12px] font-medium text-input-label-color-light text-opacity-75 cursor-pointer" data-sentry-element="Dict" data-sentry-source-file="register.tsx" />
          </label>

          <PasswordValidationTooltip validationRules={[{
          key: "length",
          text: password_validation_length
        }, {
          key: "lowercase",
          text: password_validation_lowercase
        }, {
          key: "uppercase",
          text: password_validation_uppercase
        }, {
          key: "special",
          text: password_validation_special
        }]} passwordValidationState={passwordValidationState} forceShow={isPasswordFocused} placement={isMobile ? "top-end" : "right"} data-sentry-element="PasswordValidationTooltip" data-sentry-source-file="register.tsx">
            <div>
              <Input id="password-input" type="password" aria-invalid={!!errors.password} onFocus={() => setIsPasswordFocused(true)} {...register("password", {
              required: true,
              onBlur: () => setIsPasswordFocused(false)
            })} data-sentry-element="Input" data-sentry-source-file="register.tsx" />
            </div>
          </PasswordValidationTooltip>
        </div>

        <SignupDropdown defaultActiveState={!!watch("referral") || dropdownActive} classType={["expand"]} dropDownStyle="register-modal" inputRef={inputRef} data-sentry-element="SignupDropdown" data-sentry-source-file="register.tsx">
          <div className="grid gap-1 relative">
            <div>
              <Input aria-invalid={!!errors.referral} {...register("referral")} data-sentry-element="Input" data-sentry-source-file="register.tsx" />
            </div>
            {errors.referral && <p className="text-[#EC4848] text-[13px]">
                {errors.referral.message}
              </p>}
          </div>
        </SignupDropdown>

        <div className="flex justify-center">
          <div className="cf-turnstile w-full mb-[-10px]" ref={turnstileRef} />
        </div>

        <div className={st["checkbox-container"]}>
          <input type="checkbox" id="terms" {...register("terms")} />

          <label htmlFor="terms" className={cn(!!errors.terms && "before:!border-[#EC4848]")}>
            {L("register-checkbox", {})}
            <p onClick={handleTermsAndServiceClick} className="text-[#5e679e] text-[12px] p-0 inline-block pl-1 [&_span]:text-[#7179a5] [&_span]:hover:text-[#9ca4cf] [&_span]:cursor-pointer [&_span]:transition-colors [&_span]:duration-200">
              <Dict name="register-terms" as="span" section="forms" data-sentry-element="Dict" data-sentry-source-file="register.tsx" />
            </p>
          </label>
        </div>

        <Button className="p-[20px] h-[50px] border" type="submit" data-sentry-element="Button" data-sentry-source-file="register.tsx">
          {displayMsg}
        </Button>
      </div>
    </form>;
}