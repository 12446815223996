import { useEffect, useReducer, useState } from "react";
import st from "../../styles/components/FairPlay.module.scss";
import { Row, Col } from "../../items/Layout";
import InputItem from "../../items/InputItem";
import Button from "../../items/Button";
import InputDropDown from "../../items/InputDropDown";
import useLanguage from "../../hooks/useLanguage";
import usePopup from "../../hooks/usePopup";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
const PlinkoFairness = dynamic(() => import("../ProvablyFair/PlinkoFairness"));
const CaseFairness = dynamic(() => import("../ProvablyFair/CaseFairness"));
const DiceFairness = dynamic(() => import("../ProvablyFair/DiceFairness"));
const CrashFairness = dynamic(() => import("../ProvablyFair/CrashFairness"));
const RaffleFairness = dynamic(() => import("../ProvablyFair/raffle-fairness"));
const RouletteFairness = dynamic(() => import("../ProvablyFair/RouletteFairness"));
const CaseBattlesFairness = dynamic(() => import("../ProvablyFair/CaseBattlesFairness"));
const DoubleDownFairness = dynamic(() => import("../ProvablyFair/DoubleDownFairness"));
const KenoFairness = dynamic(() => import("../ProvablyFair/KenoFairness"));
const MinesFairness = dynamic(() => import("../ProvablyFair/MinesFairness"));
const BlackjackFairness = dynamic(() => import("../ProvablyFair/blackjack-fairness"));
const LimboFairness = dynamic(() => import("../ProvablyFair/LimboFairness"));
const initState = {
  clientSeed: "",
  errors: {}
};
export default function Verify({
  betDetails
}) {
  const L = useLanguage(["forms", "FairPlay"]);
  // const [state, dispatch] = useReducer(Reducer, initState);
  const [seedData, setSeedData] = useState({});
  const [serverSeed, setServerSeed] = useState("");
  const [nonce, setNonce] = useState("");
  const [clientSeed, setClientSeed] = useState("");
  const [gameName, setGameName] = useState("plinko");
  const [gameParameters, setGameParameters] = useState({});
  const setMessage = usePopup();
  const [response, setResponse] = useState("");
  const router = useRouter();
  const {
    betId
  } = router.query;
  useEffect(() => {
    //console.log("Verify Query: ",router.query)
    if (router.query?.gameName) {
      setGameName(router.query?.gameName);
    } else {
      setGameName(router.query?.game);
    }
    if (router.query?.serverSeed) {
      setServerSeed(router.query?.serverSeed);
      setNonce(router.query?.nonce);
      setClientSeed(router.query?.clientSeed);
      if (router.query?.game === "plinko") {
        setGameParameters({
          risk_type: router.query?.plinkoRiskType,
          row_count: router.query?.plinkoRowCount
        });
      } else if (router.query?.gameName === "mines") {
        setGameParameters({
          gridSize: router.query?.gridSize,
          minesCount: router.query?.minesCount
        });
      }
    }
  }, [router]);
  const renderFairnessItem = () => {
    switch (gameName) {
      case "plinko":
        return <PlinkoFairness clientSeed={clientSeed} serverSeed={serverSeed} nonce={nonce} gameParameters={gameParameters} />;
      case "dice":
        return <DiceFairness clientSeed={clientSeed} serverSeed={serverSeed} nonce={nonce}
        // gameParameters={gameHistory?.game_parameters}
        />;
      case "case open":
        return <CaseFairness clientSeed={clientSeed} serverSeed={serverSeed} nonce={nonce}
        // gameParameters={gameHistory?.game_parameters}
        />;
      case "crash":
        return <CrashFairness clientSeed={clientSeed} serverSeed={serverSeed} nonce={nonce}
        // gameParameters={gameHistory?.game_parameters}
        />;
      case "case battles":
        return <CaseBattlesFairness serverSeed={serverSeed} clientSeed={clientSeed} betId={betId} />;
      case "double down":
        return <DoubleDownFairness clientSeed={clientSeed} serverSeed={serverSeed} nonce={nonce} />;
      case "keno":
        return <KenoFairness clientSeed={clientSeed} serverSeed={serverSeed} nonce={nonce} />;
      case "mines":
        return <MinesFairness clientSeed={clientSeed} serverSeed={serverSeed} nonce={nonce} gridSizeData={gameParameters?.gridSize} minesCount={gameParameters?.minesCount} />;
      case "raffle":
        return <RaffleFairness eosSeed={clientSeed} serverSeed={serverSeed} lastNumber={router.query?.lastNumber} />;
      case "blackjack":
        return <BlackjackFairness clientSeed={clientSeed} serverSeed={serverSeed} nonce={nonce} />;
      case "limbo":
        return <LimboFairness clientSeed={clientSeed} serverSeed={serverSeed} nonce={nonce} />;
      default:
        return <RouletteFairness clientSeed={clientSeed} serverSeed={serverSeed} nonce={nonce}
        //   gameParameters={gameHistory?.game_parameters}
        />;
    }
  };
  return <div className={st["container"]} data-sentry-component="Verify" data-sentry-source-file="Verify.jsx">
      <div className={st["outcome-container"]}>
        <h3>{L("outcome")}</h3>
        {renderFairnessItem()}
      </div>
      <form className={st["form-container"]}>
        <h3>{L("game_parameters")}</h3>
        <Col data-sentry-element="Col" data-sentry-source-file="Verify.jsx">
          <InputDropDown selectedOption={gameName}
        // label={"Provider"}
        name={"Game"} classType={["search-dropdown"]} onSelectOption={e => setGameName(e.target.value)} options={[{
          value: "plinko",
          label: "Plinko"
        }, {
          value: "dice",
          label: "Dice"
        }, {
          value: "case open",
          label: "Case Open"
        },
        // { value: "crash", label: "Crash" },
        // { value: "roulette", label: "Roulette" },
        {
          value: "case battles",
          label: "Case Battles"
        }, {
          value: "double down",
          label: "Double Down"
        }, {
          value: "keno",
          label: "Keno"
        }, {
          value: "mines",
          label: "Mines"
        }, {
          value: "raffle",
          label: "Raffle"
        }, {
          value: "blackjack",
          label: "Blackjack"
        }, {
          value: "limbo",
          label: "Limbo"
        }]} data-sentry-element="InputDropDown" data-sentry-source-file="Verify.jsx" />

          <InputItem value={clientSeed} method={setClientSeed}
        // copy={true}
        classType={["mid-height", "modal-inputs"]} label={L("client_seed")} data-sentry-element="InputItem" data-sentry-source-file="Verify.jsx" />

          <InputItem value={serverSeed} method={setServerSeed} classType={["mid-height", "modal-inputs"]} label={L("server_seed")} data-sentry-element="InputItem" data-sentry-source-file="Verify.jsx" />
          <InputItem value={nonce} method={setNonce}
        // copy={true}
        classType={["mid-height", "modal-inputs"]} label={L("nonce")} data-sentry-element="InputItem" data-sentry-source-file="Verify.jsx" />
        </Col>
        <div className={st["rotation-section"]}>
          <div className={st["rotate-seed-section"]}>
            <span>{L("how_results_are_generated")}</span>

            <Button text={L("calculations")} classType={["plain", "half-width", "grey-button", "modal-grey-button"]} buttonType={"link"} url={{
            pathname: "/calculations"
          }} data-sentry-element="Button" data-sentry-source-file="Verify.jsx" />
          </div>
        </div>
      </form>
    </div>;
}