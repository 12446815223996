import st from "../../styles/games/CaseBattles.module.scss";
import InputItem from "../../items/InputItem";
import useLanguage from "../../hooks/useLanguage";
import InputDropDown from "../../items/InputDropDown";
import SearchIcon from "../../assets/icons/general/SearchIcon";
export default function CaseOpenInputs({
  search,
  setSearch,
  sortByCategory,
  setSortByCategory,
  sortBy,
  setSortBy,
  cases
}) {
  const L = useLanguage(["common"]);
  return <div className={st["search-area"]} data-sentry-component="CaseOpenInputs" data-sentry-source-file="CaseOpenInputs.jsx">
      <InputItem classType={["search", "search-input"]} name="search" value={search} method={setSearch} placeholder={L("search")} icon={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.19526 9.19526C9.45561 8.93491 9.87772 8.93491 10.1381 9.19526L13.8047 12.8619C14.0651 13.1223 14.0651 13.5444 13.8047 13.8047C13.5444 14.0651 13.1223 14.0651 12.8619 13.8047L9.19526 10.1381C8.93491 9.87772 8.93491 9.45561 9.19526 9.19526Z" fill="#5B628C" stroke="#5B628C" strokeWidth="0.5" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.66667 3.33333C4.82572 3.33333 3.33333 4.82572 3.33333 6.66667C3.33333 8.50762 4.82572 10 6.66667 10C8.50762 10 10 8.50762 10 6.66667C10 4.82572 8.50762 3.33333 6.66667 3.33333ZM2 6.66667C2 4.08934 4.08934 2 6.66667 2C9.244 2 11.3333 4.08934 11.3333 6.66667C11.3333 9.244 9.244 11.3333 6.66667 11.3333C4.08934 11.3333 2 9.244 2 6.66667Z" fill="#5B628C" stroke="#5B628C" strokeWidth="0.5" />
          </svg>} data-sentry-element="InputItem" data-sentry-source-file="CaseOpenInputs.jsx" />
      <div className={st["filter-area"]}>
        <InputDropDown selectedOption={sortByCategory}
      // label={"SortBy"}
      name={"SortByCategory"} classType={["search-dropdown"]} placeholder={L("sort_by")} onSelectOption={event => setSortByCategory(event.target.value)} options={[{
        value: "all-categories",
        label: "All Categories"
      }, ...cases.map(caseName => ({
        value: caseName,
        label: caseName
      }))]} data-sentry-element="InputDropDown" data-sentry-source-file="CaseOpenInputs.jsx" />
        <InputDropDown selectedOption={sortBy}
      // label={"SortBy"}
      name={"SortBy"} classType={["search-dropdown"]} placeholder={L("high-to-low")} onSelectOption={event => setSortBy(event.target.value)} options={[{
        value: "high-to-low",
        label: L("high-to-low")
      }, {
        value: "low-to-high",
        label: L("low-to-high")
      }, {
        value: "",
        label: L("recommended")
      }]} data-sentry-element="InputDropDown" data-sentry-source-file="CaseOpenInputs.jsx" />
      </div>
    </div>;
}