import st from "../../styles/components/PromoTerms.module.scss";
import useLanguage from "../../hooks/useLanguage";
import API from "../../lib/api/api";
import Button from "../../items/Button";
import usePopup from "../../hooks/usePopup";
import { useRouter } from "next/router";
import useSWR from "swr";
import { useUserSession } from "../../hooks/useUserSession";
const fetcher = url => API.get(url).then(res => res.data);
export default function CancelPromotionModal({}) {
  const router = useRouter();
  const L = useLanguage(["common", "Wallet", "PromotionsPage", "PromoTerms"]);
  const setMessage = usePopup();
  const {
    mutate: userMutate
  } = useUserSession();
  const {
    // data: promoData,
    mutate: promoMutate
    // error,
  } = useSWR("promotions/list", fetcher);
  const cancelPromoMethod = promoId => {
    let data = {
      promo_code: promoId
    };
    API.post("promotions/cancel", data).then(res => {
      console.log("Cancel Promo Response", res);
      if (res.data.success) {
        setMessage({
          code: "responses.su_promotion_cancelled",
          type: 1
        });
      } else {
        setMessage({
          code: "responses." + res.data.error,
          type: 0
        });
      }
      setTimeout(() => {
        promoMutate();
        userMutate();
        router.push("promotions");
      }, 100);
    }).catch(err => {});
  };
  return <div className={st["promo-terms-cancel-container"]} data-sentry-component="CancelPromotionModal" data-sentry-source-file="CancelPromotionModal.jsx">
      <div className={st["promo-terms-cancel"]}>
        <h2>{"Cancel Promotion"}</h2>

        <p>{L("are_you_sure")}</p>
        <p className={st["promo-warning-cancel"]}>{L("are_you_sure_desc")}</p>
      </div>
      <div className={st["promo-terms-buttons-cancel"]}>
        <Button param={false} buttonType={"link"} url={{
        pathname: "/promotions"
      }} text={L("no")} classType={["grey-button", "cancel-button", "fifth-width", "max-height-button"]} data-sentry-element="Button" data-sentry-source-file="CancelPromotionModal.jsx" />

        <Button text={L("yes")} method={() => cancelPromoMethod(router.query?.type)} classType={["blue-button", "has-border", "fifth-width", "max-height-button"]} data-sentry-element="Button" data-sentry-source-file="CancelPromotionModal.jsx" />
      </div>
    </div>;
}