import { useEffect, useState } from "react";
import st from "@styles/components/Wallet.module.scss";
import API from "@lib/api/api";
import PromotionsIcon from "@assets/icons/general/PromotionsIcon";
import useLanguage from "@hooks/useLanguage";
import { useUserSession } from "@hooks/useUserSession";
import ValueDisplay from "@items/ValueDisplay";
import { logError } from "@lib/tools/logger";
export default function ActivePromoBar() {
  const {
    userData,
    hasUserData
  } = useUserSession();
  const L = useLanguage(["Wallet"]);
  const [wagerLockValue, setWagerLockValue] = useState(0);
  const hasPromoActive = userData?.balance_promo;
  useEffect(() => {
    if (!hasUserData) {
      return;
    } else {
      if (hasPromoActive) {
        API.post("user/wager-lock", {
          balance_type: 1
        }).then(res => {
          if (res.data.success) {
            setWagerLockValue(res.data["wager-lock"]);
          }
        }).catch(logError);
      }
    }
  }, [hasPromoActive, hasUserData]);
  return hasPromoActive && wagerLockValue > 0 ? <div className={st["active-promo-bar-container"]} data-sentry-component="ActivePromoBar" data-sentry-source-file="active-promo-bar.tsx">
      <PromotionsIcon data-sentry-element="PromotionsIcon" data-sentry-source-file="active-promo-bar.tsx" />
      <div className={st["text"]}>
        {L("your_promotion_has", {})}
        <ValueDisplay amount={wagerLockValue} size={"small"} data-sentry-element="ValueDisplay" data-sentry-source-file="active-promo-bar.tsx" />{" "}
        {L("remaining", {})}
      </div>
    </div> : null;
}