import useLanguage, { useDict } from "@hooks/useLanguage";
import useWallet from "@hooks/useWallet";
import Button from "@items/Button";
import ValueDisplay from "@items/ValueDisplay";
import ValueIcons from "@items/ValueIcons";
import API from "@lib/api/api";
import { getCurrencySymbol, numFormat } from "@lib/tools/helpers";
import st from "@styles/components/BetResult.module.scss";
import React, { useMemo } from "react";
import useSWR from "swr";
import WarningNotice from "../WarningNotice";
export type GameResult = {
  success: boolean;
  result: Array<{
    id: number | string;
    game: {
      id: number | string;
      name: string;
      url: string;
      // hard to type each game has a different parameters
      parameters: any;
      icon: string;
      banner: string;
      provider: string;
    };
    value: string;
    currency: {
      amount: string;
      code: string;
      icon: string;
    };
    payout: string;
    currency_payout: string;
    multiplier: string;
    time: number;
    public_id: number;
    user: {
      name: string;
      public_id: any;
      rank: string;
    };
    complete: boolean;
  }>;
};
export const getGameResult = async ([, id]: [string, string]) => API.post<GameResult>(`public/game-results`, {
  id
}).then(res => {
  const result = res?.data?.result?.[0];
  if (!result) throw new Error("No result found");
  result.id = Number.isNaN(+id) ? id : +id;
  return result;
});
export const useBetDetails = (betId: string) => useSWR(betId ? [`public/game-results`, betId] : null, ([url, id]) => getGameResult([url, id]),
// fix as
{
  revalidateOnFocus: false,
  revalidateOnReconnect: false
});
function ShowInHouseDetails({
  betDetails
}: {
  betDetails: GameResult["result"][0];
}) {
  const L = useLanguage(["BetResult"]);
  if (!betDetails?.game?.parameters) return null;
  switch (betDetails?.game?.icon) {
    case "plinko":
      return <div className="py-5 text-center relative">
          <div className="relative left-0 top-1/2 h-[1px] bg-gradient-to-b from-transparent via-[rgba(255,255,255,0.35)] to-transparent flex items-center justify-center">
            <h3 className="absolute px-4 text-[rgba(232,229,255,0.25)] text-[14px] font-medium bg-[#191f3b]">
              {L("gameDetails", {})}
            </h3>
          </div>

          <div className={`${st["item-row"]} mt-8`}>
            <div className={st["detail-item"]}>
              <span>{L("bucket", {})}</span>
              <div className={st["value"]}>
                <span>{betDetails?.game?.parameters.bucket}x</span>
              </div>
            </div>
            <div className={st["detail-item"]}>
              <span>{L("riskLevel", {})}</span>
              <div className={st["value"]}>
                <span>{betDetails?.game?.parameters.risk_type}</span>
              </div>
            </div>
            <div className={st["detail-item"]}>
              <span>{L("rowCount", {})}</span>
              <div className={st["value"]}>
                <span>{betDetails?.game?.parameters.row_count}</span>
              </div>
            </div>
          </div>
        </div>;
    case "crash":
      return <div className="py-5 text-center relative">
          <div className="relative left-0 top-1/2 h-[1px] bg-gradient-to-b from-transparent via-[rgba(255,255,255,0.35)] to-transparent flex items-center justify-center">
            <h3 className="absolute px-4 text-[rgba(232,229,255,0.25)] text-[14px] font-medium bg-[#191f3b]">
              {L("gameDetails", {})}
            </h3>
          </div>
          <div className={`${st["item-row"]} mt-8`}>
            <div className={st["detail-item"]}>
              <span>{L("crashPoint", {})}</span>
              <div className={st["value"]}>
                <span>{betDetails?.game?.parameters.crash_point}x</span>
              </div>
            </div>
          </div>
        </div>;
    case "case-opening":
      return <div className="py-5 text-center relative">
          <div className="relative left-0 top-1/2 h-[1px] bg-gradient-to-b from-transparent via-[rgba(255,255,255,0.35)] to-transparent flex items-center justify-center">
            <h3 className="absolute px-4 text-[rgba(232,229,255,0.25)] text-[14px] font-medium bg-[#191f3b]">
              {L("gameDetails", {})}
            </h3>
          </div>
          <div className={`${st["item-row"]}  mt-8 ${st["wrap"]}`}>
            <div className={`${st["detail-item"]}  ${st["full-width"]}`}>
              <span>{L("itemName", {})}</span>
              <div className={st["value"]}>
                <span>{betDetails?.game?.parameters.winning_item?.name}</span>
              </div>
            </div>
            <div className={st["detail-item"]}>
              <span>{L("chance", {})}</span>
              <div className={st["value"]}>
                <span>
                  %{betDetails?.game?.parameters.winning_item?.chance}
                </span>
              </div>
            </div>
            <div className={st["detail-item"]}>
              <span>{L("value", {})}</span>
              <div className={st["value"]}>
                <ValueIcons code={"rain"} size={"mid"} />{" "}
                <span>{betDetails?.game?.parameters.winning_item?.value}</span>
              </div>
            </div>
          </div>
          <div className="absolute right-0 top-1/2 w-[1px] h-[43px] -translate-y-1/2 bg-gradient-to-b from-transparent via-[rgba(255,255,255,0.1)] to-transparent" />
        </div>;
    case "roulette":
      const colorMap: {
        [key: string]: string;
      } = {
        r: "Red",
        b: "Black",
        g: "Green"
      };
      return <div className="py-5 text-center relative">
          <div className="relative left-0 top-1/2 h-[1px] bg-gradient-to-b from-transparent via-[rgba(255,255,255,0.35)] to-transparent flex items-center justify-center">
            <h3 className="absolute px-4 text-[rgba(232,229,255,0.25)] text-[14px] font-medium bg-[#191f3b]">
              {L("gameDetails", {})}
            </h3>
          </div>
          <div className={`${st["item-row"]} mt-8`}>
            <div className={st["detail-item"]}>
              <span>{L("userBetColor", {})}</span>
              <div className={st["value"]}>
                <span>
                  {colorMap[betDetails?.game?.parameters?.user_bet_color || ""]}
                </span>
              </div>
            </div>
            <div className={st["detail-item"]}>
              <span>{L("winColor", {})}</span>
              <div className={st["value"]}>
                <span>
                  {colorMap[betDetails?.game?.parameters?.winning_color || ""]}
                </span>
              </div>
            </div>
            <div className={st["detail-item"]}>
              <span>{L("winningNumber", {})}</span>
              <div className={st["value"]}>
                <span>{betDetails?.game?.parameters?.winning_number}</span>
              </div>
            </div>
          </div>
        </div>;
    case "dice":
      const toCustomDecimal = (num: number): string => {
        const integerPart = Math.floor(num / 100);
        const decimalPart = num % 100;
        return integerPart + "." + String(decimalPart).padStart(2, "0");
      };
      return <div className="py-5 text-center relative">
          <div className="relative left-0 top-1/2 h-[1px] bg-gradient-to-b from-transparent via-[rgba(255,255,255,0.35)] to-transparent flex items-center justify-center">
            <h3 className="absolute px-4 text-[rgba(232,229,255,0.25)] text-[14px] font-medium bg-[#191f3b]">
              {L("gameDetails", {})}
            </h3>
          </div>
          <div className={`${st["item-row"]} mt-8`}>
            <div className={st["detail-item"]}>
              <span>{L("direction", {})}</span>
              <div className={st["value"]}>
                <span>
                  {betDetails?.game?.parameters?.over ? L("over", {}) : L("under", {})}
                </span>
              </div>
            </div>
            <div className={st["detail-item"]}>
              <span>{L("userRoll", {})}</span>
              <div className={st["value"]}>
                <span>{betDetails?.game?.parameters?.roll}</span>
              </div>
            </div>
            <div className={st["detail-item"]}>
              <span>{L("rollResult", {})}</span>
              <div className={st["value"]}>
                <span>
                  {toCustomDecimal(betDetails?.game?.parameters?.roll_result || 0)}
                </span>
              </div>
            </div>
          </div>
        </div>;
    default:
      return null;
  }
}

// Shows the results section in the game popup
export default function Result({
  betDetails
}) {
  const L = useLanguage(["BetResult"]);
  const Dict = useDict(["BetResult"]);
  const {
    activeRate
  } = useWallet();

  // Special Case for Double Down
  const linkToGame = useMemo(() => {
    if (betDetails?.game?.url?.includes("double-down")) {
      return "/casino/originals/case-battles";
    } else if (betDetails?.game?.url?.includes("mines")) {
      return "/casino/originals/mines-game";
    } else {
      return betDetails?.game?.url;
    }
  }, [betDetails?.game?.url]);
  const displayGameName = useMemo(() => {
    if (betDetails?.game?.name === "Double Down") {
      return "Case Battles";
    } else {
      return betDetails?.game?.name || "";
    }
  }, [betDetails?.game?.name]);
  const payout = betDetails?.currency_payout;
  const amount = betDetails?.currency?.amount;
  const currency = betDetails?.currency?.code;
  const multiplier = useMemo(() => {
    return amount && payout ? +payout / +amount : betDetails?.multiplier;
  }, [amount, betDetails?.multiplier, payout]);
  return <div className={st["result-container"]} data-sentry-component="Result" data-sentry-source-file="Result.tsx">
      <div className={st["item-content"]}>
        <div className={st["item-row"]}>
          <div className={st["detail-item"]}>
            <span>{L("amount", {})}</span>
            <div className={st["value"]}>
              <ValueDisplay size={"small"} textIcon amount={amount} currencyCode={currency} data-sentry-element="ValueDisplay" data-sentry-source-file="Result.tsx" />
            </div>
          </div>
          <div className={st["detail-item"]}>
            <span>{L("multiplier", {})}</span>
            <div className={st["value"]}>
              <span>
                {typeof multiplier === "number" ? multiplier.toFixed(2) : "0.00"}
                x
              </span>
            </div>
          </div>
          <div className={st["detail-item"]}>
            <span>{L("payout", {})}</span>
            <div className={st["value"]}>
              <ValueDisplay size={"small"} textIcon amount={payout} currencyCode={currency} data-sentry-element="ValueDisplay" data-sentry-source-file="Result.tsx" />
            </div>
          </div>
        </div>
      </div>

      {activeRate?.display?.icon !== betDetails?.currency?.icon && <WarningNotice type="neutral">
          <Dict name="original_bet_notice" className={st["notice-message"]} replacements={{
        0: `<b>${getCurrencySymbol(betDetails?.currency?.code)}${numFormat(betDetails?.currency?.amount)} </b>`
      }} />
        </WarningNotice>}

      {betDetails?.game?.parameters && <ShowInHouseDetails betDetails={betDetails} />}

      <Button buttonType={"link"} hardUrl={{
      pathname: linkToGame
    }} classType={["blue", "full-width"]} text={`${L("play", {})} ${displayGameName}`} data-sentry-element="Button" data-sentry-source-file="Result.tsx" />
    </div>;
}