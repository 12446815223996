import { Button as NewButton } from "@components/new-ui/button";
import { Dialog, DialogContent, XIcon } from "@components/new-ui/dialog";
import usePopup from "@hooks/usePopup";
import { useUserDetails } from "@hooks/useUserSession";
import Button from "@items/Button";
import { cn } from "@lib/utils";
import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";
import { startTransition, useCallback, useEffect, useState } from "react";
import CaseBattlesModal from "../Games/CaseBattles/CaseBattlesModal";
import CaseItemsModal from "../Games/CaseBattles/CaseItemsModal";
import Auth from "./Auth";
import BetResult from "./BetResult";
import Blocker from "./Blocker";
import FairPlay from "./FairPlay";
import GiftCardModal from "./GiftCardModals/GiftCardModal.jsx";
import PromoTerms from "./PromoTerms";
import CancelPromotionModal from "./PromoTerms/CancelPromotionModal";
import RacesModal from "./RacesModal";
import SearchModal from "./SearchModal";
import Steam from "./Steam";
import UserProfile from "./UserProfile";
import Wallet from "./Wallet";
import WalletSettings from "./WalletSettings";
import ResetPassword from "./reset-password";
import TwoFa from "./two-fa";
import UserDisplay from "./user-display";
import VaultModal from "./vault-modal";
import VerifyEmailModal from "./verify-email-modal";
interface ModalProps {
  classType?: string[];
}
interface ModalConfig {
  component: any;
  className?: string;
}
const MODAL_CONFIG: Record<string, ModalConfig> = {
  "how-races-work": {
    component: RacesModal
  },
  auth: {
    component: Auth
  },
  wallet: {
    component: Wallet
  },
  user: {
    component: UserDisplay
  },
  fairplay: {
    component: FairPlay
  },
  bet: {
    component: BetResult
  },
  twoFactor: {
    component: TwoFa
  },
  blocker: {
    component: Blocker
  },
  profile: {
    component: UserProfile
  },
  steam: {
    component: Steam
  },
  recover: {
    component: ResetPassword
  },
  promotion: {
    component: PromoTerms,
    className: "promotions-modal"
  },
  "promotion-cancel": {
    component: CancelPromotionModal,
    className: "promotions-modal"
  },
  "gift-card": {
    component: GiftCardModal,
    className: "gift-card-modals"
  },
  vault: {
    component: VaultModal,
    className: "gift-card-modals"
  },
  "case-battles-select": {
    component: CaseBattlesModal
  },
  "case-list": {
    component: CaseItemsModal
  },
  search: {
    component: SearchModal
  },
  "wallet-settings": {
    component: WalletSettings
  },
  "verify-email": {
    component: VerifyEmailModal
  }
};
export function Modal({
  classType = []
}: ModalProps) {
  const router = useRouter();
  const setMessage = usePopup();
  const search = useSearchParams();
  const modalType = search.get("modal");
  const {
    data: userDetails
  } = useUserDetails();
  const [isChallengeOpen, setIsChallengeOpen] = useState(false);
  const handleReplace = useCallback(() => {
    startTransition(() => {
      const {
        asPath,
        query
      } = router;
      const playQuery = query.play ? `?play=${query.play}` : "";
      router.replace(asPath.split("?")[0] + playQuery, undefined, {
        shallow: true
      });
    });
  }, [router]);
  useEffect(() => {
    if (!modalType || typeof window === "undefined") return;
    const handleRecaptchaChange = (event: Event) => {
      const challengeIframe = document.querySelector('iframe[title="recaptcha challenge expires in two minutes"]');
      const recaptchaOverlay = document.querySelector('div[style*="z-index: 2000000000"]');
      const isOpen = !!challengeIframe && !!recaptchaOverlay;
      setIsChallengeOpen(isOpen);
      if (isOpen) {
        document.body.style.pointerEvents = "auto !important";
      } else {
        document.body.style.pointerEvents = "";
      }
    };
    document.addEventListener("click", handleRecaptchaChange);
    document.addEventListener("focus", handleRecaptchaChange);
    document.addEventListener("blur", handleRecaptchaChange);
    handleRecaptchaChange(new Event("init"));
    return () => {
      document.removeEventListener("click", handleRecaptchaChange);
      document.removeEventListener("focus", handleRecaptchaChange);
      document.removeEventListener("blur", handleRecaptchaChange);
      document.body.style.pointerEvents = "";
      setIsChallengeOpen(false);
    };
  }, [modalType]);
  if (!modalType || !MODAL_CONFIG[modalType]) return null;
  const isSensitiveModal = modalType === "wallet" || modalType === "gift-card";
  if (isSensitiveModal && userDetails?.self_exclusion !== undefined) {
    setMessage({
      code: "responses.er_self_exclusion_modal_self_exclusion_active",
      type: 0
    });
    handleReplace();
    return null;
  }
  const ModalComponent = MODAL_CONFIG[modalType].component;
  const modalClassName = MODAL_CONFIG[modalType].className;
  const filteredClassType = classType.filter(clt => clt !== "promotions-modal" && clt !== "gift-card-modal");
  if (modalClassName) {
    filteredClassType.push(modalClassName);
  }
  return <>
      {modalType && <Dialog open={!!modalType} onOpenChange={handleReplace}>
          <DialogContent className={cn("p-6", modalType === "auth" && "[@media(min-width:600px)]:p-0 h-[100vh] xs:max-h-[800px]", "[@media(max-width:600px)]:pt-[max(24px,env(safe-area-inset-top))]", "[@media(max-width:600px)]:pb-[max(40px,env(safe-area-inset-bottom))]", "[@media(max-width:600px)]:pl-[max(16px,env(safe-area-inset-left))]", "[@media(max-width:600px)]:pr-[max(16px,env(safe-area-inset-right))]", ...filteredClassType)} onPointerDownOutside={e => {
        if (isChallengeOpen) {
          e.preventDefault();
        }
      }}>
            {modalType !== "auth" ? <NewButton variant="dark" size="sm" className={cn("absolute right-6 top-6 rounded-full z-10 h-[32px] w-[32px] min-h-[32px]")} onClick={handleReplace}>
                <XIcon className="h-3 w-3" />
              </NewButton> : null}

            <ModalComponent />
          </DialogContent>
        </Dialog>}
    </>;
}
export function ModalNav({
  navItems,
  type = "tab"
}) {
  return <div className="inline-flex flex-wrap items-center gap-[2px] my-5 bg-gradient-to-b from-[#7C83B1]/20 to-[#5E679E]/20 border border-solid border-[#5E679E]/35 rounded-md p-[3px] w-fit" data-sentry-component="ModalNav" data-sentry-source-file="new-modal.tsx">
      {navItems.map((item, i) => {
      return <Button isShallow={true} showActive={true} key={i} text={item.text} buttonType={"link"} classType={[type, "live-tab", "login-tab"]} query={item.query} />;
    })}
    </div>;
}