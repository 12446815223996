import { useForm } from "react-hook-form";
import useLanguage, { Dict } from "../../hooks/useLanguage";
import API from "../../lib/api/api";
import usePopup from "../../hooks/usePopup";
import useLoader from "../../hooks/useLoader.js";
import { useRouter } from "next/router";
import { Input } from "@components/new-ui/input";
import { Button } from "@components/new-ui/button";
import Link from "next/link";
import AltSignIn from "./alt-signin";
import { NewLogoIcon } from "@assets/icons/general/NewLogo";
import { useModifySearchParams } from "@hooks/useModifySearchParams";
import { useTurnstile } from "@hooks/useTurnstile";
import { XIcon } from "@components/new-ui/dialog";
import { Button as NewButton } from "@components/new-ui/button";
import { cn } from "@lib/utils";
type FormValues = {
  usernameOrEmail: string;
};
export default function RecoverAccount() {
  const L = useLanguage(["forms"]);
  const [displayMsg, setLoader] = useLoader(L("recover", {}));
  const setMessage = usePopup();
  const Router = useRouter();
  const {
    remove
  } = useModifySearchParams();
  const {
    turnstileRef,
    token: turnstileToken,
    turnstileError
  } = useTurnstile();
  const {
    register,
    handleSubmit
  } = useForm<FormValues>({
    defaultValues: {
      usernameOrEmail: ""
    }
  });
  const onSubmit = async (data: FormValues) => {
    setLoader(true);
    try {
      const res = await API.post("user/recover", {
        username: data.usernameOrEmail,
        secure_token: turnstileToken
      });
      if (res.data.success) {
        setMessage({
          code: "responses.su_recover_message",
          type: 1
        });
        Router.replace(Router.asPath.split("?")[0], undefined, {
          shallow: true
        });
      } else {
        setMessage({
          code: "responses." + res.data.error,
          type: 0
        });
      }
    } catch (error) {
      setMessage({
        code: "responses.er_network",
        type: 2
      });
    } finally {
      setLoader(false);
    }
  };
  return <div className="flex flex-col gap-5 justify-between h-full" data-sentry-component="RecoverAccount" data-sentry-source-file="recover.tsx">
      <form className="grid gap-[11px] relative" onSubmit={handleSubmit(onSubmit)}>
        <NewButton variant="dark" size="sm" className={cn("absolute right-0 top-0 rounded-full z-10 h-[32px] w-[32px] min-h-[32px]", "[@media(max-width:550px)]:right-[max(16px,env(safe-area-inset-right))]")} onClick={e => {
        e.preventDefault();
        remove("recover");
      }} data-sentry-element="NewButton" data-sentry-source-file="recover.tsx">
          <XIcon className="h-3 w-3" data-sentry-element="XIcon" data-sentry-source-file="recover.tsx" />
        </NewButton>

        <div className="grid gap-[10px]">
          <div className="flex justify-start gap-4 items-center mb-3">
            <NewLogoIcon height={25} width={25} data-sentry-element="NewLogoIcon" data-sentry-source-file="recover.tsx" />
            <h2 className="text-lg !font-medium">{L("recover", {})}</h2>
          </div>
        </div>

        <div className="grid gap-4 relative">
          <Dict name="recover_username" as="span" section="forms" className="text-[12px] font-medium text-input-label-color-light text-opacity-75 cursor-pointer" data-sentry-element="Dict" data-sentry-source-file="recover.tsx" />

          <div>
            <Input {...register("usernameOrEmail")} data-sentry-element="Input" data-sentry-source-file="recover.tsx" />
          </div>
        </div>

        <div className=" mb-[-10px]" ref={turnstileRef} />

        <div className="flex flex-col gap-4 pt-[14px] pb-4">
          <Button type="submit" className="border" data-sentry-element="Button" data-sentry-source-file="recover.tsx">
            {displayMsg}
          </Button>

          <div className="flex justify-end">
            <button onClick={e => {
            e.preventDefault();
            remove("recover");
          }} className="[&_span]:text-[#7179a5] text-[13px] hover:text-[#a1a9d5] hover:brightness-125">
              <Dict name="return_to_login" as="span" section="forms" data-sentry-element="Dict" data-sentry-source-file="recover.tsx" />
            </button>
          </div>
        </div>
      </form>
      <div>
        <AltSignIn data-sentry-element="AltSignIn" data-sentry-source-file="recover.tsx" />
        <div className="text-center text-[13px] text-[rgba(232,229,255,0.5)]">
          {L("remember_password", {})}
          <Link href={`?modal=auth&tab=login`} className="inline-block pl-[4px] hover:text-[rgba(232,229,255,0.8)]" data-sentry-element="Link" data-sentry-source-file="recover.tsx">
            {L("login", {})}
          </Link>
        </div>
      </div>
    </div>;
}