import Link from "next/link";
import Button from "../../items/Button";
import st from "../../styles/components/ModalGiftCards.module.scss";
import useLoader from "../../hooks/useLoader";
import VisaIcon from "../../assets/icons/PaymentMethods/VisaIcon";
import MasterCardIcon from "../../assets/icons/PaymentMethods/MasterCardIcon";
import AppleIcon from "../../assets/icons/PaymentMethods/AppleIcon";
import GooglePayIcon from "../../assets/icons/PaymentMethods/GooglePay";
import PurchaseIcon from "../../assets/icons/general/PurchaseIcon";
import useLanguage from "../../hooks/useLanguage";
import ArrowIconTwo from "../../assets/icons/general/ArrowIconTwo";
import { useEffect, useState } from "react";
import API from "../../lib/api/api";
import Image from "next/image";
import { useRouter } from "next/router";
import { removeUrlQueries } from "../../lib/tools/helpers";
import LogoIcon from "../../assets/icons/general/LogoIcon";
import Logo from "../../assets/icons/general/Logo";
import ValueIcons from "../../items/ValueIcons";
import alipay from "../../assets/Images/alipay.png";
import paysafecard from "../../assets/Images/paysafecard.png";
import trustly from "../../assets/Images/trustly.png";
import BlueStarIcon from "../../assets/icons/general/BlueStarIcon";
import RedeemGiftCard from "./RedeemGiftCard";
import ValueDisplay from "../../items/ValueDisplay";
import { NewLogo } from "@assets/icons/general/NewLogo";
export default function GiftCardModal() {
  const L = useLanguage(["GiftCardModals", "common"]);
  const [displayMsg] = useLoader(L("gift_card.purchase"));
  const [retailers, setRetailers] = useState([]);
  const [selectedGiftCard, setSelectedGiftCard] = useState(null);
  const router = useRouter();
  const handleCardClick = url => {
    setSelectedGiftCard(prevSelected => prevSelected === url ? null : url);
  };
  const handlePurchase = url => {
    window.open(url, "_blank"); // Opens URL in a new tab
  };
  useEffect(() => {
    API.get("/giftcards/list-retailers").then(res => {
      if (res.data.success) {
        setRetailers(res.data.results);
      }
    }).catch(err => console.log("err", err)).finally(() => {});
  }, []);
  const giftCards = retailers.flatMap(data => data["gift-cards"]);
  return <>
      <div className={st["gift-card-modal-container"]}>
        <Button text={L("go_back")} buttonType={"link"} classType={["modal-return", "icon-left", "square-icon-grey"]} icon={"return"} url={{
        query: {
          modal: "wallet",
          tab: "deposit"
        }
      }} data-sentry-element="Button" data-sentry-source-file="GiftCardModal.jsx" />
        <h2>{L("gift_card.title")}</h2>
        <p>{L("gift_card.desc")}</p>
        <div className={st["star-icon-one"]}>
          <BlueStarIcon data-sentry-element="BlueStarIcon" data-sentry-source-file="GiftCardModal.jsx" />
        </div>
        <div className={st["star-icon-two"]}>
          <BlueStarIcon width={44} height={44} data-sentry-element="BlueStarIcon" data-sentry-source-file="GiftCardModal.jsx" />
        </div>
        <div className={st["star-icon-three"]}>
          <BlueStarIcon width={40} height={40} data-sentry-element="BlueStarIcon" data-sentry-source-file="GiftCardModal.jsx" />
        </div>

        <div className={st["gift-card-payment-methods-container"]}>
          <VisaIcon data-sentry-element="VisaIcon" data-sentry-source-file="GiftCardModal.jsx" /> <MasterCardIcon data-sentry-element="MasterCardIcon" data-sentry-source-file="GiftCardModal.jsx" />
          <Image src={paysafecard} alt="pay safe card" style={{
          maxWidth: "100%",
          height: "auto"
        }} data-sentry-element="Image" data-sentry-source-file="GiftCardModal.jsx" />
          <Image src={trustly} alt="trustly" style={{
          maxWidth: "100%",
          height: "auto"
        }} data-sentry-element="Image" data-sentry-source-file="GiftCardModal.jsx" />
          <Image src={alipay} alt="alipay" style={{
          maxWidth: "100%",
          height: "auto"
        }} data-sentry-element="Image" data-sentry-source-file="GiftCardModal.jsx" />
        </div>
        <div className={st["gift-cards-container"]}>
          <div className={st["gift-cards"]}>
            {giftCards.length === 0 ? <p>{L("available_soon")}</p> : giftCards.map((item, i) => <>
                  <div key={item.price} className={` ${st["card"]} ${selectedGiftCard === item.url && st["selected"]}`} onClick={() => handlePurchase(item.url)}>
                    <div className={st["gift-card-content"]}>
                      <div className={st["gift-card-content-left"]}>
                        <NewLogo />
                        <span>Gift Card</span>
                      </div>
                      <div className={st["gift-card-content-right"]}>
                        <ValueDisplay amount={item.price} size="small" />
                      </div>
                    </div>
                  </div>
                </>)}
          </div>
        </div>

        {/* <Button
         buttonType="link"
         query={{ modal: "redeem-gift-card" }}
         text={L("gift_card.redeem_gift_card")}
         classType={["no-border", "no-padding", "gift-card-modals-btn"]}
         customInnerContent={
          <div className={st["gift-card-button-container"]}>
            {L("gift_card.redeem_gift_card")}
            <ArrowIconTwo />
          </div>
         }
         /> */}

        <RedeemGiftCard data-sentry-element="RedeemGiftCard" data-sentry-source-file="GiftCardModal.jsx" />
        <span className={st["gift-card-description-container"]}>
          {L("terms_service_desc")}{" "}
          <Link href={"/terms"} data-sentry-element="Link" data-sentry-source-file="GiftCardModal.jsx">{L("terms_service")}</Link>.
        </span>
      </div>
    </>;
}