import st from "./LoadingExternalLogin.module.scss";
import Image from "next/image";
import MetaMaskAnimation from "@assets/Images/metamask.gif";
import SteamIcon from "@assets/icons/general/SteamIcon";
import LoadingSpinner from "./LoadingSpinner";
export const LoadingExternalLogin = ({
  type
}) => {
  return <div data-loading={type} className={st["container"]} data-sentry-component="LoadingExternalLogin" data-sentry-source-file="LoadingExternalLogin.jsx">
      {type === "steam" && <div className={st["steam-animate"]}>
          <SteamIcon className={st["svg"]} />
        </div>}

      {type === "metamask" && <Image alt="Metamask loading" src={MetaMaskAnimation} height={100} width={100} />}

      {(type === "google" || type === "default") && <LoadingSpinner />}
    </div>;
};