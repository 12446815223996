import BackgroundImage from "@assets/Images/Wallet/crypto_banner_background.jpg";
import CryptoIcon from "@assets/Images/Wallet/cryptos_icon.png";
import Image from "next/image";
import st from "../../styles/components/Wallet.module.scss";
import Particles from "@assets/Images/Wallet/particles.gif";
import useLanguage from "@hooks/useLanguage";
import { NewLogo } from "@assets/icons/general/NewLogo";
export default function DefaultCryptoBanner() {
  const L = useLanguage(["Wallet"]);
  return <div className={st["default-crypto-banner"]} data-sentry-component="DefaultCryptoBanner" data-sentry-source-file="DefaultCryptoBanner.jsx">
      <div className={st["default-crypto-banner-content"]}>
        <div className={st["default-crypto-banner-text"]}>
          <div className={st["center-content"]}>
            <div className={st["crypto-logo"]}>
              <Image
            // layout="fill"
            objectFit="contain" src={CryptoIcon} quality={100} width={120} alt="Crypto Icon" data-sentry-element="Image" data-sentry-source-file="DefaultCryptoBanner.jsx" />
            </div>
            <div className={st["text-content"]}>
              <h1>{L("crypto")}</h1>
              <h3>
                {/* <span>Bank Transfer</span>, <span>Gift Cards</span> and
                 <span>many</span> <br /> <span>more</span> payment methods */}
                {L("banner_description")}
              </h3>
            </div>
            <div className={st["logo-icon"]}>
              <NewLogo data-sentry-element="NewLogo" data-sentry-source-file="DefaultCryptoBanner.jsx" />
            </div>
          </div>

          {/* <p>Buy, sell, and trade Bitcoin, Ethereum, and more</p> */}
        </div>

        <div className={st["particles-background"]}>
          <Image unoptimized src={Particles} alt="Particles" layout="fill" objectFit="cover" data-sentry-element="Image" data-sentry-source-file="DefaultCryptoBanner.jsx" />
        </div>

        <div className={st["default-crypto-banner-image"]}>
          <Image src={BackgroundImage} alt="Crypto Banner" layout="fill" objectFit="cover" data-sentry-element="Image" data-sentry-source-file="DefaultCryptoBanner.jsx" />
        </div>
      </div>
    </div>;
}