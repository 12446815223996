import st from "../../../styles/games/CaseBattles.module.scss";
import Card from "../Card";
import battlesCaseItemListAtom from "../../../recoil/battleCaseItemList/atom";
import { useRecoilState } from "recoil";

//Auth container,
export default function CaseBattlesSelect(props) {
  const [casesList, setCasesList] = useRecoilState(battlesCaseItemListAtom);
  //Switch between sections and create the menu
  console.log("CaseBattlesSelect", casesList);
  const setItemColor = itemColor => {
    switch (itemColor) {
      case "blue":
        return "uncommon";
      case "purple":
        return "mythical";
      case "red":
        return "ancient";
      case "yellow":
        return "immortal";
      default:
        "";
    }
  };
  return <div
  // ref={ref}
  className={`${st["case-item-list-modal-container"]} `} data-sentry-component="CaseBattlesSelect" data-sentry-source-file="index.jsx">
      <h3>{casesList?.case?.name}</h3>

      <div className={st["case-item-list"]}>
        {casesList?.caseItems?.map((item, index) => <Card key={`${item.id}`} icon={item.icon} value={item.value} baseValue={item?.baseValue} multiplierResult={item?.multiplierResult} chance={item.chance} name={item.name} defaultVisible={true} rarityClass={setItemColor(item.color)} />)}
      </div>
    </div>;
}