import Button from "../../items/Button";
import useLanguage from "../../hooks/useLanguage";
import MetaMaskIcon from "@assets/icons/general/MetaMaskIcon";
import SteamIcon from "@assets/icons/general/SteamIcon";
import { GoogleLogoModal } from "@assets/icons/general/GoogleLogo";
import st from "../../styles/components/Auth.module.scss";
import { useExternalAuth } from "@hooks/useExternalAuth";
export default function AltSignIn() {
  const L = useLanguage(["common", "auth", "forms"]);
  const {
    steam,
    metaMaskLogin,
    googleLogin
  } = useExternalAuth();
  return <div className={st["alt-signin"]} data-sentry-component="AltSignIn" data-sentry-source-file="alt-signin.tsx">
      <h3>{L("other_top", {})} </h3>
      <div className={st["btn-content"]}>
        <Button method={steam} classType={["meta-steam"]} icon={<SteamIcon />} data-sentry-element="Button" data-sentry-source-file="alt-signin.tsx" />
        <Button method={metaMaskLogin} classType={["meta-steam"]} icon={<MetaMaskIcon />} data-sentry-element="Button" data-sentry-source-file="alt-signin.tsx" />
        <Button method={googleLogin} classType={["meta-steam"]} icon={<GoogleLogoModal />} data-sentry-element="Button" data-sentry-source-file="alt-signin.tsx" />
      </div>
    </div>;
}