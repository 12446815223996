const validateWallet = {
  username: (value) => {
    let err = [];
    if (value === "") err.push("");
    if (value.length < 5) err.push("*Username must be at least 5 characters");
    return err;
  },
  password: (value) => {
    let err = [];
    if (value === "") err.push("");
    if (value.length < 5) err.push("*password must be at least 5 characters");
    return err;
  },
  email: (value) => {
    let err = [];
    if (value === "") err.push("");
    if (value.indexOf("@")) err.push("*No E-mail Host ");
    if (value.indexOf(".")) err.push("*Invalid E-mail");
    return err;
  },
};

const validateSteamSettings = {
  apiKey: (value) => {
    let err = [];
    if (value === "") err.push("");
    if (value.length < 5) err.push("*Username must be at least 5 characters");
    return err;
  },
  tradeUrl: (value) => {
    let err = [];
    if (value === "") err.push("");
    if (value.length < 5) err.push("*password must be at least 5 characters");
    return err;
  },
};

const validateKyc = {
  firstName: (value) => {
    let err = [];
    if (value === "") err.push("");
    // if(value.length < 5) err.push("*Username must be at least 5 characters");
    return err;
  },
  lastName: (value) => {
    let err = [];
    if (value === "") err.push("");
    // if(value.length < 5) err.push("*Username must be at least 5 characters");
    return err;
  },
  day: (value) => {
    let err = [];
    if (value === "") err.push("");
    // Add additional validation for day field here
    return err;
  },
  month: (value) => {
    let err = [];
    if (value === "") err.push("");
    // Add additional validation for month field here
    return err;
  },
  year: (value) => {
    let err = [];
    if (value === "") err.push("");
    // Add additional validation for year field here
    return err;
  },
  streetAddress: (value) => {
    let err = [];
    if (value === "") err.push("");
    // Add additional validation for street address field here
    return err;
  },
  city: (value) => {
    let err = [];
    if (value === "") err.push("");
    // Add additional validation for city field here
    return err;
  },
  state: (value) => {
    let err = [];
    if (value === "") err.push("");
    // Add additional validation for state field here
    return err;
  },
  country: (value) => {
    let err = [];
    if (value === "") err.push("");
    // Add additional validation for country field here
    return err;
  },
  postalCode: (value) => {
    let err = [];
    if (value === "") err.push("");
    // Add additional validation for postal code field here
    return err;
  },
};

const validatePixPic = {
  email: (value) => {
    let err = [];
    if (value === "" || value === undefined) err.push("");
    // Add additional validation for email field here
    return err;
  },
  phone: (value) => {
    let err = [];
    if (value === "" || value === undefined) err.push("");
    // Add additional validation for phone field here
    return err;
  },
  firstName: (value) => {
    let err = [];
    if (value === undefined || value === "") err.push(" ");
    // Add additional validation for firstName field here
    return err;
  },
  lastName: (value) => {
    let err = [];
    if (value === undefined || value === "") err.push("");
    // Add additional validation for lastName field here
    return err;
  },
  day: (value) => {
    let err = [];
    if (value === "" || value === undefined) err.push(" ");
    // Add additional validation for day field here
    return err;
  },
  month: (value) => {
    let err = [];
    if (value === "" || value === undefined) err.push("");
    // Add additional validation for month field here
    return err;
  },
  year: (value) => {
    let err = [];
    if (value === "" || value === undefined) err.push("");
    // Add additional validation for year field here
    return err;
  },
  cpf: (value) => {
    let err = [];
    if (value === "" || value === undefined) err.push("");
    // Add additional validation for cpf field here
    return err;
  },
  pixKey: (value) => {
    let err = [];
    if (value === "" || value === undefined) err.push("");
    // Add additional validation for cpf field here
    return err;
  },
};

const validateInterac = {
  email: (value) => {
    let err = [];
    if (value === "" || value === undefined) err.push("");
    // Add additional validation for email field here
    return err;
  },
  phone: (value) => {
    let err = [];
    if (value === "" || value === undefined) err.push("");
    // Add additional validation for phone field here
    return err;
  },
  firstName: (value) => {
    let err = [];
    if (value === undefined || value === "") err.push(" ");
    // Add additional validation for firstName field here
    return err;
  },
  lastName: (value) => {
    let err = [];
    if (value === undefined || value === "") err.push("");
    // Add additional validation for lastName field here
    return err;
  },
};

/**
 * Checks for errors in the submitted item using the provided validator.
 * @param {Object} validator - The validator object containing validation functions for each item property.
 * @param {Object} item - The item to be validated.
 * @returns {(Object|boolean)} - Returns an object containing the validation errors if any errors are found, otherwise returns false.
 */
const checkSubmitForErrors = (validator, item, ignore = false) => {
  let errorFound = false;
  let submitErrors = {};
  Object.keys(item).forEach((key) => {
    if (ignore && ignore[key]) {
      return; // Skip the rest of the loop
    }

    let validate = validator[key];
    if (typeof validate !== "undefined") {
      submitErrors[key] = validate(item[key]);
    }
    if (
      typeof submitErrors[key] !== "undefined" &&
      submitErrors[key].length !== 0
    ) {
      errorFound = true;
    }
  });
  if (errorFound) {
    return submitErrors;
  }
  return false;
};

export {
  validateWallet,
  validateKyc,
  checkSubmitForErrors,
  validateSteamSettings,
  validatePixPic,
  validateInterac,
};
