import { InputWithIcon } from "./input";
import useCurrencySymbol from "@hooks/use-currency-symbol";
import { cn } from "@lib/utils";
import { ComponentProps, forwardRef } from "react";
type CurrencyInputProps = ComponentProps<"input"> & {
  currency: string;
  empty?: boolean;
};
export default forwardRef<HTMLInputElement, CurrencyInputProps>(function CurrencyInput({
  empty,
  ...props
}, ref) {
  const symbol = useCurrencySymbol(props.currency);
  return <InputWithIcon ref={ref} {...props} icon={<span className={cn("text-[13px] leading-[14px] select-none text-primary-font-color", empty && "text-font-color-seven")}>
            {symbol}
          </span>} type="number" className={cn("text-white", empty && "text-font-color-seven placeholder:text-font-color-seven")} />;
});