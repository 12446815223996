import Button from "@items/Button";
import InputItem from "@items/InputItem";
import st from "@styles/components/ModalGiftCards.module.scss";
import useLoader from "@hooks/useLoader";
import useLanguage from "@hooks/useLanguage";
import { useState } from "react";
import API from "@lib/api/api";
import usePopup from "@hooks/usePopup";
import useAuth from "@hooks/useAuth";
import useWallet from "@hooks/useWallet";
import { useRouter } from "next/router";
import { useLocale } from "@lib/tools/helpers";
export default function RedeemGiftCard() {
  const [giftCardCode, setGiftCardCode] = useState("");
  const router = useRouter();
  const L = useLanguage(["GiftCardModals"]);
  const setMessage = usePopup();
  const {
    balanceMutate
  } = useWallet();
  const [deActiveButton, setDeActiveButton] = useState(false);
  useAuth();
  const [displayMsg, setLoader] = useLoader(L("redeem_gift_card.redeem_promotional_code"));
  const userLanguage = useLocale();
  const submitForm = () => {
    setLoader(true);
    setDeActiveButton(true);
    API.post("giftcards/redeem", {
      language: userLanguage,
      gift_card_code: giftCardCode
    }).then(res => {
      // console.log('resss',res);
      if (res.data.success) {
        setMessage({
          type: 1,
          code: "responses.su_gift_card_redeemed",
          replacements: {
            TOKEN_AMOUNT: res.data.results.amount,
            WAGER_LOCK: res.data.results.wager_lock
          }
        });
        balanceMutate();
        router.replace(router.asPath.split("?")[0], undefined, {
          shallow: true
        });
      } else {
        setDeActiveButton(false);
        setMessage({
          code: "responses." + res.data.error,
          type: 0
        });
      }
    }).catch(res => {
      setDeActiveButton(false);
      setMessage({
        code: "responses." + res.response.data.error,
        type: 0
      });
    }).finally(() => {
      setLoader(false);
    });
  };
  return <div className={st["redeem-gift-card-modal-container"]} data-sentry-component="RedeemGiftCard" data-sentry-source-file="RedeemGiftCard.jsx">
      {/* <h2>{L("redeem_gift_card.title")}</h2>
       <p>{L("redeem_gift_card.desc")}</p> */}
      <div className={st["redeem-gift-card-input-container"]}>
        <InputItem label={<div className={st["digit-code-container"]}>
              {L("redeem_gift_card.gift_card_code")}
              <span className={st["digit-code"]}>
                {L("redeem_gift_card.digit_code")}
              </span>
            </div>} value={giftCardCode} method={setGiftCardCode} classType={["modal-inputs"]} data-sentry-element="InputItem" data-sentry-source-file="RedeemGiftCard.jsx" />
      </div>

      <Button text={displayMsg} method={submitForm} classType={["full-width", "green", "has-border", "modal-button"]} deActive={giftCardCode.length < 16 || deActiveButton} data-sentry-element="Button" data-sentry-source-file="RedeemGiftCard.jsx" />

      {/* <Button
        buttonType="link"
        query={{ modal: "gift-card" }}
        text={L("gift_card.redeem_gift_card")}
        classType={["no-border", "no-padding", "gift-card-modals-btn"]}
        customInnerContent={
          <div className={st["redeem-gift-card-description-container"]}>
            {L("redeem_gift_card.buy_gift_card")}
            <ArrowIconTwo />
          </div>
        }
       /> */}
    </div>;
}