import { NewLogoIcon } from "@assets/icons/general/NewLogo";
import { Button, Button as NewButton } from "@components/new-ui/button";
import { XIcon } from "@components/new-ui/dialog";
import { Input } from "@components/new-ui/input";
import useLanguage, { Dict } from "@hooks/useLanguage";
import { useModifySearchParams } from "@hooks/useModifySearchParams";
import { useTurnstile } from "@hooks/useTurnstile";
import { NODE_API } from "@lib/api/api";
import createPopup from "@lib/create-popup";
import { handleCatchPopup, removeUrlQueries } from "@lib/tools/helpers";
import { cn } from "@lib/utils";
import { KeyboardEvent, PropsWithChildren, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
export type LoginForm = {
  username: string;
  password: string;
  twoFactorCode?: string;
  turnstileToken?: string;
};
type ResponseData = {
  tokens: {
    jwt: string;
    refresh_token: string;
  };
  error?: string;
};
export const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<ResponseData>(null);
  const [storedTurnstileToken, setStoredTurnstileToken] = useState<string | null>(null);
  const submit = async ({
    username,
    password,
    twoFactorCode: tfa,
    turnstileToken
  }: LoginForm) => {
    setIsLoading(true);

    // Store turnstile token on first attempt
    if (turnstileToken) {
      setStoredTurnstileToken(turnstileToken);
    }
    NODE_API.post<ResponseData>("v1/user/log-in", {
      username,
      password,
      ...(tfa && {
        code: tfa
      }),
      secure_token: storedTurnstileToken || turnstileToken
    }).then(r => r.data).then(data => {
      if (data?.error === "er_two_factor_missing") {
        setData(data);
        return data;
      }
      if (!data?.tokens) throw data.error;
      setError(null);
      setData(data);
      return data;
    }).catch(handleCatchPopup(createPopup)).finally(() => {
      setIsLoading(false);
    });
  };
  const isSecurityCheck = error === "er_security_check_required";
  const has2FA = data?.error === "er_two_factor_missing";
  return {
    error,
    isSecurityCheck,
    has2FA,
    submit,
    isLoading,
    data
  };
};
type LoginProps = PropsWithChildren<{
  form: UseFormReturn<LoginForm>;
  onSubmit: (data: LoginForm) => void;
  isLoading?: boolean;
}>;
export default function Login({
  form,
  onSubmit,
  isLoading
}: LoginProps) {
  const L = useLanguage(["forms"]);
  const {
    register,
    handleSubmit,
    watch
  } = form;
  const {
    add
  } = useModifySearchParams();
  const username = watch("username");
  const password = watch("password");
  const {
    turnstileRef,
    token: turnstileToken,
    turnstileError,
    refresh
  } = useTurnstile();
  const disabled = !username || !password || !turnstileToken;
  const handleFormSubmit = async (data: LoginForm) => {
    if (!turnstileToken) {
      refresh();
      return;
    }
    if (turnstileError) {
      refresh();
      return;
    }
    onSubmit({
      ...data,
      turnstileToken
    });
  };
  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter" && !disabled) {
      e.preventDefault();
      handleSubmit(handleFormSubmit)();
    }
  };

  // Add useEffect to handle turnstile error
  useEffect(() => {
    if (turnstileError) {
      refresh();
    }
  }, [turnstileError, refresh]);
  return <form className="grid gap-5 relative" onSubmit={handleSubmit(handleFormSubmit)} onKeyDown={handleKeyPress} data-sentry-component="Login" data-sentry-source-file="login.tsx">
      <NewButton variant="dark" size="sm" className={cn("absolute right-0 top-0 rounded-full z-10 h-[32px] w-[32px] min-h-[32px]", "[@media(max-width:550px)]:right-[max(16px,env(safe-area-inset-right))]")} type="button" onClick={e => {
      e.preventDefault();
      removeUrlQueries();
    }} data-sentry-element="NewButton" data-sentry-source-file="login.tsx">
        <XIcon className="h-3 w-3" data-sentry-element="XIcon" data-sentry-source-file="login.tsx" />
      </NewButton>

      <div className="grid gap-[10px]">
        <div className="flex justify-start gap-4 items-center">
          <NewLogoIcon height={25} width={25} data-sentry-element="NewLogoIcon" data-sentry-source-file="login.tsx" />
          <h2 className="text-lg !font-medium">{L("login", {})}</h2>
        </div>
      </div>
      <div className="grid gap-4">
        <div className="grid gap-2 relative">
          <label htmlFor="username-input">
            <Dict name="username" as="span" section="forms" className="text-[12px] font-medium text-input-label-color-light text-opacity-75 cursor-pointer" data-sentry-element="Dict" data-sentry-source-file="login.tsx" />
          </label>

          <div>
            <Input id="username-input" {...register("username", {
            required: true
          })} data-sentry-element="Input" data-sentry-source-file="login.tsx" />
          </div>
        </div>

        <div className="grid gap-2">
          <label htmlFor="password-input">
            <Dict name="password" as="span" section="forms" className="text-[12px] font-medium text-input-label-color-light text-opacity-75 cursor-pointer" data-sentry-element="Dict" data-sentry-source-file="login.tsx" />
          </label>

          <div>
            <Input id="password-input" type="password" {...register("password")} data-sentry-element="Input" data-sentry-source-file="login.tsx" />
          </div>
        </div>

        <div className="cf-turnstile w-full pt-[13px] mb-[-10px]" ref={turnstileRef} />

        <div className="flex flex-col gap-2 pt-[14px] pb-2">
          <Button className="border" type="submit" disabled={disabled} data-sentry-element="Button" data-sentry-source-file="login.tsx">
            {isLoading ? "Please wait..." : L("login", {})}
          </Button>
        </div>

        <div className="flex justify-end">
          <button type="button" onClick={() => {
          add("recover", "true");
        }} className="[&_span]:text-[#7179a5] text-[13px] hover:text-[#a1a9d5] hover:brightness-125">
            <Dict name="recover_account" as="span" section="forms" data-sentry-element="Dict" data-sentry-source-file="login.tsx" />
          </button>
        </div>
      </div>
    </form>;
}