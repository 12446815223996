import st from "../../styles/games/CaseBattles.module.scss";
import Button from "../../items/Button";
import Image from "next/image";
import PlusIcon from "../../assets/icons/general/plus-icon";
import MinusIcon from "../../assets/icons/general/MinusIcon";
import ValueDisplay from "../../items/ValueDisplay";
import useLanguage from "../../hooks/useLanguage";
import AlertIcon from "@assets/icons/general/AlertIcon";
import { useMemo, memo } from "react";
import ToolTip from "@items/ToolTip";
import useLongPress from "@hooks/useLongPress";
import useDoubleTap from "@hooks/useDoubleTap";
import ChangePositionIcon from "./assets/ChangePositionIcon";
function CaseItemSelect({
  item,
  setSelectedCases,
  selectedCases,
  casesRainList,
  activeRate,
  getConvertedAmount,
  dragItem,
  isDragging,
  dragHandleProps,
  onSwap,
  selectedForMove,
  setSelectedForMove
}) {
  const L = useLanguage("CaseBattle");
  const rainModeCaseWarning = useMemo(() => {
    return casesRainList && !casesRainList?.cases?.[item.id] && casesRainList?.activeRainMode;
  }, [casesRainList, casesRainList?.cases, casesRainList?.activeRainMode, item.id]);
  const handleDoubleTap = () => {
    if (!onSwap) return; // Exit if onSwap is not provided

    if (selectedForMove && selectedForMove.id === item.id) {
      // Deselect if the same item is double-tapped again
      setSelectedForMove(null);
    } else {
      // Select this item for moving
      setSelectedForMove(item);
    }
  };
  const handleLongPress = () => {
    if (!onSwap) return; // Exit if onSwap is not provided
    if (selectedForMove && selectedForMove.id !== item.id) {
      // Swap items
      onSwap(selectedForMove, item);
      setSelectedForMove(null);
    }
  };
  const doubleTapEvent = useDoubleTap(handleDoubleTap, 300);
  const longPressEvent = useLongPress(handleLongPress, 50);
  const handleClick = () => {
    if (selectedForMove && selectedForMove.id === item.id) {
      // Deselect if the same item is tapped again
      setSelectedForMove(null);
    }
  };
  return <button
  //   key={i}
  // method={() => {
  //   setSelectedCases((items) => {
  //     return [...items, item];
  //   });
  // }}
  className={`
           ${selectedCases?.[item.id] ? st["active-item"] : ""}
        ${st["case-select"]}
        ${selectedForMove && selectedForMove.id === item.id ? st["selected"] : ""}
         ${selectedForMove && selectedForMove.id !== item.id ? st["not-selected"] : ""}
      `} onClick={handleClick} {...doubleTapEvent} contentType={"cases"} data-sentry-component="CaseItemSelect" data-sentry-source-file="CaseItemSelect.jsx">
      {selectedForMove && selectedForMove.id !== item.id && <div className={st["change-position-overlay"]}>
          <div {...longPressEvent} className={st["change-position-icon-container"]}>
            <ChangePositionIcon />
          </div>
        </div>}

      {dragItem && <div {...dragHandleProps} className={st["case-item-select-container"]}></div>}
      <div className={st["case-item-alert"]}>
        {rainModeCaseWarning && <ToolTip text={L("cant_use_with_rain")}>
            <AlertIcon />
          </ToolTip>}
      </div>

      <div className={st["active-background"]}></div>
      <div className={st["case-item-display"]}>
        <div className={st["item-image"]}>
          <Image alt={item.name} src={item.icon} width={100} height={100} sizes="100vw" style={{
          width: "100%",
          height: "auto",
          objectFit: "contain"
        }} data-sentry-element="Image" data-sentry-source-file="CaseItemSelect.jsx" />
        </div>

        <h3 className={st["case-name"]}>{item.name}</h3>

        <div className={st["case-value-container"]}>
          <div className={st["inner-container"]}>
            {/* <div className={st["icon"]}>
              <ValueIcons wallet={"rain"} size={"small"} />
             </div>
             <span>{item.price}</span> */}

            <ValueDisplay raw amount={getConvertedAmount(item.price, {
            round: {
              direction: "roundHalfUp"
            }
          })} size={"small"} data-sentry-element="ValueDisplay" data-sentry-source-file="CaseItemSelect.jsx" />
          </div>

          <div className={st["gradient-item"]}></div>
        </div>

        <div className={`
          ${st["item-add-remove-btns"]}

        `}>
          {/* Only active when there no items */}
          {/* {selectedCases?.[item.id] ? ( */}
          <div className={st["amount-change"]}>
            <Button method={() => {
            setSelectedCases(items => {
              let newItems = {
                ...items
              };
              newItems[item.id] = {
                ...newItems[item.id],
                amount: newItems[item.id]?.amount - 1
              };
              if (newItems[item.id]?.amount == 0) {
                delete newItems[item.id];
              }
              return newItems;
            });
          }} icon={<MinusIcon />} classType={["square", "icon-case-btn", "add-case-battle-btn"]} data-sentry-element="Button" data-sentry-source-file="CaseItemSelect.jsx" />
            <div className={st["amount-of-cases"]}>
              {selectedCases?.[item.id]?.amount}
            </div>
            <Button method={() => {
            setSelectedCases(items => {
              let newItems = {
                ...items
              };
              newItems[item.id] = {
                ...newItems[item.id],
                amount: newItems[item.id].amount + 1
              };
              return newItems;
            });
          }} deActive={rainModeCaseWarning} icon={<PlusIcon />} classType={["square", "icon-case-btn", "add-case-battle-btn"]} data-sentry-element="Button" data-sentry-source-file="CaseItemSelect.jsx" />
          </div>
          {/* ) : ( */}
          <div className={st["add-case-item"]}>
            <Button text={L("case_item_add_case")} classType={["full-width", "add-case-battle-btn"]} deActive={rainModeCaseWarning} method={() => {
            setSelectedCases(items => {
              let newItem = {
                ...item,
                amount: 1
              };
              return {
                ...items,
                [item.id]: newItem
              };
            });
          }} data-sentry-element="Button" data-sentry-source-file="CaseItemSelect.jsx" />
          </div>
          {/* )} */}
        </div>
      </div>
    </button>;
}
export default memo(CaseItemSelect);