export default function VisaIconBackground() {
  return <svg width="78" height="38" viewBox="0 0 225 110" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="VisaIconBackground" data-sentry-source-file="VisaIconBackground.jsx">
      <rect width="225" height="110" rx="13" fill="#00579F" data-sentry-element="rect" data-sentry-source-file="VisaIconBackground.jsx" />
      <g clipPath="url(#clip0_0_1)" data-sentry-element="g" data-sentry-source-file="VisaIconBackground.jsx">
        <path d="M104.566 73.451H94.9258L100.955 36.666H110.595L104.566 73.451Z" fill="white" data-sentry-element="path" data-sentry-source-file="VisaIconBackground.jsx" />
        <path d="M139.511 37.5661C137.609 36.8218 134.593 36 130.864 36C121.345 36 114.641 41.0088 114.6 48.1698C114.521 53.4532 119.4 56.3877 123.049 58.1493C126.778 59.9495 128.046 61.1244 128.046 62.729C128.008 65.1933 125.032 66.3293 122.257 66.3293C118.408 66.3293 116.346 65.7436 113.212 64.3726L111.943 63.7848L110.594 72.0423C112.855 73.0586 117.02 73.9605 121.345 74C131.46 74 138.045 69.0688 138.123 61.4375C138.161 57.2501 135.585 54.0414 130.031 51.4195C126.659 49.7364 124.595 48.6015 124.595 46.8794C124.634 45.3138 126.341 43.7103 130.147 43.7103C133.281 43.6317 135.583 44.375 137.328 45.1188L138.2 45.5094L139.511 37.5661Z" fill="white" data-sentry-element="path" data-sentry-source-file="VisaIconBackground.jsx" />
        <path d="M152.325 60.4194C153.118 58.3062 156.173 50.1273 156.173 50.1273C156.133 50.2058 156.966 47.975 157.442 46.6055L158.115 49.7751C158.115 49.7751 159.941 58.5803 160.337 60.4194C158.831 60.4194 154.229 60.4194 152.325 60.4194ZM164.224 36.666H156.767C154.468 36.666 152.721 37.3307 151.729 39.7181L137.41 73.4505H147.525C147.525 73.4505 149.19 68.9104 149.548 67.9325C150.658 67.9325 160.497 67.9325 161.925 67.9325C162.202 69.224 163.075 73.4505 163.075 73.4505H172.001L164.224 36.666Z" fill="white" data-sentry-element="path" data-sentry-source-file="VisaIconBackground.jsx" />
        <path d="M86.8741 36.666L77.4335 61.7499L76.4019 56.6626C74.6566 50.7925 69.1827 44.4148 63.0742 41.2436L71.7214 73.412H81.9153L97.0675 36.666H86.8741Z" fill="white" data-sentry-element="path" data-sentry-source-file="VisaIconBackground.jsx" />
        <path d="M68.6682 36.666H53.1587L53 37.4093C65.0984 40.4619 73.111 47.82 76.403 56.6641L73.0314 39.7581C72.4763 37.4087 70.7705 36.7435 68.6682 36.666Z" fill="white" data-sentry-element="path" data-sentry-source-file="VisaIconBackground.jsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="VisaIconBackground.jsx">
        <clipPath id="clip0_0_1" data-sentry-element="clipPath" data-sentry-source-file="VisaIconBackground.jsx">
          <rect width="119" height="38" fill="white" transform="translate(53 36)" data-sentry-element="rect" data-sentry-source-file="VisaIconBackground.jsx" />
        </clipPath>
      </defs>
    </svg>;
}