import { Input } from "@components/new-ui/input";
import useLanguage from "@hooks/useLanguage";
import useLoader from "@hooks/useLoader";
import usePopup from "@hooks/usePopup";
import useUser from "@hooks/useUser";
import API from "@lib/api/api";
import assert from "@lib/tools/assert";
import { capitalize, handleCatchPopup, removeUrlQueries } from "@lib/tools/helpers";
import st from "@styles/components/TwoFactor.module.scss";
import Image from "next/image";
import QRCode from "qrcode";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import LoadingSpinner from "../Loading/LoadingSpinner";
import { ClickCopy } from "@components/click-copy";
import { Button } from "@components/new-ui/button";
import useToken from "@hooks/useToken";
const format = (user: string, secret: string) => `otpauth://totp/Rainbet (${user})?secret=${secret}&issuer=Rainbet`;
const useQRCode = (user: string) => {
  const token = useToken();
  const [data, setData] = useState<{
    base64: string;
    secret: string;
    formated: string;
  } | null>(null);
  useEffect(() => {
    setData(null);
    API.get("user/setup-two-factor", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(res => res?.data?.secret).then(secret => ({
      secret,
      formated: format(user, secret)
    })).then(({
      secret,
      formated
    }) => QRCode.toDataURL(formated, {
      margin: 2,
      width: 200
    }).then(base64 => ({
      base64,
      secret,
      formated
    }))).then(setData).catch(() => null);
  }, [token, user]);
  return data;
};
export default function Enable({
  onDone = () => {}
}) {
  const L = useLanguage(["forms", "TwoFactor"]) as any;
  const setMessage = usePopup();
  const [displayMsg, setLoader] = useLoader(L("complete_setup"));
  const {
    register,
    handleSubmit,
    reset
  } = useForm();
  const {
    data: userData,
    mutate
  } = useUser();
  const data = useQRCode(userData?.username);
  const {
    base64,
    secret
  } = data || {};
  useEffect(() => reset, [reset]);
  const submitForm = useCallback(({
    code
  }: {
    code: string;
  }) => {
    setLoader(true);
    API.post("user/setup-two-factor", {
      code
    }).then(async res => {
      assert(res?.data?.success, res.data);
      setMessage({
        code: "responses.set_2fa",
        type: 1
      });
      await mutate();
      onDone();
    }).catch(handleCatchPopup(setMessage)).finally(() => {
      setLoader(false);
      removeUrlQueries();
    });
  }, [mutate, onDone, setLoader, setMessage]);
  const loading = !base64 || !secret;
  return <div className={st.container} data-sentry-component="Enable" data-sentry-source-file="enable.tsx">
      <h2>{L("two_factor")}</h2>
      <div className={st["qr-container"]}>
        <div className={st["desc"]}>
          <h3>{L("setup_instructions")}</h3>
          <p>{L("scan_qr_code")}</p>
          <p>{L("if_not_able_scan")}</p>

          {userData?.auth?.type !== "rainbet" && <p>
              {`${L("as_a")} ${capitalize(userData?.auth?.type)} ${L("actions_besides_login")}`}
            </p>}
        </div>
        <div className={st["image-wrapper"]}>
          {loading ? <LoadingSpinner type={"twoFactor"} /> : <Image src={base64} alt={"qrCode"} fill style={{
          width: "100%"
        }} />}
        </div>
      </div>
      <form id="enable-2fa-form" className="flex flex-col gap-2 mt-4" onSubmit={handleSubmit(submitForm)}>
        <div className="relative flex flex-col gap-2">
          <label htmlFor="2fa-secret">
            <span className="text-[12px] font-medium text-input-label-color-light text-opacity-75 cursor-pointer">
              {L("secret_key")}
            </span>
          </label>

          <Input id="2fa-secret" value={loading ? "" : secret} disabled data-sentry-element="Input" data-sentry-source-file="enable.tsx" />

          <ClickCopy value={secret || "error"} className="absolute right-4 bottom-4" data-sentry-element="ClickCopy" data-sentry-source-file="enable.tsx" />
        </div>
        {/*
         <span className="text-xs text-font-color-two opacity-50">
          {formated}
         </span> */}

        <hr className="my-3 opacity-15" />

        <label htmlFor="2fa-input">
          <span className="text-[12px] font-medium text-input-label-color-light text-opacity-75 cursor-pointer">
            {L("enter_six_digit_app_code")}
          </span>
        </label>

        <Input id="2fa-input" {...register("code", {
        required: true
      })} data-sentry-element="Input" data-sentry-source-file="enable.tsx" />

        <Button type="submit" className="w-full mt-4" onClick={handleSubmit(submitForm)} variant="green" data-sentry-element="Button" data-sentry-source-file="enable.tsx">
          {displayMsg}
        </Button>
      </form>
    </div>;
}