import React from "react";
import useLanguage, { useDictionary } from "@hooks/useLanguage";
import { useUserDetails, useUserSession } from "@hooks/useUserSession";
import RewardIcons from "@items/RewardIcons";
import { useLocale } from "@lib/tools/helpers";
import { SvgUnranked } from "@components/Svg/SvgStore";
import st from "@styles/components/ProfileProgressBar.module.scss";
import { useRankProgress } from "@hooks/useRankProgress";
import ValueDisplay from "@items/ValueDisplay";
import romanize from "@lib/tools/romanize";
export function ProfileProgressBar() {
  const L = useLanguage(["pageContent", "common", "UserDisplay"]) as any;
  const {
    userData
  } = useUserSession();
  const {
    nextRank,
    userRank,
    progress
  } = useRankProgress();
  const locale = useLocale();
  const {
    data: userDetails
  } = useUserDetails();
  const remainAmount = nextRank?.threshold;
  const {
    member_since
  } = useDictionary("settings", "member_since");
  const memberSince = new Date(userDetails?.registered_time).toLocaleString(locale, {
    month: "short",
    day: "numeric",
    year: "numeric"
  });
  return <div className={st["profile-banner-container"]} data-sentry-component="ProfileProgressBar" data-sentry-source-file="ProfileProgressBar.tsx">
      <div className={st["reward-container"]}>
        {userRank?.name && userRank?.name !== "unranked" ? <RewardIcons code={userRank?.name} size="x5large" /> : <SvgUnranked width="60px" />}
      </div>
      <div className={st["profile-container"]}>
        <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        width: "100%"
      }}>
          <div className={st["text-container"]}>
            <div className={st["profile-text-container"]}>
              <span className={st["profile-user-name"]}>
                {userData?.username}
              </span>
              <span className={st["profile-text"]}>
                {member_since}: {memberSince}
              </span>
            </div>

            {nextRank?.name || !userRank?.name ? <span className={st["wagered-text"]}>
                <ValueDisplay amount={remainAmount} size="small" textSize={14} classType="custom-value-display" raw={true} />
                <span style={{
              color: "rgba(232, 229, 255, 0.70)",
              fontWeight: 500
            }}>
                  {L("homeBanner.rank_banner.remaining")}
                </span>
              </span> : null}
          </div>

          {nextRank?.name && <div className={st["progress-bar-container"]}>
              <div className={st["progress-bar"]}>
                <div className={st["bar"]} style={{
              width: `${progress}%`
            }} />
              </div>
            </div>}

          <div className={st["user-profile-container"]}>
            <div style={{
            alignItems: "center",
            display: "flex",
            gap: "5px"
          }}>
              <span className={st["user-rank"]}>
                {userRank?.name ? `${userRank.name} ${romanize(userRank.level)}` : L("homeBanner.rank_banner.unrank")}
              </span>
            </div>

            <span className={st["profile-text"]} style={{
            flexWrap: "wrap"
          }}>
              <div style={{
              alignItems: "center",
              display: "flex"
            }}>
                {userData?.username && !nextRank?.name && <p className={st["next-rank-label"]}>
                    {L("homeBanner.rank_banner.next_rank")}:
                  </p>}
                {userRank?.name ? nextRank?.name ? <RewardIcons code={nextRank?.name} size="xlarge" /> : null : <RewardIcons code="bronze" size="xlarge" />}

                <span className={st["user-rank"]} style={{
                marginLeft: "5px"
              }}>
                  {nextRank?.name ? `${nextRank?.name} ${romanize(nextRank?.level)}` : !userRank?.name ? `${L("homeBanner.rank_banner.rank")} 1` : null}
                </span>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>;
}