import { useEffect, useReducer, useState } from "react";
import st from "../../styles/components/FairPlay.module.scss";
import { Col } from "../../items/Layout";
import InputItem from "../../items/InputItem";
import Button from "../../items/Button";
import useLanguage from "../../hooks/useLanguage";
import API from "../../lib/api/api";
import useLoader from "../../hooks/useLoader";
import ModalSpinner from "../../items/ModalSpinner";
import usePopup from "../../hooks/usePopup";
const initState = {
  clientSeed: "",
  errors: {}
};
export default function Seeds(props) {
  const L = useLanguage(["forms", "FairPlay"]);
  // const [state, dispatch] = useReducer(Reducer, initState);
  const [currentSeed, setCurrentSeed] = useState(false);
  const [clientSeed, setClientSeed] = useState("");
  const [displayMsg, setLoader] = useLoader(L("rotate_seed"));
  const setMessage = usePopup();
  useEffect(() => {
    getSeedValue();
    generateRandomString();
    // setClientSeed(generateRandomString());
  }, []);
  const getSeedValue = () => {
    API.get("user/seeds").then(res => {
      if (res.data.success) {
        setCurrentSeed(res.data.seeds);
      }
    }).catch(err => {
      console.log("Get Seed Error", err);
    }).finally(() => {
      console.log("Get Seed");
    });
  };
  const generateRandomString = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 16; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setClientSeed(result);
  };
  const onSubmit = () => {
    setLoader(true);
    const data = {
      client_seed: clientSeed
    };
    API.post("user/set-seed", data).then(res => {
      if (res?.data?.success) {
        setMessage({
          code: "responses.su_client_seed_updated",
          type: 1
        });
        getSeedValue();
        generateRandomString();
      } else {
        setMessage({
          code: "responses." + res.data.error,
          type: 0
        });
      }
      return;
    }).catch(() => {
      setMessage({
        code: "responses.er_network",
        type: 2
      });
    }).finally(() => {
      setLoader(false);
    });
  };
  return <>
      <ModalSpinner hide={currentSeed} data-sentry-element="ModalSpinner" data-sentry-source-file="Seeds.jsx" />
      <div className={st["container"]}>
        <form className={st["form-container"]}>
          <Col data-sentry-element="Col" data-sentry-source-file="Seeds.jsx">
            <h3>{L("seeds")}</h3>
            <InputItem value={currentSeed?.client_seed} copy={true} classType={["mid-height", "modal-inputs", "no-border"]} label={L("active_client_seed")} data-sentry-element="InputItem" data-sentry-source-file="Seeds.jsx" />
            <InputItem value={currentSeed?.hashed_server_seed} copy={true} classType={["mid-height", "modal-inputs", "no-border"]} label={L("active_hashed_server_seed")} data-sentry-element="InputItem" data-sentry-source-file="Seeds.jsx" />

            <InputItem value={currentSeed?.nonce} copy={true} classType={["mid-height", "modal-inputs", "no-border"]} label={L("current_nonce")} data-sentry-element="InputItem" data-sentry-source-file="Seeds.jsx" />
          </Col>
          <div className={st["rotation-section"]}>
            <Col data-sentry-element="Col" data-sentry-source-file="Seeds.jsx">
              <h3>{L("rotate_seed_pair")}</h3>
              <InputItem name={"clientSeed"} label={L("enter_new_client_phrase")} value={clientSeed} type={"text"} classType={["mid-height", "modal-inputs", "no-border"]} method={setClientSeed} inlineButtons={<>
                    <Button text={displayMsg} classType={["green", "mid", "inline-settings"]} method={onSubmit} />
                  </>} data-sentry-element="InputItem" data-sentry-source-file="Seeds.jsx" />
              <InputItem value={currentSeed?.hashed_next_server_seed} copy={true} classType={["mid-height", "modal-inputs", "no-border"]} label={L("next_server_hash")} data-sentry-element="InputItem" data-sentry-source-file="Seeds.jsx" />
            </Col>
          </div>
        </form>
      </div>
    </>;
}