import Image from "next/image";
import st from "../../styles/games/CaseBattles.module.scss";
import { useState, useEffect } from "react";
import ValueDisplay from "../../items/ValueDisplay";
import ChestMultiIcon from "@assets/icons/case-battles/ChestMultiIcon";

/**
 * Card component for displaying game items.
 *
 * @param {Object} props - The props for the Card component.
 * @param {string} props.icon - The image URL for the game item.
 * @param {string} props.name - The name of the game item.
 * @param {number} props.value - The value of the game item.
 * @param {number} props.chance - The chance of getting the game item.
 * @param {string} props.rarityClass - The CSS class related to the rarity of the game item.
 */
export default function Card({
  icon,
  name,
  value,
  chance,
  rarityClass,
  showTime,
  baseValue,
  multiplierResult,
  defaultVisible = false,
  addFadeMethod,
  savedActiveRates,
}) {
  const [isVisible, setIsVisible] = useState(defaultVisible);

  console.log("Saved Active Rates", savedActiveRates);

  useEffect(() => {
    if (!showTime) {
      return;
    }

    const currentTime = Date.now();
    const delay = showTime - currentTime;
    // console.log("Delay", showTime, delay);
    // console.log(delay);
    if (delay > 0) {
      setIsVisible(false);
      const timeoutId = setTimeout(() => {
        setIsVisible(true);
        if (addFadeMethod) {
          addFadeMethod();
        }
      }, delay);

      return () => clearTimeout(timeoutId);
    } else {
      // If the showTime is already passed, show the card immediately
      if (addFadeMethod) {
        addFadeMethod();
      }
      setIsVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTime, defaultVisible]);

  /**
   * Function to format numbers to a maximum of three decimal places.
   *
   * @param {number} num - The number to format.
   * @returns {string} The formatted number as a string.
   */
  // function formatNumber(num) {
  //   let formatted = num.toFixed(3);
  //   formatted = formatted.replace(/\.?0*$/, "");
  //   return formatted;
  // }

  function formatNumberWithCommas(input) {
    if (!input) {
      return "0";
    }
    const parts = input.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (parts[1]) {
      const decimalDigits = parts[1].replace(/0+$/, "");
      if (decimalDigits.length > 0) {
        return parts[0] + "." + decimalDigits;
      } else {
        return parts[0];
      }
    } else {
      return parts[0];
    }
  }

  const setBaseValue = (mulitValue, baseValue, finalItemValue) => {
    let value = parseFloat(mulitValue);
    return (
      <div
        className={` ${st["base-new-amount"]} 
      
      ${value <= 1 ? st["negative-multi"] : ""}
      `}
      >
        <div
          className={`
          ${st["multi-amount"]} 
          `}
        >
          {" "}
          <ChestMultiIcon /> <span> {multiplierResult}x</span>
        </div>

        <div className={st["multi-price"]}>
          <ValueDisplay
            iconOnly={true}
            amount={baseValue}
            customFiatRate={savedActiveRates}
            size={"small"}
          />

          {value != 1 && (
            <span
              className={` 
              ${st["base-amount"]} 
            `}
            >
              <span className={st["cross-out-line"]}></span>
              <ValueDisplay amount={baseValue} icon={false} />
            </span>
          )}

          <span className={st["new-value"]}>
            {" "}
            <ValueDisplay
              amount={finalItemValue}
              customFiatRate={savedActiveRates}
              icon={false}
            />
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`
      ${st["card-item-display"]} ${st[rarityClass]}
      ${isVisible ? st["visible"] : "hidden"}
      

    `}
    >
      <div className={st["left-side-details"]}>
        <div className={st["item-image"]}>
          <Image
            // Localized alt text
            alt={name}
            src={icon}
            loading="lazy"
            width={100}
            height={100}
            style={{
              maxWidth: "100%",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </div>

        <div className={st["details"]}>
          <div className={st["left-details"]}>
            {/* <ToolTip placement={"top"} size={"small"} text={name}> */}
            <p className={st["item-name"]}>{name}</p>
            {/* </ToolTip> */}
          </div>

          <div className={st["value"]}>
            <p className={st["chance"]}>{formatNumberWithCommas(chance)}%</p>
          </div>
        </div>
      </div>

      <div className={st["right-side-value"]}>
        {baseValue ? (
          <div className={st["card-item-multi-display"]}>
            {setBaseValue(multiplierResult, baseValue, value)}
          </div>
        ) : (
          <div className={st["icon"]}>
            <ValueDisplay
              amount={value}
              customFiatRate={savedActiveRates}
              size={"mid"}
            />
          </div>
        )}
      </div>
    </div>
  );
}
