export const extraCryptoDetails = {
  XRP: {
    extraParameter: "Memo",
    qrCode: (address) => {
      return `${address}`;
    },
  },
};

export const keysTranslate = {
  tag: "Memo/Tag (Optional)",
  ERC20: "ERC-20 (Ethereum)",
  TRC20: "TRC-20 (TRON)",
  ETH: "Ethereum",
  BTC: "Bitcoin",
  DOGE: "Dogecoin",
  XRP: "Ripple",
  HBAR: "Hedera Hashgraph",
  ADA: "Cardano",
  SOL: "Solana",
  LTC: "Litecoin",
  BNB: "BNB Smart Chain",
};
