import React, { useCallback, useEffect, useState, useId } from "react";
import useLanguage from "../../hooks/useLanguage";
import useWallet from "@hooks/useWallet";
import CaseItemSelect from "./CaseItemSelect";
import useConvert from "@hooks/useConvert";
export const CaseRow = React.memo(({
  itemName,
  rows,
  st,
  specialCases,
  style,
  searchTerm,
  sortBy,
  setSelectedCases,
  selectedCases,
  rainLimitedCases
}) => {
  const L = useLanguage(["common"]);
  const [filteredRows, setFilteredRows] = useState([]);
  const masterList = Array.isArray(rows) ? [].concat(...rows) : [];
  const id = useId();
  const {
    getConvertedAmount
  } = useConvert();
  const handleFilterSort = useCallback(() => {
    let items = [...masterList];
    if (searchTerm) {
      items = items.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    items = items.sort((a, b) => {
      if (sortBy == "high-to-low") {
        return b.price - a.price;
      } else if (sortBy == "low-to-high") {
        return a.price - b.price;
      } else {
        return 0;
      }
    });
    setFilteredRows(items);
  }, [masterList]);
  useEffect(() => {
    handleFilterSort();
  }, [searchTerm, sortBy]);
  return <div key={itemName + id} className={st["cases-chest-display"]} style={filteredRows.length === 0 ? {
    display: "none"
  } : {}}>
        <h2>{itemName}</h2>
        <div className={`${st["case-row"]} `}>
          {filteredRows.map((item, i) => {
        return <CaseItemSelect key={i + item.name} item={item} setSelectedCases={setSelectedCases} selectedCases={selectedCases} casesRainList={rainLimitedCases} L={L} getConvertedAmount={getConvertedAmount} />;
      })}
        </div>
      </div>;
});
CaseRow.displayName = "CaseRow";