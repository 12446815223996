import st from "../../styles/components/Blocker.module.scss";
import { useRouter } from "next/router";
import { useEffect } from "react";
import Logo from "../../assets/icons/general/Logo";
import Button from "../../items/Button";
import useLanguage from "../../hooks/useLanguage";
export default function Blocker(props) {
  let router = useRouter();
  const L = useLanguage("Blocker");
  return <div className={st["container"]} data-sentry-component="Blocker" data-sentry-source-file="index.jsx">
      <div className={st["top-content"]}>
        <div className={st["logo-container"]}>
          <Logo data-sentry-element="Logo" data-sentry-source-file="index.jsx" />
        </div>
        <h1>
          {router.query.type === "block" ? <>{L("blockTitle")}</> : <>{L("restrictedTitle")}</>}
        </h1>
        <p>
          {router.query.type === "block" ? <>{L("blockMessage")}</> : <>{L("restrictedMessage")}</>}
        </p>

        {router.query.type === "restricted" ? <Button text={L("continueButton")} classType={["green", "full-width"]} method={() => {
        router.replace(router.asPath.split("?")[0], undefined, {
          shallow: true
        });
      }} /> : null}
      </div>
    </div>;
}