import LinkIcon from "@assets/icons/general/LinkIcon";
import { NewLogo } from "@assets/icons/general/NewLogo";
import { ClickCopy } from "@components/click-copy";
import useLanguage from "@hooks/useLanguage";
import usePopup from "@hooks/usePopup";
import Button from "@items/Button";
import ModalSpinner from "@items/ModalSpinner";
import { formatDateAndTime, removeUrlQueries, useLocale } from "@lib/tools/helpers";
import st from "@styles/components/BetResult.module.scss";
import { useSearchParams } from "next/navigation";
import { useMemo } from "react";
import FairCheck from "./FairCheck";
import Result, { GameResult, useBetDetails } from "./Result";
function renderSwitch(param: string, details: GameResult["result"][0]) {
  switch (param) {
    case "result":
      return <Result betDetails={details} />;
    case "fair":
      return <FairCheck betDetails={details} />;
    default:
      removeUrlQueries();
      return null;
  }
}
function NextArrow() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none" data-sentry-element="svg" data-sentry-component="NextArrow" data-sentry-source-file="index.tsx">
      <path d="M1 5.45703C0.585786 5.45703 0.25 5.79282 0.25 6.20703C0.25 6.62124 0.585786 6.95703 1 6.95703V5.45703ZM14.5303 6.73736C14.8232 6.44447 14.8232 5.96959 14.5303 5.6767L9.75736 0.90373C9.46447 0.610837 8.98959 0.610837 8.6967 0.90373C8.40381 1.19662 8.40381 1.6715 8.6967 1.96439L12.9393 6.20703L8.6967 10.4497C8.40381 10.7426 8.40381 11.2174 8.6967 11.5103C8.98959 11.8032 9.46447 11.8032 9.75736 11.5103L14.5303 6.73736ZM1 6.95703H14V5.45703H1V6.95703Z" fill="#E8E5FF" fillOpacity="0.5" data-sentry-element="path" data-sentry-source-file="index.tsx" />
    </svg>;
}
export default function BetResult() {
  const L = useLanguage(["BetResult", "common"]) as any;
  const setMessage = usePopup();
  const locale = useLocale();
  const params = useSearchParams();
  const betId = params.get("betId");
  const tab = params.get("tab");
  const modal = params.get("modal");
  const {
    data: betInfo
  } = useBetDetails(betId);
  const dateTime = useMemo(() => formatDateAndTime(betInfo?.time * 1000, locale), [locale, betInfo?.time]);
  const user = betInfo?.user?.name ? <Button buttonType="link" query={{
    modal: "user",
    user: betInfo.user.public_id
  }} active={false} text={betInfo.user.name} classType={["inline-btn-display"]} /> : L("hidden");
  return <div className={st["container"]} data-sentry-component="BetResult" data-sentry-source-file="index.tsx">
      <ModalSpinner hide={!!betInfo} data-sentry-element="ModalSpinner" data-sentry-source-file="index.tsx" />

      <h2>{L("bet")}</h2>
      <div className={st["user-display"]}>
        <div className={st["left"]}>
          <img alt={betInfo?.game?.name || ""} src={betInfo?.game?.banner || ""} style={{
          width: "100%",
          borderRadius: "6px",
          objectFit: "cover",
          height: "auto"
        }} />
        </div>
        <div className={st["right"]}>
          <div className={st["game-data"]}>
            <h2 className={st["game-name"]}>{betInfo?.game?.name}</h2>
            <p className={st["game-type"]}>{betInfo?.game?.provider}</p>
          </div>
          <div className={st["user-data"]}>
            <div className="flex items-center justify-between gap-4">
              <div className="flex items-center gap-2">
                <p className="leading-6">{L("betId")}: </p>
                <span className="truncate max-w-[150px] leading-6 text-[14px]">
                  {betId ?? ""}
                </span>
              </div>
              <div className="flex items-center shrink-0 gap-2">
                <ClickCopy value={String(betId)} data-sentry-element="ClickCopy" data-sentry-source-file="index.tsx" />
                <LinkIcon style={{
                cursor: "pointer"
              }} onClick={() => {
                navigator.clipboard.writeText(window.location.href).then(() => {
                  setMessage({
                    code: "responses.copied_to_clipboard",
                    type: 2
                  }, 1500);
                });
              }} data-sentry-element="LinkIcon" data-sentry-source-file="index.tsx" />
              </div>
            </div>
            <div className={st["user-name"]}>
              <p>{L("placedBy")} </p>
              <span>{user} </span>
              <p>{L("on")} </p>
              <span>{dateTime?.localizedDate} </span>
              <p>{L("at")} </p>
              <span>{dateTime?.localizedTime} </span>
            </div>
          </div>
        </div>
      </div>
      <div className={st["logo-container"]}>
        <hr />
        <div style={{
        opacity: 0.2
      }}>
          <NewLogo data-sentry-element="NewLogo" data-sentry-source-file="index.tsx" />
        </div>
        <hr />
      </div>
      {modal === "bet" && renderSwitch(tab, betInfo)}
      <div className={st["footer"]}>
        {!!betInfo?.complete && (tab !== "result" ? <>
              {" "}
              <Button buttonType="link" query={{
          modal: "bet",
          tab: "result",
          betId: betInfo?.id
        }} active={false} extraContent={<div className="flex gap-1 items-center">
                    <p>{L("betResult")}</p>
                    <NextArrow />
                  </div>} classType={["inline-btn-display"]} />
            </> : <>
              {/* Conditional Fair Play Button */}
              {betInfo?.game?.url?.includes("originals") && <>
                  <hr style={{
            marginTop: "20px",
            marginBottom: "0"
          }} />
                  <Button buttonType="link" query={{
            modal: "bet",
            tab: "fair",
            betId: betInfo?.id
          }} active={false} extraContent={<div className="flex items-center gap-1">
                        <p>{L("provablyFair")}</p>
                        <NextArrow />
                      </div>} classType={["inline-btn-display"]} />
                </>}
            </>)}
      </div>
    </div>;
}