import Particles from "@assets/Images/Wallet/particles.gif";
import BackgroundImage from "@assets/Images/Wallet/crypto_banner_background.jpg";
import AlipayIconBackground from "@assets/icons/PaymentMethods/AlipayIconBackground";
import AppleIconWhite from "@assets/icons/PaymentMethods/AppleIconWhite";
import GooglePayBlack from "@assets/icons/PaymentMethods/GooglePayBlack";
import KinguinIcon from "@assets/icons/PaymentMethods/KinguinIcon";
import MasterCardIcon from "@assets/icons/PaymentMethods/MasterCardIcon";
import Moonpay from "@assets/icons/PaymentMethods/Moonpay";
import NeopayIconBackground from "@assets/icons/PaymentMethods/NeopayIconBackground";
import PaysafeIconBackground from "@assets/icons/PaymentMethods/PaysafeIconBackground";
import SkrillIconBackground from "@assets/icons/PaymentMethods/SkrillIconBackground";
import TrustlyIconBackground from "@assets/icons/PaymentMethods/TrustlyIconBackground";
import VisaIconBackground from "@assets/icons/PaymentMethods/VisaIconBackground";
import VisaIconWhite from "@assets/icons/PaymentMethods/VisaIconWhite";
import useLanguage, { useDict } from "@hooks/useLanguage";
import st from "@styles/components/Wallet.module.scss";
import Image from "next/image";
export default function DepositButtons({
  crypto
}) {
  const L = useLanguage(["Wallet"]);
  const Dict = useDict(["Wallet"]);
  return crypto ? <div className={st["deposit-buttons-container"]} data-sentry-component="DepositButtons" data-sentry-source-file="DepositButtons.jsx">
      <div className={st["deposit-buttons-left-container"]}>
        <div>
          <Dict name="no_crypto_problem" className={st["deposit-buttons-upper-text"]} data-sentry-element="Dict" data-sentry-source-file="DepositButtons.jsx" />

          <Dict name="buy_cryptocurrency" className={st["deposit-buttons-title-text"]} data-sentry-element="Dict" data-sentry-source-file="DepositButtons.jsx" />
        </div>
        <div className={st["deposit-buttons-powered-by"]}>
          <span>{L("powered_by")}</span>
          <Moonpay data-sentry-element="Moonpay" data-sentry-source-file="DepositButtons.jsx" />
        </div>
      </div>
      <div className={st["deposit-buttons-crypto-image-container"]}>
        <div className={st["deposit-buttons-crypto-logos-container"]}>
          <div className={st["visa-icon"]}>
            <VisaIconWhite data-sentry-element="VisaIconWhite" data-sentry-source-file="DepositButtons.jsx" />
          </div>
          <div className={st["master-icon"]}>
            <MasterCardIcon data-sentry-element="MasterCardIcon" data-sentry-source-file="DepositButtons.jsx" />
          </div>
          <div className={st["google-icon"]}>
            <GooglePayBlack width={61} data-sentry-element="GooglePayBlack" data-sentry-source-file="DepositButtons.jsx" />
          </div>
          <div className={st["apple-icon"]}>
            <AppleIconWhite data-sentry-element="AppleIconWhite" data-sentry-source-file="DepositButtons.jsx" />
          </div>
        </div>
      </div>
    </div> : <div className={st["deposit-buttons-container"]} data-sentry-component="DepositButtons" data-sentry-source-file="DepositButtons.jsx">
      {/* <div className={st["deposit-buttons-linear-background"]}></div> */}
      <div className={st["deposit-buttons-left-container"]} data-gift>
        <div>
          <p className={st["deposit-buttons-upper-text"]}>
            {L("purchase_redeem")}
          </p>
          <p className={st["deposit-buttons-title-text"]}>
            {L("rainbet_gift_cards")}
          </p>
        </div>
        <div className={st["deposit-buttons-powered-by"]}>
          <span>{L("powered_by")}</span>
          <KinguinIcon data-sentry-element="KinguinIcon" data-sentry-source-file="DepositButtons.jsx" />
        </div>
      </div>

      {/* <div className={st["promo-code"]}>
        <Dict name="use_code" as="div" />
        <div>
          <span>{`"RAINBETBLAST"`} </span>
           <Dict name="for_discount" as="span" />
        </div>
       </div> */}

      <div className={st["deposit-buttons-crypto-image-gift-container"]} style={{
      opacity: 0.333
    }}>
        <div className={st["gift-image-line"]}>
          <NeopayIconBackground data-sentry-element="NeopayIconBackground" data-sentry-source-file="DepositButtons.jsx" />
          <AlipayIconBackground data-sentry-element="AlipayIconBackground" data-sentry-source-file="DepositButtons.jsx" />
        </div>
        <div className={st["gift-image-line"]}>
          <VisaIconBackground data-sentry-element="VisaIconBackground" data-sentry-source-file="DepositButtons.jsx" />
          <div className={st["master-icon"]}>
            <MasterCardIcon data-sentry-element="MasterCardIcon" data-sentry-source-file="DepositButtons.jsx" />
          </div>

          <div className={st["paysafe-icon"]}>
            <PaysafeIconBackground data-sentry-element="PaysafeIconBackground" data-sentry-source-file="DepositButtons.jsx" />
          </div>
        </div>
        <div className={st["gift-image-line"]}>
          <TrustlyIconBackground data-sentry-element="TrustlyIconBackground" data-sentry-source-file="DepositButtons.jsx" />
          <SkrillIconBackground data-sentry-element="SkrillIconBackground" data-sentry-source-file="DepositButtons.jsx" />
        </div>
      </div>

      <div className={st["particles-background"]}>
        <Image src={Particles} unoptimized alt="Particles" layout="fill" objectFit="cover" data-sentry-element="Image" data-sentry-source-file="DepositButtons.jsx" />
      </div>

      <div className={st["background-banner"]}>
        <Image src={BackgroundImage} alt="Crypto Banner" layout="fill" objectFit="cover" data-sentry-element="Image" data-sentry-source-file="DepositButtons.jsx" />
      </div>
    </div>;
}