export default function PromotionsIcon(props) {
  return <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="PromotionsIcon" data-sentry-source-file="PromotionsIcon.jsx">
      <g clipPath="url(#clip0_2474_43635)" data-sentry-element="g" data-sentry-source-file="PromotionsIcon.jsx">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.2 0.952931C15.0353 0.105872 13.6588 -0.105893 12.3882 0.423519L4.02353 3.17646H3.17647C1.37647 3.17646 0 4.55293 0 6.35293V8.47058C0 10.2706 1.37647 11.647 3.17647 11.647H4.02353L6.35294 12.3882L7.41177 12.7059L9.52941 13.447L12.3882 14.4C12.8118 14.5059 13.3412 14.6118 13.7647 14.6118C14.6118 14.6118 15.4588 14.2941 16.2 13.7647C17.3647 12.9176 18 11.7529 18 10.3765V4.44705C18 3.07058 17.3647 1.79999 16.2 0.952931ZM2.11765 6.35293V8.47058C2.11765 9.10587 2.54118 9.5294 3.17647 9.5294V5.29411C2.54118 5.29411 2.11765 5.71764 2.11765 6.35293ZM15.0353 12.0706C15.5647 11.647 15.8824 11.1176 15.8824 10.3765V4.34117C15.8824 3.70587 15.5647 3.07058 15.0353 2.64705C14.5059 2.22352 13.7647 2.11764 13.1294 2.3294L5.29412 4.97646V9.84705L5.71765 9.95293L8.89412 11.0118L13.1294 12.3882C13.7647 12.6 14.5059 12.4941 15.0353 12.0706Z" fill="#7179A5" data-sentry-element="path" data-sentry-source-file="PromotionsIcon.jsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="PromotionsIcon.jsx">
        <clipPath id="clip0_2474_43635" data-sentry-element="clipPath" data-sentry-source-file="PromotionsIcon.jsx">
          <rect width="18" height="14.8235" fill="white" data-sentry-element="rect" data-sentry-source-file="PromotionsIcon.jsx" />
        </clipPath>
      </defs>
    </svg>;
}