import { classnames } from "@lib/tools/helpers";
import st from "../styles/items/Layout.module.scss";
export function Row({
  children,
  bottomSpace = false,
  wrap = true
}) {
  return <div {...classnames(st, "row", bottomSpace && "space-items", wrap && "wrap-items")} data-sentry-component="Row" data-sentry-source-file="Layout.jsx">
      {children}
    </div>;
}
export function Col({
  children
}) {
  return <div className={st.col} data-sentry-component="Col" data-sentry-source-file="Layout.jsx">{children}</div>;
}