import Enable from "./enable";
import Disable from "./disable";
import { useSearchParams } from "next/navigation";
type Props = Readonly<{
  tab?: "enable" | "disable";
  onDone?: () => void;
}>;
export default function TwoFA({
  tab: tab_,
  ...props
}: Props) {
  const search = useSearchParams();
  const tab = tab_ || search.get("tab");
  switch (tab) {
    case "enable":
      return <Enable {...props} />;
    case "disable":
      return <Disable {...props} />;
    default:
      return null;
  }
}