import { useEffect, useState } from "react";
import { Row, Col } from "../../items/Layout";
import InputItem from "../../items/InputItem";
import Button from "../../items/Button";
import useLanguage from "../../hooks/useLanguage";
import API from "../../lib/api/api";
import usePopup from "../../hooks/usePopup";
import st from "../../styles/components/BetResult.module.scss";
import { useUserSession } from "../../hooks/useUserSession";
export default function FairCheck({
  betDetails
}) {
  const {
    publicId
  } = useUserSession();
  const L = useLanguage(["FairCheck"]);
  const [seedData, setSeedData] = useState(null);
  const [gameDetails, setGameDetails] = useState({});
  const setMessage = usePopup();
  useEffect(() => {
    if (betDetails?.id) {
      API.post(`public/game-seeds`, {
        public_id: betDetails?.id
      }).then(res => {
        console.log("game seeds:", res.data);
        // console.log(res)
        if (res.data.success) {
          setSeedData(res.data.result[0]);
        }
      }).catch(() => {
        setMessage({
          code: "responses.er_network",
          type: 0
        });
      });
    }
  }, [betDetails, setMessage]);

  // Setting game details for 'plinko' type game
  useEffect(() => {
    if (betDetails?.game?.name === "Plinko") {
      setGameDetails({
        plinkoRowCount: betDetails?.game?.parameters?.row_count,
        plinkoRiskType: betDetails?.game?.parameters?.risk_type
      });
    } else if (betDetails?.game?.name === "Mines") {
      const boardObject = betDetails?.game?.parameters;
      if (boardObject?.board) {
        setGameDetails({
          gridSize: boardObject?.board?.length,
          minesCount: boardObject?.mines
        });
      }
    }
  }, [betDetails]);
  return <>
      {/* <ModalSpinner hide={seedData?.client_seed}/> */}
      <div className={st.container}>
        <form className={st["form-container"]}>
          <h3>{L("provablyFair", {})}</h3>
          <Col data-sentry-element="Col" data-sentry-source-file="FairCheck.jsx">
            <InputItem value={seedData?.server_seed ? seedData?.server_seed : L("seedNotRevealed", {})} copy={true} classType={["mid-height"]} label={L("serverSeed", {})} data-sentry-element="InputItem" data-sentry-source-file="FairCheck.jsx" />
            <InputItem value={seedData?.hash_server_seed} copy={true} classType={["mid-height"]} label={L("serverSeedHashed", {})} data-sentry-element="InputItem" data-sentry-source-file="FairCheck.jsx" />
            <Row wrap={false} data-sentry-element="Row" data-sentry-source-file="FairCheck.jsx">
              <InputItem value={seedData?.client_seed} copy={true} classType={["mid-height"]} label={L("clientSeed", {})} data-sentry-element="InputItem" data-sentry-source-file="FairCheck.jsx" />
              <InputItem value={seedData?.nonce} copy={true} classType={["mid-height"]} label={L("nonce", {})} data-sentry-element="InputItem" data-sentry-source-file="FairCheck.jsx" />
            </Row>
          </Col>
          <div>
            {seedData?.server_seed && <Button text={L("verifyBet", {})} classType={["green", "full-width"]} buttonType={"link"} query={{
            modal: "fairplay",
            tab: "verify",
            serverSeed: seedData?.server_seed,
            clientSeed: seedData?.client_seed,
            nonce: seedData?.nonce,
            gameName: betDetails?.game?.name?.toLowerCase(),
            ...gameDetails
          }} />}
            {!seedData?.server_seed && publicId === betDetails?.user?.public_id && <div className={st["rotate-seed-section"]}>
                  {["Roulette", "Crash"].includes(betDetails?.game?.name) ? <span>{L("publicGameSeed", {})}</span> // replace "alternateMessage" with your actual translation key for "Wait for server seed to rotate"
            : <>
                      {" "}
                      <span>{L("rotateSeedInfo", {})}</span>
                      <Button text={L("rotateSeed", {})} classType={["plain", "half-width"]} buttonType={"link"} query={{
                modal: "fairplay",
                tab: "seeds"
              }} />
                    </>}
                </div>}
          </div>
        </form>
      </div>
    </>;
}