// hooks/useDoubleTap.js
import { useState } from 'react';

export default function useDoubleTap(callback = () => {}, delay = 300) {
  const [lastTap, setLastTap] = useState(0);

  const handleTap = () => {
    const now = Date.now();
    if (now - lastTap < delay) {
      callback();
    }
    setLastTap(now);
  };

  return {
    onClick: handleTap,
  };
}