export default function LogoIcon() {
  return <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 202.28 253.686" data-sentry-element="svg" data-sentry-component="LogoIcon" data-sentry-source-file="LogoIcon.jsx">
      <defs data-sentry-element="defs" data-sentry-source-file="LogoIcon.jsx">
        <linearGradient id="logo-gradient" x1="126.86" x2="126.86" y1="0.314" y2="254" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="LogoIcon.jsx">
          <stop offset="0.101" stopColor="#3f51ff" data-sentry-element="stop" data-sentry-source-file="LogoIcon.jsx"></stop>
          <stop offset="1" stopColor="#00d1ff" data-sentry-element="stop" data-sentry-source-file="LogoIcon.jsx"></stop>
        </linearGradient>
        <linearGradient id="logo-gradient-2" x1="126.317" x2="126.317" y1="196.157" y2="55.181" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="LogoIcon.jsx">
          <stop offset="0" stopColor="#fff" data-sentry-element="stop" data-sentry-source-file="LogoIcon.jsx"></stop>
          <stop offset="0.37" stopColor="#fff" data-sentry-element="stop" data-sentry-source-file="LogoIcon.jsx"></stop>
          <stop offset="1" stopColor="#70d9ff" data-sentry-element="stop" data-sentry-source-file="LogoIcon.jsx"></stop>
        </linearGradient>
      </defs>
      <path fill="url(#logo-gradient)" d="M124.705 1.469l-98.62 148.263a2.165 2.165 0 001.803 3.363h18.97a3.87 3.87 0 013.808 3.39 76.74 76.74 0 0049.448 61.268 6.958 6.958 0 013.397 2.647l21.714 32.634a2.165 2.165 0 003.605 0l21.662-32.57a6.909 6.909 0 013.18-2.597 103.405 103.405 0 0011.847-5.517 76.863 76.863 0 0037.534-55.858 3.88 3.88 0 013.818-3.396h18.96a2.165 2.165 0 001.803-3.364L129.014 1.469a2.588 2.588 0 00-4.309 0z" transform="translate(-25.72 -.314)" data-sentry-element="path" data-sentry-source-file="LogoIcon.jsx"></path>
      <path fill="url(#logo-gradient-2)" d="M167.87 120.397L126.315 55.18l-41.551 65.216a49.274 49.274 0 1083.106 0z" transform="translate(-25.72 -.314)" data-sentry-element="path" data-sentry-source-file="LogoIcon.jsx"></path>
    </svg>;
}