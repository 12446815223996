import st from "../../styles/components/Profile.module.scss";
import { numFormat } from "../../lib/tools/helpers";
import useLanguage from "../../hooks/useLanguage";
import ValueDisplay from "../../items/ValueDisplay";
export default function Overview({
  userDetails
}) {
  //console.log(userDetails)
  const L = useLanguage(["UserProfile"]);
  return <div className={st["overview-container"]} data-sentry-component="Overview" data-sentry-source-file="Overview.jsx">
      <div className={st["overview-section"]}>
        <div className={st["icon"]}>
          <svg width="100%" height="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Overview.jsx">
            <path d="M12.3871 15.4354C10.9798 15.4354 9.78827 15.0874 8.99996 14.5133V15.4354C8.99996 16.3704 10.5154 17.129 12.3871 17.129C14.257 17.129 15.7741 16.3704 15.7741 15.4354V14.5133C14.9854 15.0874 13.7942 15.4354 12.3871 15.4354Z" fill="white" data-sentry-element="path" data-sentry-source-file="Overview.jsx" />
            <path d="M15.7741 12.7259C15.7741 13.6606 14.257 14.4194 12.3871 14.4194C10.5154 14.4194 8.99996 13.6606 8.99996 12.7259C8.99996 11.7903 10.5154 11.0322 12.3871 11.0322C14.257 11.0322 15.7741 11.7903 15.7741 12.7259Z" fill="white" data-sentry-element="path" data-sentry-source-file="Overview.jsx" />
            <path d="M8.99994 8.66145C8.99994 9.59608 7.48286 10.3547 5.61278 10.3547C3.74128 10.3547 2.22583 9.59608 2.22583 8.66145C2.22583 7.7258 3.74128 6.96777 5.61278 6.96777C7.48286 6.96777 8.99994 7.7258 8.99994 8.66145Z" fill="white" data-sentry-element="path" data-sentry-source-file="Overview.jsx" />
            <path d="M10.016 5.2743C8.60888 5.2743 7.41737 4.92618 6.62865 4.35206V5.2743C6.62865 6.20893 8.14451 6.96778 10.016 6.96778C11.8853 6.96778 13.4032 6.20893 13.4032 5.2743V4.35206C12.6145 4.92618 11.4225 5.2743 10.016 5.2743Z" fill="white" data-sentry-element="path" data-sentry-source-file="Overview.jsx" />
            <path d="M13.4032 2.56445C13.4032 3.49929 11.8853 4.25813 10.016 4.25813C8.14451 4.25813 6.62865 3.49929 6.62865 2.56445C6.62865 1.629 8.14451 0.870972 10.016 0.870972C11.8853 0.870972 13.4032 1.629 13.4032 2.56445Z" fill="white" data-sentry-element="path" data-sentry-source-file="Overview.jsx" />
            <path d="M8.99994 10.9375V10.4488C8.21122 11.0229 7.01931 11.3708 5.61278 11.3708C4.20565 11.3708 3.01414 11.0229 2.22583 10.4488V11.3708C2.22583 12.3059 3.74128 13.0645 5.61278 13.0645C6.54132 13.0645 7.38247 12.8767 7.99438 12.5737C8.04458 11.9333 8.40571 11.3708 8.99994 10.9375Z" fill="white" data-sentry-element="path" data-sentry-source-file="Overview.jsx" />
            <path d="M7.98341 13.6732C7.3087 13.9258 6.51226 14.0807 5.61278 14.0807C4.20565 14.0807 3.01414 13.7327 2.22583 13.1584V14.0807C2.22583 15.0155 3.74128 15.7741 5.61278 15.7741C6.53604 15.7741 7.37211 15.5876 7.98341 15.2878V13.6732Z" fill="white" data-sentry-element="path" data-sentry-source-file="Overview.jsx" />
            <path d="M10.016 7.98391C9.9707 7.98391 9.93066 7.97679 9.88616 7.97598C9.96826 8.19303 10.0164 8.42125 10.0164 8.66146V9.67738C11.8861 9.67738 13.4032 8.91874 13.4032 7.98391V7.06187C12.6145 7.63599 11.4225 7.98391 10.016 7.98391Z" fill="white" data-sentry-element="path" data-sentry-source-file="Overview.jsx" />
          </svg>
        </div>
        <div className={st["text-detail"]}>
          <h3>{L("total_bets")}</h3>
          <div className={st["value"]}>
            <h2>
              {userDetails?.total_bets ? numFormat(userDetails?.total_bets, 0) : 0}
            </h2>
          </div>
        </div>
      </div>
      <div className={st["overview-section"]}>
        <div className={st["icon"]}>
          <svg width="100%" height="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="Overview.jsx">
            <path d="M2.8631 4.5C3.00653 3.47906 3.47341 3.09375 4.44935 3.09375H13.244C13.3888 3.10027 13.5254 3.1624 13.6255 3.26719C13.7256 3.37198 13.7814 3.51135 13.7812 3.65625V4.5H2.8631ZM14.9062 4.52531V3.65625C14.9063 3.21305 14.732 2.78762 14.4209 2.47187C14.1099 2.15612 13.6872 1.9754 13.244 1.96875H4.44935C2.00247 1.96875 1.72403 3.96281 1.68747 4.99781C1.68621 5.00809 1.68621 5.01848 1.68747 5.02875V5.0625V14.0625C1.68747 14.5101 1.86526 14.9393 2.18173 15.2557C2.4982 15.5722 2.92742 15.75 3.37497 15.75H14.625C15.0725 15.75 15.5017 15.5722 15.8182 15.2557C16.1347 14.9393 16.3125 14.5101 16.3125 14.0625V6.1875C16.3121 5.789 16.1707 5.40351 15.9133 5.09928C15.6559 4.79505 15.2991 4.59173 14.9062 4.52531ZM13.0781 11.5312C12.8278 11.5312 12.5831 11.457 12.375 11.318C12.1668 11.1789 12.0046 10.9812 11.9088 10.75C11.813 10.5187 11.788 10.2642 11.8368 10.0187C11.8856 9.77321 12.0062 9.54769 12.1832 9.37069C12.3602 9.19369 12.5857 9.07315 12.8312 9.02432C13.0767 8.97548 13.3312 9.00055 13.5624 9.09634C13.7937 9.19213 13.9914 9.35435 14.1304 9.56248C14.2695 9.77061 14.3437 10.0153 14.3437 10.2656C14.3441 10.4319 14.3116 10.5967 14.2481 10.7504C14.1847 10.9041 14.0915 11.0438 13.9739 11.1614C13.8563 11.279 13.7166 11.3722 13.5629 11.4357C13.4091 11.4991 13.2444 11.5316 13.0781 11.5312Z" fill="white" data-sentry-element="path" data-sentry-source-file="Overview.jsx" />
          </svg>
        </div>
        <div className={st["text-detail"]}>
          <h3>{L("total_wagered")}</h3>
          <div className={st["value"]}>
            <ValueDisplay amount={userDetails?.wagered_amount} raw={true} data-sentry-element="ValueDisplay" data-sentry-source-file="Overview.jsx" />
          </div>
        </div>
      </div>
    </div>;
}