import st from "@styles/games/CaseBattles.module.scss";
import { useRouter } from "next/router";
import casesListAtom from "../../../recoil/casesList/atom";
import battlesCaseListAtom from "../../../recoil/battlesCaseList/atom";
import { useRecoilState, useRecoilValue } from "recoil";
import { useEffect, useState, useReducer, useMemo } from "react";
import rainCaseLimitedListAtom from "../../../recoil/rainCaseLimitedList/atom";
import { sum } from "mathjs";
import { caseOpenInitialState, caseOpenReducer } from "../CaseOpenReducer";
import CaseList from "../CaseList";
import CaseOpenInputs from "../CaseOpenInputs";
import Button from "../../../items/Button";
import ValueDisplay from "../../../items/ValueDisplay";
import useLanguage from "../../../hooks/useLanguage";
import ToolTip from "@items/ToolTip";
import useConvert from "@hooks/useConvert";
import { sBig } from "@lib/tools/numberHelpers";
export default function CaseBattlesSelect(props) {
  const router = useRouter();
  const L = useLanguage("CaseBattle");
  const [casesList, setCasesList] = useRecoilState(casesListAtom);
  //Switch between sections and create the menu
  const [selectedCases, setSelectedCases] = useRecoilState(battlesCaseListAtom); // Stores the selected cases
  const rainLimitedCases = useRecoilValue(rainCaseLimitedListAtom); // Stores the selected cases
  const [state, dispatch] = useReducer(caseOpenReducer, caseOpenInitialState);
  const [error, setError] = useState(false);
  const [totalValue, setTotalValue] = useState(0);
  const {
    getConvertedAmount
  } = useConvert();
  //Used because if the modal opens with the error, the popup will be in the wrong position
  const [delayTotalError, setDelayTotalError] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setDelayTotalError(true);
    }, 500);
  }, [selectedCases]);
  useEffect(() => {
    if (!selectedCases) return;
    let hostTotal = Object.values(selectedCases).reduce((sum, item) => sBig(sum).plus(sBig(getConvertedAmount(item.price, {
      round: {
        direction: "roundHalfUp"
      }
    })).times(item.amount)), sBig(0));
    setTotalValue(hostTotal.toNumber());
  }, [selectedCases, getConvertedAmount]);
  const {
    cases,
    amountInRowsState,
    search,
    sortBy,
    sortByCategory,
    filteredCases
  } = state;
  useEffect(() => {
    if (casesList) {
      // console.log("Add Cases", casesList);
      dispatch({
        type: "SET_CASES",
        payload: casesList
      });
    }
  }, [casesList]);
  const filterCasesByCategory = () => {
    const newFilteredCases = {};
    if (sortByCategory === "all-categories") {
      dispatch({
        type: "SET_FILTERED_CASES",
        payload: cases
      });
    } else {
      newFilteredCases[sortByCategory] = cases[sortByCategory];
      dispatch({
        type: "SET_FILTERED_CASES",
        payload: newFilteredCases
      });
    }
  };
  const checkSearchLength = (filteredCases, testSearch) => {
    const items = Object.keys(filteredCases).flatMap(key => filteredCases[key].filter(item => item.name.toLowerCase().includes(testSearch.toLowerCase())));
    if (Object.keys(cases).length > 0 && items.length === 0) {
      setError(true);
    } else {
      setError(false);
    }
  };
  useEffect(() => {
    filterCasesByCategory();
    checkSearchLength(cases, search);
  }, [sortByCategory, cases, search, error]);
  const total = useMemo(() => {
    return sum(Object.values(selectedCases).map(item => item.amount));
  }, [selectedCases]);
  return <div
  // ref={ref}
  className={`${st["all-cases-container"]} `} data-sentry-component="CaseBattlesSelect" data-sentry-source-file="index.jsx">
      <div
    // className={`${isMobile ? st[isMobile] : null} ${
    //   st["all-cases-container"]
    // }`}
    >
        <CaseOpenInputs search={search} setSearch={value => dispatch({
        type: "SET_SEARCH",
        payload: value
      })} sortByCategory={sortByCategory} setSortByCategory={value => dispatch({
        type: "SET_SORT_BY_CATEGORY",
        payload: value
      })} sortBy={sortBy} setSortBy={value => dispatch({
        type: "SET_SORT_BY",
        payload: value
      })} cases={Object.keys(cases)} data-sentry-element="CaseOpenInputs" data-sentry-source-file="index.jsx" />
        {error && <p>{L("no_results_search_terms")}</p>}
        <div className={st["case-list-display-scroll-container"]}>
          <div className={st["case-list-display-scroll"]}>
            <CaseList filteredCases={filteredCases} specialCases={false} amountInRowsState={amountInRowsState} st={st} search={search} sortBy={sortBy} setSelectedCases={setSelectedCases} selectedCases={selectedCases} rainLimitedCases={rainLimitedCases} data-sentry-element="CaseList" data-sentry-source-file="index.jsx" />
          </div>
          <div className={st["case-row-bottom-fade"]}> </div>
        </div>
      </div>
      <div className={st["submit-modal-container"]}>
        <div className={st["left-side"]}>
          <div className={st["total-value"]}>
            <span>{L("modal_amount")}</span>
            <div>
              <ToolTip text={L("max_cases_limit")} showMode={true} forceShow={total > 30 && delayTotalError} data-sentry-element="ToolTip" data-sentry-source-file="index.jsx">
                <span>{total}</span>
              </ToolTip>
            </div>
          </div>
          <div className={st["total-value"]}>
            <span>{L("modal_total_cost")}</span>
            <div className={st["amount-icon"]}>
              {/* <ValueIcons size={"small"} />
               <span>{totalValue}</span> */}
              <ValueDisplay raw amount={totalValue} size={"small"} data-sentry-element="ValueDisplay" data-sentry-source-file="index.jsx" />
            </div>
          </div>
        </div>
        <div className={st["continue"]}>
          <Button text={L("modal_continue")} deActive={total === 0 || total > 30} method={() => {
          router.replace(router.asPath.split("?")[0], undefined, {
            shallow: true
          });
        }} classType={["blue", "full-width"]} data-sentry-element="Button" data-sentry-source-file="index.jsx" />
        </div>
      </div>
    </div>;
}