export default function BlueStarIcon(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none" {...props} data-sentry-element="svg" data-sentry-component="BlueStarIcon" data-sentry-source-file="BlueStarIcon.jsx">
      <g filter="url(#filter0_d_4224_10998)" data-sentry-element="g" data-sentry-source-file="BlueStarIcon.jsx">
        <path d="M27 20L29.3709 24.6291L34 27L29.3709 29.3709L27 34L24.6291 29.3709L20 27L24.6291 24.6291L27 20Z" fill="url(#paint0_linear_4224_10998)" data-sentry-element="path" data-sentry-source-file="BlueStarIcon.jsx" />
        <path d="M27 20.5484L29.1484 24.743L29.1852 24.8148L29.257 24.8516L33.4516 27L29.257 29.1484L29.1852 29.1852L29.1484 29.257L27 33.4516L24.8516 29.257L24.8148 29.1852L24.743 29.1484L20.5484 27L24.743 24.8516L24.8148 24.8148L24.8516 24.743L27 20.5484Z" stroke="url(#paint1_linear_4224_10998)" strokeWidth="0.5" data-sentry-element="path" data-sentry-source-file="BlueStarIcon.jsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="BlueStarIcon.jsx">
        <filter id="filter0_d_4224_10998" x="0" y="0" width="54" height="54" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB" data-sentry-element="filter" data-sentry-source-file="BlueStarIcon.jsx">
          <feFlood floodOpacity="0" result="BackgroundImageFix" data-sentry-element="feFlood" data-sentry-source-file="BlueStarIcon.jsx" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" data-sentry-element="feColorMatrix" data-sentry-source-file="BlueStarIcon.jsx" />
          <feOffset data-sentry-element="feOffset" data-sentry-source-file="BlueStarIcon.jsx" />
          <feGaussianBlur stdDeviation="10" data-sentry-element="feGaussianBlur" data-sentry-source-file="BlueStarIcon.jsx" />
          <feComposite in2="hardAlpha" operator="out" data-sentry-element="feComposite" data-sentry-source-file="BlueStarIcon.jsx" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.211765 0 0 0 0 0.653726 0 0 0 0 0.843137 0 0 0 0.43 0" data-sentry-element="feColorMatrix" data-sentry-source-file="BlueStarIcon.jsx" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4224_10998" data-sentry-element="feBlend" data-sentry-source-file="BlueStarIcon.jsx" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4224_10998" result="shape" data-sentry-element="feBlend" data-sentry-source-file="BlueStarIcon.jsx" />
        </filter>
        <linearGradient id="paint0_linear_4224_10998" x1="22.4466" y1="11.0909" x2="35.6974" y2="12.5241" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="BlueStarIcon.jsx">
          <stop stopColor="#619FD3" data-sentry-element="stop" data-sentry-source-file="BlueStarIcon.jsx" />
          <stop offset="1" stopColor="#0077DB" data-sentry-element="stop" data-sentry-source-file="BlueStarIcon.jsx" />
        </linearGradient>
        <linearGradient id="paint1_linear_4224_10998" x1="27" y1="20" x2="27" y2="34" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="BlueStarIcon.jsx">
          <stop stopColor="#0077DB" data-sentry-element="stop" data-sentry-source-file="BlueStarIcon.jsx" />
          <stop offset="1" stopColor="white" stopOpacity="0" data-sentry-element="stop" data-sentry-source-file="BlueStarIcon.jsx" />
        </linearGradient>
      </defs>
    </svg>;
}