import { Dict } from "@hooks/useLanguage";
import ToolTip from "@items/ToolTip";
export const formValidation = {
  username: {
    minLength: 3
  },
  password: {
    minLength: 10,
    patterns: {
      lowercase: /[a-z]/,
      uppercase: /[A-Z]/,
      specialChar: /[~`!@#$%^&*()_\-+=\[\]{}\'":;,.<>?\/\\|]/
    }
  }
};
const checkSvgGreen = () => {
  return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="checkSvgGreen" data-sentry-source-file="password-validation.tsx">
      <circle cx="9" cy="9" r="9" fill="#1BBB3E" data-sentry-element="circle" data-sentry-source-file="password-validation.tsx" />
      <g clipPath="url(#clip0_18630_155828)" data-sentry-element="g" data-sentry-source-file="password-validation.tsx">
        <path d="M12.7861 7.39138L8.46548 11.7116C8.14554 12.0317 7.62654 12.0317 7.30629 11.7116L5.07599 9.48118C4.75592 9.16117 4.75592 8.64211 5.07599 8.32205C5.39611 8.00192 5.91507 8.00192 6.23505 8.32193L7.88606 9.97295L11.6268 6.23219C11.9469 5.91207 12.4659 5.91231 12.7859 6.23219C13.1059 6.55226 13.1059 7.07113 12.7861 7.39138Z" fill="white" data-sentry-element="path" data-sentry-source-file="password-validation.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="password-validation.tsx">
        <clipPath id="clip0_18630_155828" data-sentry-element="clipPath" data-sentry-source-file="password-validation.tsx">
          <rect width="8.19" height="8.19" fill="white" transform="translate(4.90625 4.90625)" data-sentry-element="rect" data-sentry-source-file="password-validation.tsx" />
        </clipPath>
      </defs>
    </svg>;
};
const wrongSvgGray = () => {
  return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="wrongSvgGray" data-sentry-source-file="password-validation.tsx">
      <circle cx="9" cy="9" r="9" fill="#E8E5FF" fillOpacity="0.2" data-sentry-element="circle" data-sentry-source-file="password-validation.tsx" />
      <circle cx="9" cy="9" r="8.5" stroke="white" strokeOpacity="0.05" data-sentry-element="circle" data-sentry-source-file="password-validation.tsx" />
      <path d="M5.50211 12.5043L5.50202 12.5044L5.50446 12.5065C5.64923 12.631 5.8365 12.6945 6.02705 12.684C6.21509 12.6926 6.39937 12.6292 6.54225 12.5065L6.54233 12.5066L6.54453 12.5044L12.507 6.5419L12.5072 6.54206L12.5099 6.53873C12.7424 6.252 12.7277 5.83771 12.4755 5.56816L12.4456 5.59614L12.4755 5.56816C12.1966 5.27009 11.7288 5.25458 11.4308 5.5335L11.4308 5.53348L11.4298 5.53444L5.5022 11.4621L5.50211 11.4621C5.21553 11.7504 5.21553 12.216 5.50211 12.5043Z" fill="white" stroke="white" strokeWidth="0.0819" data-sentry-element="path" data-sentry-source-file="password-validation.tsx" />
      <path d="M11.4259 12.4683L11.426 12.4684C11.5632 12.6045 11.7482 12.6814 11.9414 12.6827C12.0415 12.6929 12.1427 12.6825 12.2387 12.6522C12.3353 12.6217 12.4246 12.5716 12.5011 12.5051L12.5011 12.5052L12.5022 12.5041C12.5141 12.493 12.5255 12.4816 12.5366 12.4697L12.5067 12.4417L12.5366 12.4697C12.8155 12.1719 12.8002 11.7044 12.5027 11.4254L6.54057 5.49822L6.54065 5.49814L6.53834 5.49616C6.26164 5.25918 5.85352 5.25918 5.57679 5.49616L5.57679 5.49616C5.26674 5.76169 5.23065 6.22829 5.49615 6.53834L5.49606 6.53842L5.4983 6.54066L11.4259 12.4683Z" fill="white" stroke="white" strokeWidth="0.0819" data-sentry-element="path" data-sentry-source-file="password-validation.tsx" />
    </svg>;
};
export function PasswordValidationTooltip({
  forceShow,
  passwordValidationState,
  placement,
  children,
  validationRules
}) {
  return <ToolTip placement={placement} size={"small"} transparent={true} forceShow={forceShow} showMode={true} innerContent={<div className="p-4 w-[200px] sm:min-w-[280px] whitespace-pre-wrap bg-[#2A315B] border border-solid border-[#2E3666] py-3 px-4 grid gap-3 rounded-md">
          <Dict name="password_validation_tooltip" as="p" section="settings" className="text-white font-medium text-[13px] block" />

          <ul className="space-y-2">
            {validationRules.map(({
        key,
        text
      }) => <li key={key} className="font-normal text-[13px] flex items-center gap-2 text-gray-200 break-words [&_svg]:min-w-[18px] [&_svg]:min-h-[18px]">
                {passwordValidationState[key] ? checkSvgGreen() : wrongSvgGray()}
                <span>{text}</span>
              </li>)}
          </ul>
        </div>} data-sentry-element="ToolTip" data-sentry-component="PasswordValidationTooltip" data-sentry-source-file="password-validation.tsx">
      {children}
    </ToolTip>;
}