export default function KinguinIcon() {
  return <svg xmlns="http://www.w3.org/2000/svg" width="52" height="16" viewBox="0 0 52 16" fill="none" data-sentry-element="svg" data-sentry-component="KinguinIcon" data-sentry-source-file="KinguinIcon.jsx">
      <path d="M20.8707 8.0222C20.8707 7.68931 20.6185 7.42946 20.2976 7.42946C20.0531 7.42946 19.9155 7.57565 19.8276 7.69742L17.9096 9.97097L16.855 9.95471V8.01007C16.855 7.67305 16.6066 7.40918 16.2895 7.40918C15.9724 7.40918 15.7164 7.67305 15.7164 8.01007V12.7479C15.7164 13.0849 15.9685 13.3488 16.2895 13.3488C16.6105 13.3488 16.855 13.0849 16.855 12.7479V11.0346L17.6994 11.0509C17.6994 11.0509 18.0471 11.0509 18.0624 11.0468L19.778 13.1417C19.9117 13.2919 20.0531 13.3609 20.2403 13.3609C20.5383 13.3609 20.7752 13.1133 20.7752 12.7926C20.7752 12.618 20.7103 12.4759 20.5841 12.35L18.9947 10.4419L20.7256 8.43634C20.7981 8.33486 20.8745 8.21304 20.8745 8.0141" fill="white" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
      <path d="M22.697 7.42956C22.3761 7.42956 22.1239 7.69343 22.1239 8.0304V12.7683C22.1239 13.1053 22.3761 13.3691 22.697 13.3691C23.018 13.3691 23.2625 13.1053 23.2625 12.7683V8.0304C23.2625 7.69343 23.0142 7.42956 22.697 7.42956Z" fill="white" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
      <path d="M35.5619 9.93869H34.7633C34.4768 9.93869 34.2437 10.1823 34.2437 10.4827C34.2437 10.7831 34.4768 11.0186 34.7633 11.0186H35.027V11.9077C34.706 12.1473 34.2781 12.2813 33.8502 12.2813C32.8491 12.2813 32.1499 11.5464 32.1499 10.4097C32.1499 9.37439 32.8758 8.5299 33.7661 8.5299C34.2551 8.5299 34.6105 8.65172 34.9964 8.95214C35.0843 9.01708 35.1951 9.0861 35.3441 9.0861C35.6574 9.0861 35.9096 8.81814 35.9096 8.48525C35.9096 8.20919 35.7491 8.05083 35.6765 7.994C35.134 7.57991 34.5723 7.40126 33.8043 7.40126C32.211 7.40126 30.9654 8.72884 30.9654 10.4421C30.9654 12.1554 32.1651 13.4018 33.8196 13.4018C34.6907 13.4018 35.3594 13.0608 35.7606 12.7725C36.0128 12.606 36.1351 12.3706 36.1351 12.0498V10.5436C36.1351 10.2026 35.8829 9.93461 35.5619 9.93461" fill="white" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
      <path d="M41.7441 7.42956C41.4232 7.42956 41.171 7.69343 41.171 8.0304V10.783C41.171 11.7208 40.7354 12.2364 39.9406 12.2364C39.1459 12.2364 38.7027 11.7046 38.7027 10.7424V8.0304C38.7027 7.69343 38.4543 7.42956 38.1372 7.42956C37.8201 7.42956 37.5641 7.69343 37.5641 8.0304V10.783C37.5641 12.4273 38.4238 13.3732 39.9254 13.3732C41.427 13.3732 42.3096 12.411 42.3096 10.7343V8.0304C42.3096 7.69343 42.0613 7.42956 41.7441 7.42956Z" fill="white" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
      <path d="M47.4562 13.3732C47.7771 13.3732 48.0292 13.1093 48.0292 12.7723V10.0197C48.0292 9.08193 48.4649 8.56631 49.2596 8.56631C50.0543 8.56631 50.4978 9.09814 50.4978 10.0603V12.7723C50.4978 13.1093 50.7459 13.3732 51.063 13.3732C51.3804 13.3732 51.6364 13.1093 51.6364 12.7723V10.0197C51.6364 8.37548 50.7767 7.42956 49.2747 7.42956C47.7732 7.42956 46.8906 8.39174 46.8906 10.0685V12.7723C46.8906 13.1093 47.1392 13.3732 47.4562 13.3732Z" fill="white" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
      <path d="M25.5359 13.361C25.8569 13.361 26.1091 13.0971 26.1091 12.7601V10.0197C26.1091 9.08193 26.5447 8.56631 27.3394 8.56631C28.1341 8.56631 28.5774 9.09814 28.5774 10.0603V12.7723C28.5774 13.1093 28.8257 13.3732 29.1428 13.3732C29.46 13.3732 29.716 13.1093 29.716 12.7723V10.0197C29.716 8.37548 28.8563 7.42956 27.3547 7.42956C25.8531 7.42956 24.9705 8.39174 24.9705 10.0685V12.7601C24.9705 13.0971 25.2188 13.361 25.5359 13.361Z" fill="white" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
      <path d="M44.6364 7.42956C44.3154 7.42956 44.0634 7.69343 44.0634 8.0304V12.7683C44.0634 13.1053 44.3154 13.3691 44.6364 13.3691C44.9573 13.3691 45.202 13.1053 45.202 12.7683V8.0304C45.202 7.69343 44.9538 7.42956 44.6364 7.42956Z" fill="white" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
      <path d="M1.21996 7.46198C1.42628 7.16971 1.75106 6.59726 2.29362 5.00982C2.29362 5.00982 2.68717 3.94208 3.45899 3.12199C2.07965 4.29123 1.93828 5.98826 0.948678 7.47013C-0.560567 9.72743 -0.0982424 12.4191 1.14354 13.8725C-0.384807 11.5097 0.211249 8.88702 1.21996 7.46606" fill="white" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
      <path d="M8.86932 5.00588C9.40804 6.59327 9.73281 7.1698 9.94299 7.45804C10.9517 8.87901 11.5478 11.5017 10.0194 13.8645C11.2612 12.4111 11.7235 9.71937 10.2143 7.46207C9.22467 5.98025 9.08327 4.28727 7.70397 3.11396C7.69249 3.10178 7.68101 3.08961 7.66958 3.07337C8.46811 3.89752 8.86932 5.00181 8.86932 5.00181" fill="white" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
      <path d="M7.21882 5.33067C7.00483 5.22511 6.74883 5.30223 6.58837 5.50525C6.53489 5.57426 6.56161 5.67575 6.63424 5.69603C6.72973 5.72447 6.86347 5.76912 6.99721 5.83814C7.13476 5.90715 7.2494 5.98432 7.33346 6.04518C7.39838 6.09391 7.49011 6.03708 7.50539 5.9437C7.53978 5.68793 7.43277 5.43623 7.22263 5.33067" fill="black" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
      <path d="M3.94033 5.33067C4.15429 5.22511 4.41028 5.30223 4.57079 5.50525C4.62427 5.57426 4.59751 5.67575 4.52492 5.69603C4.42938 5.72447 4.29565 5.76912 4.16195 5.83814C4.0244 5.90715 3.90976 5.98432 3.8257 6.04518C3.76075 6.09391 3.66904 6.03708 3.65376 5.9437C3.61937 5.68793 3.72636 5.43623 3.9365 5.33067" fill="black" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
      <path d="M9.94303 7.46212C9.73671 7.1698 9.41194 6.59735 8.86936 5.00996C8.86936 5.00996 8.46438 3.90566 7.66962 3.0815C7.66962 3.0815 6.93601 3.43066 5.58344 3.43066C4.23086 3.43066 3.49723 3.0815 3.49723 3.0815C2.69867 3.9016 2.29748 5.00588 2.29748 5.00588C1.75873 6.59332 1.43396 7.1698 1.22381 7.45804C0.13869 8.98457 -0.472651 11.9036 1.52948 14.3923C1.83515 14.7739 1.99563 14.4451 1.83515 14.0107C0.967816 11.6641 2.33951 8.85872 2.81712 8.07922C3.05019 7.6976 3.11897 7.14951 3.03491 6.70291C2.60697 4.35631 3.98629 4.17361 3.98629 4.17361C4.96828 4.08024 5.24337 5.03432 5.31596 5.57023C5.3924 5.50122 5.4879 5.4687 5.58344 5.4687C5.67893 5.4687 5.77447 5.50525 5.85087 5.57023C5.92731 5.03025 6.19859 4.07618 7.18053 4.17361C7.18053 4.17361 8.55988 4.35631 8.13194 6.70291C8.05169 7.15354 8.11666 7.6976 8.34974 8.07922C8.82736 8.85872 10.199 11.6641 9.33169 14.0107C9.17123 14.4451 9.33169 14.7739 9.63736 14.3923C11.6357 11.9036 11.0281 8.98457 9.94299 7.45804" fill="black" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
      <path d="M6.21375 6.53241C6.08764 6.5162 5.86984 6.47965 5.57945 6.47965C5.28906 6.47965 5.07131 6.5162 4.94519 6.53241L5.31202 7.01148C5.38079 7.10079 5.48014 7.14548 5.57945 7.14548C5.6788 7.14548 5.77815 7.10079 5.84693 7.01148L6.21375 6.53241Z" fill="#D94726" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
      <path d="M6.37811 6.2563L5.90812 5.6392C5.8241 5.52961 5.7018 5.47278 5.57954 5.47278C5.45728 5.47278 5.33502 5.52961 5.25095 5.6392L4.78096 6.2563C4.70076 6.36186 4.75805 6.55673 4.94909 6.53236C5.0752 6.51615 5.29296 6.47961 5.58335 6.47961C5.87373 6.47961 6.09153 6.51615 6.21765 6.53236C6.40487 6.55673 6.46598 6.36186 6.38573 6.2563" fill="#F26621" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
      <path d="M8.34979 8.08325C8.1167 7.70163 8.04792 7.15354 8.13199 6.70694C8.55992 4.36034 7.18062 4.17765 7.18062 4.17765C6.19864 4.08427 5.92735 5.03428 5.85095 5.57426C5.87386 5.59455 5.89296 5.61484 5.91206 5.6392L6.38205 6.2563C6.4623 6.36186 6.40479 6.55677 6.21375 6.53241L5.84693 7.01148C5.77815 7.10079 5.6788 7.14548 5.57945 7.14548C5.48014 7.14548 5.38079 7.10079 5.31202 7.01148L4.94519 6.53241C4.75797 6.55677 4.69703 6.36186 4.77728 6.2563L5.24723 5.6392C5.26633 5.61484 5.28547 5.59455 5.30838 5.57426C5.23194 5.03428 4.95684 4.0802 3.97871 4.17765C3.97871 4.17765 2.59938 4.36034 3.02732 6.70694C3.10756 7.15757 3.0426 7.70163 2.80953 8.08325C2.35103 8.83431 1.05193 11.4814 1.74733 13.7752C1.76261 13.8158 1.77789 13.8523 1.797 13.8929C1.94219 14.2583 2.18291 14.5953 2.49622 14.8876C2.72547 15.1028 2.98147 15.2855 3.26039 15.4316C3.94814 15.8092 4.76581 16 5.58348 16C6.40115 16 7.21882 15.8132 7.90656 15.4316C8.18166 15.2814 8.44147 15.1028 8.67075 14.8876C8.98405 14.5953 9.22095 14.2624 9.36997 13.8929C9.38526 13.8564 9.40436 13.8158 9.41965 13.7752C10.115 11.4814 8.81978 8.83431 8.35745 8.08325M4.53276 5.69603C4.43722 5.72447 4.30348 5.76912 4.16975 5.83406C4.0322 5.90308 3.91758 5.98024 3.83352 6.04111C3.76857 6.08983 3.67687 6.033 3.66158 5.93962C3.6272 5.68385 3.73418 5.43216 3.94433 5.32659C4.15831 5.22103 4.41431 5.29816 4.57477 5.50117C4.62826 5.57018 4.60154 5.67167 4.52891 5.69196M7.50154 5.93962C7.49011 6.033 7.39838 6.0858 7.3296 6.04111C7.2494 5.98024 7.13095 5.90308 6.9934 5.83406C6.85585 5.76505 6.72592 5.7204 6.63038 5.69603C6.5578 5.67575 6.53107 5.57018 6.58456 5.50525C6.74502 5.30223 7.00102 5.22511 7.21501 5.33067C7.42514 5.43618 7.53212 5.68793 7.49773 5.9437" fill="white" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
      <path d="M5.57967 0L4.55567 1.83506L3.27185 0.641463L3.44761 2.61862C4.77343 3.70667 6.59981 1.83506 6.59981 1.83506L5.57581 0H5.57967Z" fill="#FFDE00" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
      <path d="M6.60349 1.83514L7.12311 1.34795L6.806 0.604991L6.33982 1.36013L6.60349 1.83514Z" fill="#F26621" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
      <path d="M4.55567 1.83506L4.82297 1.36013L4.35684 0.604991L4.03588 1.34795L4.55567 1.83506Z" fill="#F26621" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
      <path d="M6.60349 1.83514C6.60349 1.83514 4.77724 3.7066 3.45138 2.61855L3.49341 3.08138C3.49341 3.08138 4.227 3.43053 5.57962 3.43053C6.9322 3.43053 7.66581 3.08138 7.66581 3.08138L7.88361 0.637336L6.59976 1.83094" fill="#FFC20D" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
      <path d="M12.01 13.8118C12.01 13.7509 11.9909 13.6981 11.9527 13.6616C11.9145 13.6251 11.8572 13.6048 11.7808 13.6048H11.5821C11.5515 13.6048 11.5324 13.6129 11.5171 13.6291C11.5018 13.6453 11.4942 13.6697 11.4942 13.6981V14.2341C11.4942 14.2909 11.5209 14.3193 11.5706 14.3193C11.6203 14.3193 11.647 14.2909 11.647 14.2341V14.0392H11.7158C11.7349 14.0392 11.754 14.0392 11.7693 14.0514C11.7846 14.0595 11.796 14.0757 11.8037 14.1001L11.8495 14.2543C11.8495 14.2543 11.8648 14.2868 11.8763 14.299C11.8877 14.3112 11.903 14.3152 11.9221 14.3152C11.9489 14.3152 11.9718 14.3071 11.9871 14.2909C12.0024 14.2746 12.0062 14.2503 11.9947 14.2178L11.9603 14.1041C11.945 14.0554 11.9298 14.0229 11.9068 14.0107C11.8992 14.0067 11.8877 13.9986 11.8801 13.9986C11.9106 13.9905 11.9374 13.9783 11.9565 13.958C11.9947 13.9255 12.0138 13.8768 12.0138 13.8118M11.7655 13.9174H11.647V13.7225H11.7655C11.7999 13.7225 11.8266 13.7306 11.8457 13.7469C11.8648 13.7631 11.8724 13.7874 11.8724 13.8199C11.8724 13.8524 11.8648 13.8808 11.8457 13.893C11.8266 13.9093 11.7999 13.9174 11.7655 13.9174Z" fill="black" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
      <path d="M12.2624 13.7184C12.2318 13.6453 12.1936 13.5804 12.1439 13.5276C12.0942 13.4748 12.0331 13.4302 11.9643 13.4018C11.8955 13.3693 11.8191 13.3571 11.7389 13.3571C11.6587 13.3571 11.5822 13.3734 11.5135 13.4018C11.4447 13.4343 11.3835 13.4748 11.3339 13.5276C11.2804 13.5804 11.2422 13.6453 11.2116 13.7184C11.1811 13.7915 11.1696 13.8727 11.1696 13.958C11.1696 14.0432 11.1849 14.1244 11.2116 14.1975C11.2422 14.2706 11.2804 14.3355 11.3339 14.3883C11.3874 14.4452 11.4447 14.4857 11.5135 14.5182C11.5822 14.5507 11.6587 14.5629 11.7389 14.5629C11.8191 14.5629 11.8955 14.5467 11.9643 14.5182C12.0331 14.4857 12.0942 14.4452 12.1439 14.3883C12.1936 14.3315 12.2356 14.2706 12.2624 14.1975C12.2929 14.1244 12.3044 14.0432 12.3044 13.958C12.3044 13.8727 12.2891 13.7915 12.2624 13.7184ZM12.1821 14.161C12.1592 14.2219 12.1248 14.2787 12.0828 14.3234C12.0407 14.368 11.9873 14.4046 11.9299 14.433C11.8726 14.4574 11.8077 14.4736 11.7389 14.4736C11.6701 14.4736 11.6051 14.4614 11.5478 14.433C11.4905 14.4086 11.4371 14.3721 11.395 14.3234C11.353 14.2787 11.3186 14.2219 11.2957 14.161C11.2727 14.1001 11.2613 14.031 11.2613 13.958C11.2613 13.8849 11.2727 13.8159 11.2957 13.755C11.3186 13.6941 11.353 13.6372 11.395 13.5926C11.4371 13.5479 11.4905 13.5114 11.5478 13.4829C11.6051 13.4586 11.6701 13.4424 11.7389 13.4424C11.8077 13.4424 11.8726 13.4546 11.9299 13.4829C11.9873 13.5073 12.0407 13.5439 12.0828 13.5926C12.1248 13.6372 12.1592 13.6941 12.1821 13.755C12.2051 13.8159 12.2165 13.8849 12.2165 13.958C12.2165 14.031 12.2051 14.1001 12.1821 14.161Z" fill="black" data-sentry-element="path" data-sentry-source-file="KinguinIcon.jsx" />
    </svg>;
}