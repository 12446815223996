"use client";

import { Button, ButtonProps } from "@components/new-ui/button";
import { cn } from "@lib/utils";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import * as React from "react";
export const XIcon = ({
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="XIcon" data-sentry-source-file="dialog.tsx">
      <path d="M8.40994 6.99994L12.7099 2.70994C12.8982 2.52164 13.004 2.26624 13.004 1.99994C13.004 1.73364 12.8982 1.47825 12.7099 1.28994C12.5216 1.10164 12.2662 0.99585 11.9999 0.99585C11.7336 0.99585 11.4782 1.10164 11.2899 1.28994L6.99994 5.58994L2.70994 1.28994C2.52164 1.10164 2.26624 0.99585 1.99994 0.99585C1.73364 0.99585 1.47824 1.10164 1.28994 1.28994C1.10164 1.47825 0.995847 1.73364 0.995847 1.99994C0.995847 2.26624 1.10164 2.52164 1.28994 2.70994L5.58994 6.99994L1.28994 11.2899C1.19621 11.3829 1.12182 11.4935 1.07105 11.6154C1.02028 11.7372 0.994141 11.8679 0.994141 11.9999C0.994141 12.132 1.02028 12.2627 1.07105 12.3845C1.12182 12.5064 1.19621 12.617 1.28994 12.7099C1.3829 12.8037 1.4935 12.8781 1.61536 12.9288C1.73722 12.9796 1.86793 13.0057 1.99994 13.0057C2.13195 13.0057 2.26266 12.9796 2.38452 12.9288C2.50638 12.8781 2.61698 12.8037 2.70994 12.7099L6.99994 8.40994L11.2899 12.7099C11.3829 12.8037 11.4935 12.8781 11.6154 12.9288C11.7372 12.9796 11.8679 13.0057 11.9999 13.0057C12.132 13.0057 12.2627 12.9796 12.3845 12.9288C12.5064 12.8781 12.617 12.8037 12.7099 12.7099C12.8037 12.617 12.8781 12.5064 12.9288 12.3845C12.9796 12.2627 13.0057 12.132 13.0057 11.9999C13.0057 11.8679 12.9796 11.7372 12.9288 11.6154C12.8781 11.4935 12.8037 11.3829 12.7099 11.2899L8.40994 6.99994Z" fill="white" data-sentry-element="path" data-sentry-source-file="dialog.tsx" />
    </svg>;
};
const Dialog = DialogPrimitive.Root;
const DialogTrigger = DialogPrimitive.Trigger;
const DialogPortal = DialogPrimitive.Portal;
const DialogClose = DialogPrimitive.Close;
const DialogOverlay = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Overlay>, React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>>(({
  className,
  ...props
}, ref) => <DialogPrimitive.Overlay ref={ref} className={cn("fixed inset-0 z-[5001] bg-black/50 w-[100vw] h-[100vh]", className)} {...props} />);
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;
const DialogContent = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Content>, React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
  title?: string;
}>(({
  className,
  children,
  title,
  ...props
}, ref) => <DialogPortal>
    <DialogOverlay />
    <div className="fixed z-[5002] inset-0 flex items-center justify-center">
      <DialogPrimitive.Content ref={ref} className={cn("relative grid w-full sm:max-h-[90vh] overflow-y-auto overflow-x-hidden sm:max-w-max h-dvh sm:h-auto max-w-lg gap-4 border bg-[#191f3b] p-6 duration-200 data-[state=closed]:animate-zoom-out-95 data-[state=open]:animate-zoom-in-95 sm:rounded-lg md:w-full outline-none ring-0 shadow-none", "sm:py-6 py-4", "[@media(max-width:600px)]:pt-[max(16px,env(safe-area-inset-top))]", "[@media(max-width:600px)]:pb-[max(16px,env(safe-area-inset-bottom))]", "[@media(max-width:600px)]:pl-[max(16px,env(safe-area-inset-left))]", "[@media(max-width:600px)]:pr-[max(16px,env(safe-area-inset-right))]", "[&::-webkit-scrollbar]:w-[5px]", "[&::-webkit-scrollbar-thumb]:bg-[#494F78]", "[&::-webkit-scrollbar-thumb]:border-l-4", "[&::-webkit-scrollbar-thumb]:border-l-6", "[&::-webkit-scrollbar-thumb]:border-solid", "[&::-webkit-scrollbar-thumb]:border-[#494F78]", "[&::-webkit-scrollbar-thumb]:bg-clip-padding", "[&::-webkit-scrollbar-thumb]:rounded-full", className)} {...props}>
        <DialogTitle className="sr-only">{title}</DialogTitle>
        {children}
      </DialogPrimitive.Content>
    </div>
  </DialogPortal>);
DialogContent.displayName = DialogPrimitive.Content.displayName;
const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => <div className={cn("flex flex-col space-y-1.5 text-center sm:text-left", className)} {...props} data-sentry-component="DialogHeader" data-sentry-source-file="dialog.tsx" />;
DialogHeader.displayName = "DialogHeader";
const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => <div className={cn("flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2", className)} {...props} data-sentry-component="DialogFooter" data-sentry-source-file="dialog.tsx" />;
DialogFooter.displayName = "DialogFooter";
const DialogTitle = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Title>, React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>>(({
  className,
  ...props
}, ref) => <DialogPrimitive.Title ref={ref} className={cn("text-lg font-semibold leading-none tracking-tight", className)} {...props} />);
DialogTitle.displayName = DialogPrimitive.Title.displayName;
const DialogDescription = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Description>, React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>>(({
  className,
  ...props
}, ref) => <DialogPrimitive.Description ref={ref} className={cn("text-sm text-muted-foreground", className)} {...props} />);
DialogDescription.displayName = DialogPrimitive.Description.displayName;
export const CloseButton = ({
  className,
  ...props
}: ButtonProps) => <Button variant="dark" size="sm" className={cn("absolute right-6 top-6 rounded-full z-10", className)} {...props} data-sentry-element="Button" data-sentry-component="CloseButton" data-sentry-source-file="dialog.tsx">
    <XIcon className="h-4 w-4" data-sentry-element="XIcon" data-sentry-source-file="dialog.tsx" />
  </Button>;
export { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogOverlay, DialogPortal, DialogTitle, DialogTrigger };