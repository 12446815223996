const AlertIcon = props => <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" {...props} data-sentry-element="svg" data-sentry-component="AlertIcon" data-sentry-source-file="AlertIcon.jsx">
    <g clipPath="url(#clip0_2888_152641)" data-sentry-element="g" data-sentry-source-file="AlertIcon.jsx">
      <path d="M21.4437 4.99976L37.3203 32.4998C37.4666 32.7531 37.5436 33.0405 37.5436 33.3331C37.5436 33.6256 37.4666 33.913 37.3203 34.1664C37.1741 34.4198 36.9637 34.6302 36.7103 34.7765C36.457 34.9227 36.1695 34.9998 35.877 34.9998H4.12366C3.8311 34.9998 3.5437 34.9227 3.29034 34.7765C3.03698 34.6302 2.82659 34.4198 2.68031 34.1664C2.53404 33.913 2.45703 33.6256 2.45703 33.3331C2.45703 33.0405 2.53404 32.7531 2.68032 32.4998L18.557 4.99976C18.7033 4.74642 18.9137 4.53604 19.167 4.38977C19.4204 4.24351 19.7078 4.1665 20.0003 4.1665C20.2929 4.1665 20.5803 4.24351 20.8336 4.38977C21.087 4.53604 21.2974 4.74642 21.4437 4.99976ZM18.3337 26.6664V29.9998H21.667V26.6664H18.3337ZM18.3337 14.9998V23.3331H21.667V14.9998H18.3337Z" fill="" data-sentry-element="path" data-sentry-source-file="AlertIcon.jsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="AlertIcon.jsx">
      <clipPath id="clip0_2888_152641" data-sentry-element="clipPath" data-sentry-source-file="AlertIcon.jsx">
        <rect width="40" height="40" fill="white" data-sentry-element="rect" data-sentry-source-file="AlertIcon.jsx" />
      </clipPath>
    </defs>
  </svg>;
export default AlertIcon;