import { useEffect, useRef, useState } from "react";

export const useTurnstile = (
  onTokenChange?: (token: string | null) => void
) => {
  const turnstileRef = useRef<HTMLDivElement>(null);
  const turnstileWidgetId = useRef<string | null>(null);
  const [turnstileError, setTurnstileError] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const refresh = () => {
    if (turnstileWidgetId.current !== null) {
      window.turnstile.reset(turnstileWidgetId.current);
    }
  };

  useEffect(() => {
    if (!turnstileRef.current || !window.turnstile) {
      console.error("Turnstile is not available.");
      return;
    }

    const sitekey = process.env.NEXT_PUBLIC_TURNSTILE_AUTH_SITE_KEY;

    if (!sitekey) {
      console.error("Missing Turnstile sitekey.");
      setTurnstileError("Missing Turnstile sitekey.");
      return;
    }

    turnstileWidgetId.current = window.turnstile.render(turnstileRef.current, {
      sitekey,
      callback: (token: string) => {
        setToken(token);
        setTurnstileError(null);
        onTokenChange?.(token);
      },
      "expired-callback": () => {
        setToken(null);
        onTokenChange?.(null);
      },
      "error-callback": (error: string) => {
        setTurnstileError(`Security verification error: ${error}`);
        setToken(null);
        onTokenChange?.(null);
      },
      appearance: "always",
      size: "flexible",
      theme: "dark",
    });

    return () => {
      if (turnstileWidgetId.current !== null) {
        window.turnstile.remove(turnstileWidgetId.current);
      }
    };
  }, [onTokenChange]);

  return { turnstileRef, token, turnstileError, refresh };
};
