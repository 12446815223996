export default function PaysafeIconBackground() {
  return <svg width="58" height="38" viewBox="0 0 700 172" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="PaysafeIconBackground" data-sentry-source-file="PaysafeIconBackground.jsx">
      <g clipPath="url(#clip0_4304_11815)" data-sentry-element="g" data-sentry-source-file="PaysafeIconBackground.jsx">
        <path d="M182.894 76.8403C182.78 73.3257 181.123 71.5115 177.906 71.3923H160.723C156.656 71.6415 154.555 73.8726 154.457 78.0696V108.976C154.555 113.308 156.878 115.518 161.394 115.642H177.143C178.778 115.642 180.137 115.036 181.221 113.796C182.352 112.864 182.894 111.667 182.894 110.184V76.8403ZM132.395 72.4538C132.508 64.7205 134.23 58.8446 137.572 54.8264C139.321 53.1421 141.509 51.7341 144.173 50.5698C146.941 49.3296 149.621 48.7285 152.221 48.7285H183.76C197.673 48.8477 204.745 56.4564 204.968 71.5711V118.09C204.865 123.457 202.763 128.114 198.702 132.008C194.678 136.102 189.875 138.187 184.28 138.312H154.457V171.85H132.395V72.4538Z" fill="white" data-sentry-element="path" data-sentry-source-file="PaysafeIconBackground.jsx" />
        <path d="M244.795 111.153C244.795 112.447 245.39 113.471 246.555 114.24C247.594 115.16 248.905 115.642 250.562 115.642H267.507C271.336 115.642 273.323 114.158 273.437 111.153V104.828C273.437 101.372 271.417 99.6287 267.345 99.6287H250.562C248.353 99.6287 246.825 99.997 245.975 100.69C245.19 101.508 244.795 103.127 244.795 105.526V111.153ZM273.437 76.2934C273.551 72.9033 271.574 71.284 267.502 71.3923H223.068V48.7285H273.096C287.669 48.9668 295.067 56.0069 295.316 69.9085V117.321C295.191 123.316 293.355 128.26 289.792 132.192C286.066 136.264 281.263 138.312 275.376 138.312H245.136C230.417 138.187 222.954 131.466 222.727 118.106V99.8128C222.84 85.8517 229.972 78.7574 244.091 78.5137H273.437V76.2934Z" fill="white" data-sentry-element="path" data-sentry-source-file="PaysafeIconBackground.jsx" />
        <path d="M357.279 149.18C359.029 149.072 360.545 148.444 361.774 147.318C362.955 146.267 363.551 145.059 363.551 143.689V138.312H335.303C331.621 138.312 328.675 137.84 326.476 136.904C324.153 136.053 321.84 134.467 319.512 132.214C317.308 129.825 315.721 127.329 314.757 124.719C313.755 122.33 313.246 119.292 313.246 115.642V48.7285H335.303V108.461C335.303 113.249 337.258 115.642 341.217 115.642H356.792C358.644 115.642 360.253 115.036 361.601 113.796C362.901 112.745 363.551 111.499 363.551 110.037V48.7285H385.429V147.442C385.305 154.748 383.431 160.596 379.819 165.032C376.152 169.467 371.138 171.736 364.742 171.85H313.246V149.18H357.279Z" fill="white" data-sentry-element="path" data-sentry-source-file="PaysafeIconBackground.jsx" />
        <path d="M402.75 115.642H442.061C445.657 115.518 447.525 114.077 447.639 111.315V109.279C447.639 108.689 447.46 108.034 447.114 107.33C446.236 105.618 445.159 104.768 443.875 104.768H424.385C418.601 104.654 413.668 102.661 409.519 98.8164C405.322 95.2638 403.172 90.8935 403.048 85.7651V68.8633C403.275 55.6766 410.44 48.9668 424.553 48.7285H470.146V71.3923H431.517C426.984 71.3923 424.699 72.7841 424.699 75.5569V77.7501C424.699 80.6258 427.033 82.0663 431.685 82.0663H451.116C456.293 82.1854 460.707 84.1675 464.384 88.0341C468.099 91.9279 470.027 96.5581 470.146 101.936V118.469C470.027 123.116 467.877 127.751 463.745 132.376C461.73 134.645 459.667 136.205 457.603 137.088C455.47 137.9 452.573 138.312 448.874 138.312H402.75V115.642Z" fill="white" data-sentry-element="path" data-sentry-source-file="PaysafeIconBackground.jsx" />
        <path d="M507.898 111.153C507.898 112.447 508.494 113.471 509.685 114.24C510.714 115.16 512.03 115.642 513.666 115.642H530.643C534.472 115.642 536.448 114.158 536.562 111.153V104.828C536.562 101.372 534.515 99.6287 530.459 99.6287H513.666C511.461 99.6287 509.94 99.997 509.095 100.69C508.31 101.508 507.898 103.127 507.898 105.526V111.153ZM536.562 76.2934C536.66 72.9033 534.683 71.284 530.605 71.3923H486.182V48.7285H536.226C550.783 48.9668 558.186 56.0069 558.414 69.9085V117.321C558.295 123.316 556.442 128.26 552.901 132.192C549.202 136.264 544.393 138.312 538.501 138.312H508.234C493.525 138.187 486.057 131.466 485.852 118.106V99.8128C485.949 85.8517 493.076 78.7574 507.205 78.5137H536.562V76.2934Z" fill="white" data-sentry-element="path" data-sentry-source-file="PaysafeIconBackground.jsx" />
        <path d="M576.352 35.8774C576.487 29.7579 578.74 24.8461 583.159 21.1635C587.448 17.4214 593.08 15.4989 600.044 15.3906H614.054V37.2854H605.568C600.905 37.3938 598.528 39.6249 598.425 43.9357V48.7338H614.054V71.4193H598.425V138.322H576.352V35.8774Z" fill="white" data-sentry-element="path" data-sentry-source-file="PaysafeIconBackground.jsx" />
        <path d="M676.799 75.871V75.3456C676.799 74.1813 676.328 73.1903 675.38 72.4538C674.351 71.7389 673.095 71.3923 671.584 71.3923H656.225C654.254 71.3923 652.667 71.9447 651.438 73.0766C649.911 74.1218 649.137 75.4973 649.137 77.1923V86.2904L676.799 75.871ZM649.489 107.909V109.322C649.489 111.137 649.96 112.577 650.918 113.622C651.611 114.96 652.543 115.642 653.783 115.642H699.105V138.312H650.918C646.521 138.312 643.093 137.9 640.672 137.077C638.024 136.205 635.647 134.781 633.572 132.799C631.146 130.4 629.495 127.957 628.666 125.444C627.756 122.823 627.307 119.347 627.307 115.014L627.117 69.6486C627.361 55.942 634.52 48.9668 648.59 48.7285H678.331C692.071 48.9668 699.029 56.1856 699.252 70.423V88.3428L649.489 107.909Z" fill="white" data-sentry-element="path" data-sentry-source-file="PaysafeIconBackground.jsx" />
        <path d="M9.84001 45.321C11.573 44.5574 13.3059 43.9617 15.0389 43.588C18.2232 28.3813 31.6915 16.9763 47.8351 16.9763C63.9895 16.9763 77.4632 28.3976 80.6421 43.5989C85.5973 44.5412 89.6968 46.5449 92.892 49.5288C93.0219 46.6099 92.9028 43.7992 92.5562 41.1511C90.5146 18.1786 71.2517 0.150391 47.7647 0.150391C23.5845 0.150391 3.89921 19.3158 2.87569 43.2739C2.73489 45.3427 2.69157 47.498 2.76739 49.7509C4.79819 48.0396 7.11603 46.5016 9.84001 45.321Z" fill="white" data-sentry-element="path" data-sentry-source-file="PaysafeIconBackground.jsx" />
        <path d="M74.0867 48.7285H20.2026C17.6086 48.7285 14.9225 49.3296 12.1552 50.5698C9.48536 51.7341 7.29751 53.1421 5.54289 54.8264C2.20153 58.8446 0.490232 64.7205 0.371094 72.4538V114.575C0.490232 122.325 2.20153 128.195 5.54289 132.192C7.29751 133.903 9.48536 135.311 12.1552 136.487C14.9225 137.7 17.6086 138.312 20.2026 138.312H74.0867C88.0046 138.187 95.0826 130.551 95.3046 115.458V71.5711C95.0826 56.4564 88.0046 48.8477 74.0867 48.7285Z" fill="white" data-sentry-element="path" data-sentry-source-file="PaysafeIconBackground.jsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="PaysafeIconBackground.jsx">
        <clipPath id="clip0_4304_11815" data-sentry-element="clipPath" data-sentry-source-file="PaysafeIconBackground.jsx">
          <rect width="700" height="172" fill="white" data-sentry-element="rect" data-sentry-source-file="PaysafeIconBackground.jsx" />
        </clipPath>
      </defs>
    </svg>;
}