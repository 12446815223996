import st from "@styles/components/Wallet.module.scss";
import { useRouter } from "next/router";
import { ModalNav } from "../new-modal";
import useAuth from "@hooks/useAuth";
import SelectCrypto from "./SelectCrypto";
import useLanguage from "@hooks/useLanguage";
import { useEffect, useMemo, memo, useRef } from "react";
import { classnames } from "@lib/tools/helpers";
import Button from "@items/Button";
import LinkIcon from "@assets/icons/general/LinkIcon";
import ActivePromoBar from "./active-promo-bar";
import { useUserDetails, useUserSession } from "@hooks/useUserSession";
import { usePathname, useSearchParams } from "next/navigation";
import dynamic from "next/dynamic";
import DefaultCryptoBanner from "./DefaultCryptoBanner";
import useMobile from "@hooks/useMobile";
import useCrypto from "@hooks/useCrypto";
import ModalSpinner from "@items/ModalSpinner";
const Deposit = dynamic(() => import("./Deposit"));
const Withdrawl = dynamic(() => import("./Withdraw"));
const PromoCode = dynamic(() => import("./promo-code"));
const Tip = dynamic(() => import("./tip-modal"));
const AffiliateCode = dynamic(() => import("./AffiliateCode"));
const BuyCrypto = dynamic(() => import("./BuyCrypto"));
const RenderSwitch = memo(({
  tab,
  method,
  isMobile
}) => {
  const L = useLanguage(["Wallet"]);
  const renderContent = () => {
    if (!method && tab !== "tip" && tab !== "promo" && tab !== "affiliate" && tab !== "buy-crypto" && tab !== "buy-crypto-test") {
      return <DefaultCryptoBanner />;
    }
    switch (tab) {
      case "deposit":
        return <Deposit />;
      case "withdraw":
        return <Withdrawl />;
      default:
        return null;
    }
  };
  return <>
      {!!isMobile && ("deposit" === tab || "withdraw" === tab) && method && <div className={st["mobile-back-button-container"]}>
          <Button text={L("go_back")} buttonType={"link"} classType={["modal-return", "icon-left", "square-icon-grey"]} icon={"return"} url={{
        query: {
          modal: "wallet",
          tab
        }
      }} />
        </div>}

      {renderContent()}
    </>;
});
RenderSwitch.displayName = "RenderSwitch";
function RenderOverlaySwitch({
  tab
}) {
  switch (tab) {
    case "tip":
      return <Tip />;
    case "affiliate":
      return <AffiliateCode />;
    case "promo":
      return <PromoCode />;
    case "buy-crypto":
      return <BuyCrypto />;
    default:
      return null;
  }
}
const Wallet = () => {
  useAuth();
  const router = useRouter();
  const L = useLanguage(["Wallet"]);
  const {
    userData
  } = useUserSession();
  const {
    list: crypto,
    isLoading
  } = useCrypto();
  const search = useSearchParams();
  const tab = search.get("tab");
  const method = search.get("method");
  const modal = search.get("modal");
  const pathname = usePathname();
  const modalPath = pathname + "?" + search.toString();
  const isMobile = useMobile({
    targetElement: "body"
  });
  const {
    data: details
  } = useUserDetails();
  const default_payment_method = details?.default_payment_method;

  //Check If they have preferences set
  const checkUserDetails = useRef(false);
  useEffect(() => {
    if (method && (tab === "tip" || tab === "promo")) {
      const updatedPath = modalPath.replace(/&method=[^&]+/g, ""); // remove method query uf tab tip or promo
      router.replace(updatedPath, undefined, {
        shallow: true
      });
    }
  }, [method, modalPath, router, tab]);
  const isOverlay = useMemo(() => {
    return tab === "tip" || tab === "promo" || tab === "affiliate" || tab === "buy-crypto";
  }, [tab]);
  const selectCryptoAndPromoBar = useMemo(() => {
    if (!isMobile || !method) {
      return tab !== "tip" && tab !== "affiliate" && tab !== "promo" && tab !== "buy-crypto" && <>
            <SelectCrypto />
            <ActivePromoBar />
          </>;
    }
    return null;
  }, [isMobile, method, tab]);
  useEffect(() => {
    if (document.body.clientWidth > 1100) {
      if (checkUserDetails.current) return;
      checkUserDetails.current = true;
      if (router?.query?.tab === "buy-crypto") {
        return;
      }
      if (!default_payment_method) return;
      router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          method: default_payment_method
        }
      });
    }
  }, [default_payment_method, router]);
  useEffect(() => {
    if (crypto && method && method !== "all-crypto" && crypto?.[method] && !crypto?.[method].fiat) {
      const hasValidNetwork = crypto[method]?.networks?.some(network => network[tab === "deposit" ? "deposit" : "withdrawal"] === 1);
      if (!hasValidNetwork) {
        router.push({
          pathname: router.pathname,
          query: {
            ...router.query,
            method: "all-crypto"
          },
          shallow: true
        });
      }
    }
  }, [tab, method, crypto, router]);
  return <div {...classnames(st, "container", method && !isLoading && (tab === "withdraw" || tab === "deposit") ? "active-method" : null, !tab ? "hide" : null, isOverlay && "container__hide-left-side")} data-sentry-component="Wallet" data-sentry-source-file="index.jsx">
      <ModalSpinner hide={!isLoading} data-sentry-element="ModalSpinner" data-sentry-source-file="index.jsx" />

      {!isMobile && <div {...classnames(st, "left-side-wallet-container")}>
          {modal === "wallet" && <RenderSwitch tab={tab} modal={modal} method={method} />}
        </div>}

      <div {...classnames(st, "right-side-wallet-container")}>
        <h2>{L("wallet")}</h2>

        {tab !== "affiliate" && <ModalNav navItems={[{
        query: {
          modal: "wallet",
          tab: "deposit"
        },
        text: `${L("deposit")}`
      }, {
        query: {
          modal: "wallet",
          tab: "withdraw"
        },
        text: `${L("withdraw")}`
      }, {
        query: {
          modal: "wallet",
          tab: "buy-crypto"
        },
        text: `${L("buy_crypto_tab")}`
      }, {
        query: {
          modal: "wallet",
          tab: "tip"
        },
        text: `${L("tip")}`
      }, {
        query: {
          modal: "wallet",
          tab: "promo"
        },
        text: `${L("promo")}`
      }]} />}

        {/* Shows affiliate link */}
        {userData?.affiliate?.eligible && tab !== "tip" && tab !== "withdraw" && tab !== "promo" && tab !== "affiliate" ? <div className={st["tab-link"]}>
            <Button showActive={true} buttonType={"link"} style={{
          height: "48px",
          padding: "0px",
          borderColor: "#7ef98ab7",
          borderWidth: "1px"
        }} extraContent={isLoading ? null : <div className={st["redeem-btn"]}>
                    <div className={st["link-icon-text"]}>
                      <LinkIcon />
                      <span>{L("affiliate-link")}</span>
                    </div>
                    <div className={st["warning-message"]}>
                      <span>{L("affiliate-link-desc")}</span>
                    </div>
                  </div>} query={{
          modal: "wallet",
          tab: "affiliate"
        }} />
          </div> : null}

        {/* Could still render if its a tip */}
        <div className={` ${st["wallet-select-section"]} `}>
          {selectCryptoAndPromoBar}

          <RenderOverlaySwitch tab={tab} data-sentry-element="RenderOverlaySwitch" data-sentry-source-file="index.jsx" />

          {isMobile && <RenderSwitch tab={tab} modal={modal} method={method} isMobile={isMobile} />}
        </div>
      </div>
    </div>;
};
export default Wallet;