import { useMemo } from "react";
import useSWR from "swr";
import API from "../lib/api/api";
import { useUserSession } from "./useUserSession";

const fetcher = (url) =>
  API.get(url).then((res) => res.data?.list || res.data?.result);

const initList = {
  pix: {
    api: "url",
    id: "brl",
    name: "Real",
    fiat: true,
  },
  picpay: {
    api: "url",
    id: "brl",
    name: "Real",
    fiat: true,
  },
  interac: {
    api: "url",
    id: "cad",
    name: "CAD",
    fiat: true,
  },
};

export interface CryptoResponse {
  success: boolean;
  list: List[];
}

interface List {
  name: string;
  code: string;
  rate: number;
  icon: string;
  extra_argument: null | string;
  featured: number;
  networks: Network[];
}

interface Network {
  name: null | string;
  deposit: number;
  network: string;
  withdrawal: number;
}

export default function useCrypto() {
  const { data, isLoading } = useSWR(`crypto/list`, fetcher, {
    revalidateOnFocus: false,
  });

  const { data: ses } = useUserSession();
  const currencies = ses?.user?.currencies;
  const pixpicpay = currencies?.BRL?.rate;
  const interac = currencies?.CAD?.rate;

  const list = useMemo(() => {
    if (!data) return {};

    const obj = data?.reduce?.((acc, item) => {
      const id = item.code;
      return {
        ...acc,
        [id]: {
          ...item,
          api: "url",
          id,
        },
      };
    }, initList) as any;

    obj.pix.rate = pixpicpay;
    obj.picpay.rate = pixpicpay;
    obj.interac.rate = interac;

    type Crypto = Record<
      string,
      {
        api: string;
        id: string;
        name: string;
        fiat: boolean;
        rate: number;
        code: string;
        icon: string;
        networks: Network[];
      }
    >;

    return obj as Crypto;
  }, [data, interac, pixpicpay]);

  return {
    list,
    isLoading,
  };
}

export type CryptoCodes =
  | "BTC"
  | "ETH"
  | "USDT"
  | "LTC"
  | "SOL"
  | "TRX"
  | "TON"
  | "LINK"
  | "XLM"
  | "DAI"
  | "ZEC"
  | "DOGE"
  | "ADA"
  | "BNB"
  | "MATIC"
  | "HBAR"
  | "USDC"
  | "XRP"
  | "WETH"
  | "SHIB"
  | "PNUT"
  | "PENGU";
