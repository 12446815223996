export default function SkrillIconBackground() {
  return <svg width="78" height="38" viewBox="0 0 225 110" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="SkrillIconBackground" data-sentry-source-file="SkrillIconBackground.jsx">
      <rect width="225" height="110" rx="13" fill="#862165" data-sentry-element="rect" data-sentry-source-file="SkrillIconBackground.jsx" />
      <g clipPath="url(#clip0_0_1)" data-sentry-element="g" data-sentry-source-file="SkrillIconBackground.jsx">
        <path d="M145.885 39.7679L155.16 38.1186V73.4886H145.885V39.7679ZM159.431 39.7679V73.4886H168.716V38.1186L159.431 39.7679ZM132.67 73.4886H141.953V47.6186H132.67V73.4886ZM71.3365 51.2772C70.1634 51.2005 67.4472 51.0267 67.4472 48.5986C67.4472 45.6685 71.3571 45.6685 72.8237 45.6685C75.4028 45.6685 78.742 46.4248 81.1269 47.1333C81.1269 47.1333 82.4573 47.5991 83.5895 48.0766L83.6952 48.1049V47.9985V40.0799L83.5506 40.0379C80.736 39.0599 77.4632 38.1186 71.3955 38.1186C60.9335 38.1186 57.2266 44.1715 57.2266 49.3568C57.2266 52.346 58.5166 59.3808 70.4982 60.1923C71.514 60.2543 74.2096 60.4081 74.2096 62.9178C74.2096 64.9895 72.0026 66.2111 68.2913 66.2111C64.2235 66.2111 60.2772 65.1809 57.8809 64.2038V72.4686C61.4689 73.4027 65.5136 73.8694 70.2539 73.8694C80.4833 73.8694 85.0649 68.1426 85.0649 62.4666C85.0644 56.0309 79.9376 51.8494 71.3365 51.2772ZM127.44 47.2397C118.862 47.5132 114.507 51.3353 114.507 58.9331V73.4886H123.866V61.619C123.866 57.0694 124.465 55.1188 129.901 54.9333V47.3735C128.987 47.1802 127.44 47.2397 127.44 47.2397ZM102.262 47.5893C102.056 48.1049 100.57 52.2737 96.9757 56.5948V38.1186L87.3987 39.9989V73.4886H96.9757V63.1365C99.7495 67.2682 101.119 73.4886 101.119 73.4886H112.591C111.456 68.8037 106.492 60.1655 106.492 60.1655C110.947 54.5563 112.904 48.5795 113.215 47.5893H102.262ZM137.309 45.1368C139.837 45.1368 141.886 43.1032 141.886 40.595C141.886 38.0858 139.837 36.0508 137.309 36.0508C134.785 36.0508 132.737 38.0858 132.737 40.595C132.737 43.1032 134.785 45.1368 137.309 45.1368Z" fill="white" data-sentry-element="path" data-sentry-source-file="SkrillIconBackground.jsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="SkrillIconBackground.jsx">
        <clipPath id="clip0_0_1" data-sentry-element="clipPath" data-sentry-source-file="SkrillIconBackground.jsx">
          <rect width="112" height="38" fill="white" transform="translate(57 36)" data-sentry-element="rect" data-sentry-source-file="SkrillIconBackground.jsx" />
        </clipPath>
      </defs>
    </svg>;
}