export default function VisaIconWhite(props) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="35" height="12" viewBox="0 0 35 12" fill="none" {...props} data-sentry-element="svg" data-sentry-component="VisaIconWhite" data-sentry-source-file="VisaIconWhite.jsx">
      <g clipPath="url(#clip0_4285_11592)" data-sentry-element="g" data-sentry-source-file="VisaIconWhite.jsx">
        <path d="M14.9672 11.1648H12.1953L13.929 0.847656H16.7008L14.9672 11.1648Z" fill="white" data-sentry-element="path" data-sentry-source-file="VisaIconWhite.jsx" />
        <path d="M25.018 1.0994C24.4712 0.890646 23.6041 0.660156 22.5318 0.660156C19.7945 0.660156 17.8669 2.06498 17.8551 4.07345C17.8324 5.55529 19.2352 6.37834 20.2844 6.87244C21.3568 7.37733 21.7214 7.70687 21.7214 8.15691C21.7104 8.84808 20.8548 9.16669 20.0567 9.16669C18.9501 9.16669 18.3571 9.00243 17.456 8.61789L17.091 8.45304L16.7031 10.769C17.3532 11.0541 18.551 11.307 19.7945 11.3181C22.703 11.3181 24.5964 9.93503 24.6189 7.79469C24.6299 6.62021 23.8892 5.72028 22.2922 4.9849C21.3227 4.51284 20.729 4.19453 20.729 3.71152C20.7404 3.27242 21.2312 2.82267 22.3255 2.82267C23.2267 2.80065 23.8887 3.00911 24.3903 3.21772L24.641 3.32727L25.018 1.0994Z" fill="white" data-sentry-element="path" data-sentry-source-file="VisaIconWhite.jsx" />
        <path d="M28.6987 7.50983C28.927 6.91712 29.8053 4.62316 29.8053 4.62316C29.7938 4.64519 30.0332 4.01951 30.17 3.63541L30.3638 4.5244C30.3638 4.5244 30.8887 6.994 31.0026 7.50983C30.5694 7.50983 29.2462 7.50983 28.6987 7.50983ZM32.1202 0.847656H29.9762C29.315 0.847656 28.8127 1.03409 28.5274 1.70368L24.4102 11.1647H27.3186C27.3186 11.1647 27.7974 9.89131 27.9003 9.61706C28.2194 9.61706 31.0487 9.61706 31.4592 9.61706C31.5388 9.97928 31.7899 11.1647 31.7899 11.1647H34.3564L32.1202 0.847656Z" fill="white" data-sentry-element="path" data-sentry-source-file="VisaIconWhite.jsx" />
        <path d="M9.88257 0.847656L7.16799 7.88299L6.87136 6.45614C6.36949 4.80974 4.79553 3.02097 3.03906 2.13154L5.52551 11.1539H8.45669L12.8136 0.847656H9.88257Z" fill="white" data-sentry-element="path" data-sentry-source-file="VisaIconWhite.jsx" />
        <path d="M4.64592 0.847656H0.186249L0.140625 1.05612C3.61945 1.91228 5.9234 3.97604 6.86999 6.45658L5.90051 1.71491C5.7409 1.05597 5.2504 0.869392 4.64592 0.847656Z" fill="white" data-sentry-element="path" data-sentry-source-file="VisaIconWhite.jsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="VisaIconWhite.jsx">
        <clipPath id="clip0_4285_11592" data-sentry-element="clipPath" data-sentry-source-file="VisaIconWhite.jsx">
          <rect width="34.2177" height="10.658" fill="white" transform="translate(0.140625 0.660156)" data-sentry-element="rect" data-sentry-source-file="VisaIconWhite.jsx" />
        </clipPath>
      </defs>
    </svg>;
}